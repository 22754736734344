import React from 'react';
import ReactDOM from 'react-dom';

import { KeycloakProvider } from '@react-keycloak/web';
import keycloak from './security/keycloak';

import { Provider } from 'react-redux';
import store from './store';

import { Chart, registerables } from 'chart.js';

import Routes from './routes/Routes';

import './assets/sass/style.scss';

import { createMuiTheme , ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    htmlFontSize: 19,
  },
});

Chart.register(...registerables);
Chart.defaults.responsive = true;
Chart.defaults.plugins.title.display = true;
Chart.defaults.plugins.title.font.size = 18;

ReactDOM.render( 
  <KeycloakProvider keycloak={keycloak} initConfig={{ checkLoginIframe: false }} >
    <Provider store={store} >
      <ThemeProvider theme={theme} >
        <Routes />
      </ThemeProvider>
    </Provider>
  </KeycloakProvider>,
  document.getElementById('root')
);