import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Cache from './Cache';

import RelatorioService from '../../services/Relatorio';
import { errors } from '../../services/API';

import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import Loading from '../../components/Loading/Loading';

import DadosInscricoesUF from '../../forms/Relatorio/DadosInscricoesUF';
import { ButtonExport } from '../../forms/Buttons/ButtonsExport';

import AuthorizedElement from '../../security/AuthorizedElement';
import { __RELATORIO_INSCRICOES_EXPORTAR } from '../../security/RoleConfiguration';

import { download } from '../../helper/FileHelper';

export class InscricoesPorUF extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            ufs: [],
            ultimaChamada: '',
            loading: true,
            filters: {
                adminDep: '',
            }
        }
    }

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;

    // EXPORTAÇÃO
    handleExportChange = () => {       
        const f = [];
        const filters = this.state.filters;

        if (filters.adminDep) f.push({ field: 'depAdmin', value: filters.adminDep })

        f.push({ field: "forceRefresh", value: true })
        f.push({ field: 'exportarPlanilha', value: true });
        
        this.Toast.setState({
            message: {
                message: "A Planilha está sendo gerada para Exportação.",
                type: 'info',
                open: true,
                loading: true,
                autoHide: false,
            }
        })

        RelatorioService.exportReportInscricoesPorUF(f)
            .then(res => download(res))
    }

    // FILTROS
    ApplyFilters = (forceRefresh = false, ignoreFilter = false) => {
        this.setState({ loading: true });

        const f = [];
        
        if (!ignoreFilter) {
            const filters = this.state.filters;

            if (filters.adminDep) f.push({ field: 'depAdmin', value: filters.adminDep })
        }

        f.push({ field: "forceRefresh", value: f.length > 0 ? true : forceRefresh })

        RelatorioService.filtersReportInscricoesPorUF(f)
            .then(res => {
                this.setState({ 
                    ufs: res.data.cache,
                    ultimaChamada: res.data.ultimaChamada,
                    loading: false,
                });
            })
            .catch(error => {
                
                const e = errors(error);
                
                this.setState({ loading: false });
                
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            });
    }

    componentDidMount() {
        this.ApplyFilters(true, true);
    }

    render() {
        const pageName = 'UF`s';

        return(
            <>
                <Toast parentRef={this.setToast} />
                <Title>
                    {pageName}
                    { this.state.ultimaChamada && 
                        <Cache ultimaAtualizacao={this.state.ultimaChamada} action={() => this.ApplyFilters(true)} /> 
                    }
                </Title>

                <Grid container >
                    <AuthorizedElement roles={[__RELATORIO_INSCRICOES_EXPORTAR]}>
                        <ButtonExport
                            title="Exportar Planilha"
                            onClick={this.handleExportChange}
                        />
                    </AuthorizedElement>
                </Grid>

                { this.state.loading ? 
                    <Loading />
                :
                    <DadosInscricoesUF ufs={this.state.ufs} contexts={this.props.contexts} />
                }
            </>
        )
    }
}

export default InscricoesPorUF;