import React, { Component } from 'react';

import { isAfterDataFimInscricaoEDataFimPagamento } from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';

import Table, { DataTableLineTotals as Totals } from '../../components/Table/Table';

import { percentMask, numberFormat } from '../../helper/MaskHelper';

import { 
    ButtonNewTabEscolasPorUf,
    ButtonNewTabEscolasPorUfAndStatus,
} from '../Buttons/ButtonsNewTab';

export class DadosGestaoEscola extends Component {
    sumUfs = () => this.props.ufs.length;
    sumEscolasInscritas = () => this.props.ufs.reduce((total, i) => total + i.qtdEscolasInscritas, 0);
    sumEscolasAguardandoPagamento = () => this.props.ufs.reduce((total, i) => total + i.qtdEscolasAguardandoPagamento, 0);
    sumEscolas = () => this.props.ufs.reduce((total, i) => total + i.qtdEscolasTotal, 0);
    sumEscolasInscritasPorcentagem = () => this.props.ufs.reduce((total, i) => total + i.qtdEscolasInscritasPorcentagem, 0);
    sumMunicipiosInscritos = () => this.props.ufs.reduce((total, i) => total + i.qtdMunicipiosInscritos, 0);
    sumMunicipios = () => this.props.ufs.reduce((total, i) => total + i.qtdMunicipiosTotal, 0);
    sumMunicipiosInscritosPorcentagem = () => this.props.ufs.reduce((total, i) => total + i.qtdMunicipiosInscritosPorcentagem, 0);
    sumAlunos = () => this.props.ufs.reduce((total, i) => total + i.qtdAlunosPrevistosTotal, 0);

    newTabEscolasPorUf = (qtd, uf) => <ButtonNewTabEscolasPorUf qtd={numberFormat(qtd)} uf={uf} />
    newTabEscolasPorUfAndStatus = (qtd, uf, status) => <ButtonNewTabEscolasPorUfAndStatus qtd={numberFormat(qtd)} uf={uf} status={status} />

    render() {
        const columns = [
            { label: 'UF', name: 'nmUf', func: null, key: true, width: '10', orderLabel: 'asc', },
            { label: 'Escolas Inscritas', name: 'qtdEscolasInscritas', func: (qtd, uf) => this.newTabEscolasPorUfAndStatus(qtd, uf, "CONCLUIDA"), key: false, width: '10', },
            { label: 'Escolas Aguardando Pagamento', name: 'qtdEscolasAguardandoPagamento', func: (qtd, uf) => this.newTabEscolasPorUfAndStatus(qtd, uf, "AGUARDANDO_PAGAMENTO"), key: false, width: '10', hidden: isAfterDataFimInscricaoEDataFimPagamento(this.props.contexts), },
            { label: 'Total de Escolas', name: 'qtdEscolasTotal', func: this.newTabEscolasPorUf, key: false, width: '10', },
            { label: '% de Escolas Inscritas', name: 'qtdEscolasInscritasPorcentagem', func: percentMask, key: false, width: '12', },
            { label: 'Municípios Inscritos', name: 'qtdMunicipiosInscritos', func: numberFormat, key: false, width: '12', },
            { label: 'Total de Municípios', name: 'qtdMunicipiosTotal', func: numberFormat, key: false, width: '12', },
            { label: '% de Municípios Inscritos', name: 'qtdMunicipiosInscritosPorcentagem', func: percentMask, key: false, width: '12', },
            { label: 'Total de Alunos Previstos', name: 'qtdAlunosPrevistosTotal', func: numberFormat, key: false, width: '12', },
        ];

        const totals = [
            { column: 0, value: "Total", width: '10', },
            { column: 1, value: numberFormat(this.sumEscolasInscritas()), width: '10', },
            { column: 2, value: numberFormat(this.sumEscolasAguardandoPagamento()), width: '10', hidden: isAfterDataFimInscricaoEDataFimPagamento(this.props.contexts), },
            { column: 3, value: numberFormat(this.sumEscolas()), width: '10', },
            { column: 4, value: percentMask(this.sumEscolasInscritasPorcentagem() / this.sumUfs()), width: '12', },
            { column: 5, value: numberFormat(this.sumMunicipiosInscritos()), width: '12', },
            { column: 6, value: numberFormat(this.sumMunicipios()), width: '12', },
            { column: 7, value: percentMask(this.sumMunicipiosInscritosPorcentagem() / this.sumUfs()), width: '12', },
            { column: 8, value: numberFormat(this.sumAlunos()), width: '12', },
        ];

        return (
            <Grid container spacing={3} style={{ marginTop: this.props.marginTop ?? 8 }}>
                <Grid item xs={12}>
                    <Table
                        columns={columns}
                        tableData={this.props.ufs}
                        actions={[]}
                        page={null}
                        rowsPerPage={null}
                        sort={null}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Totals lineData={totals} columns={columns} />
                </Grid>
            </Grid>
        )
    }
}

export default DadosGestaoEscola;