import EscolaService from '../services/Escola';
import UFService from '../services/UF';

export const isNumber = str => /[^0-9]+$/.test(str);
export const isNotNumber = str => isNaN(str);
export const isNumberHelperText = () => "Campo Numérico";

export const isZero = str => str === '0' || str === 0;

export const isNotaFase1Valida = str => isBlank(str) || /^(1[0-5]|[1-9])$/.test(str);
export const isNotaFase1Invalida = str => !isNotaFase1Valida(str);
export const isNotaFase1HelperText = (nota) => nota === '0' ? "A Nota 0 não é permitida na Fase 1, pois elimina o Aluno" : "A Nota Informada deve ser entre 1 e 15";

export const isPasswordsDiffHelperText = () => "As Senhas não podem ser Diferentes";

export const isSizeInvalid = (size) => size > 10485760 ? true : false;
export const isSizeInvalidHelperText = () => "Tamanho do Arquivo excede o limite máximo de 10mb";

export const isBlank = str => str === '' || str === null || (typeof str === 'string' && str.trim() === "");
export const isNotBlank = str => !isBlank(str);
export const isBlankHelperText = () => "Campo Obrigatório!";

export const isDDDValid = str => /^\(?[1-9]{2}\)?/.test(str);
export const isDDDInvalid = str => !isDDDValid(str);
export const isDDDHelperText = () => "DDD Inválido";

export const isPhoneValid = str => /^(\d{4,5})-?(\d{4})/.test(str);
export const isPhoneInvalid = str => !isPhoneValid(str);
export const isPhoneHelperText = () => "Telefone Inválido";

export const isNameCompoundValid = str => str.trim().replaceAll(/\s+/g, " ").match(/^[A-zÀ-ÿ0-9-']+\s([A-zÀ-ÿ0-9-']\s?)*[A-zÀ-ÿ0-9-']+$/);
export const isNameCompoundInvalid = str => !isNameCompoundValid(str);
export const isNameCompoundHelperText = () => "O Nome Completo deve conter Sobrenome";

export const isNameSchoolCompoundValid = str => str.trim().replaceAll(/\s+/g, " ").match(/^[A-zÀ-ÿ0-9-']+\s([A-zÀ-ÿ0-9-']\s?)*[A-zÀ-ÿ0-9-']+$/);
export const isNameSchoolCompoundInvalid = str => !isNameSchoolCompoundValid(str);
export const isNameSchoolCompoundHelperText = () => "O Nome da Escola deve ser Composto";

export const isCPFValid = str => {
    str = str.replace(/[^\d]+/g,'');
    
    if(str === '')
        return false;
    
    if (str.length !== 11 || 
        str === "00000000000" || 
        str === "11111111111" || 
        str === "22222222222" || 
        str === "33333333333" || 
        str === "44444444444" || 
        str === "55555555555" || 
        str === "66666666666" || 
        str === "77777777777" || 
        str === "88888888888" || 
        str === "99999999999")
            return false;

    let add1 = 0;

    for (let i=0; i < 9; i++)
        add1 += parseInt(str.charAt(i)) * (10 - i);

    let rev1 = 11 - (add1 % 11);

    if (rev1 === 10 || rev1 === 11)
        rev1 = 0;

    if (rev1 !== parseInt(str.charAt(9)))
        return false;

    let add2 = 0;

    for (let i = 0; i < 10; i ++)
        add2 += parseInt(str.charAt(i)) * (11 - i);

    let rev2 = 11 - (add2 % 11);

    if (rev2 === 10 || rev2 === 11)
        rev2 = 0;

    if (rev2 !== parseInt(str.charAt(10)))
        return false;

    return true;
}
export const isCPFInvalid = str => !isCPFValid(str);
export const isCPFHelperText = () => "CPF Inválido";

export const isMailValid = str => {
    const usuario = str.substring(0, str.indexOf("@"));
    const dominio = str.substring(str.indexOf("@") + 1, str.length);

    if (usuario.length >= 1 && 
        usuario.search("@") === -1 &&
        usuario.search(" ") === -1 &&
        dominio.length >= 3 &&
        dominio.search("@") === -1 &&
        dominio.search(" ") === -1 &&
        dominio.search(".") !== -1 &&
        dominio.indexOf(".") >= 1 && 
        dominio.lastIndexOf(".") < dominio.length - 1) {
            return true;
    }

    return false;
}
export const isMailInvalid = str => !isMailValid(str);
export const isMailHelperText = () => "Email Inválido";
export const isEmailsDiffHelperText = () => "Os Emails não podem ser Diferentes";

export const isMecCodeValid = str => /^\(?[0-9]{8}\)?/.test(str) && str.length === 8 && str.indexOf('0', 0) !== 0;
export const isMecCodeInvalid = str => !isMecCodeValid(str);
export const isMecCodeHelperText = () => "Códico MEC Inválido";

export const isMecCodeNotValid = (mecCode) => UFService.validarINEP(mecCode).then(res => res.status === 200 ? res.data : null)
export const isUfDiffPrefixMecCodeHelperText = () => "Você Não Pode Alterar o INEP com Prefixo referente a outra UF do Cadastro da Escola";

export const isMecCodeExistOtherSchool = (mecCode) => EscolaService.buscarParaInscricao(mecCode).then(res => res.status === 200 ? res.data : null)
export const isMecCodeExistHelperText = (schoolName) => `Esse INEP já pertence a Escola ${schoolName}. Por Favor, utilize outro INEP.`;

export const isZipCodeValid = str => /^(\d{5})-?(\d{3})/.test(str);
export const isZipCodeInvalid = str => !isZipCodeValid(str);
export const isZipCodeHelperText = () => "CEP Inválido";

export const isDateTimeValid = d => d instanceof Date && !isNaN(d);
export const isDateTimeInvalid = str => !isDateTimeValid(str);
export const isDateTimeHelperText = () => "Formato de Data e Hora Inválido";

export const isZeroParaInscricao = () => "A Inscrição NÃO pode ser de 0 (Zero) Alunos em Ambos Níveis";

export const isForaDaDataNascimentoPrevista = () => "Data Fora do Período Permitido";
export const foraDaDataNascimentoPrevista = (date) => new Date(date).getYear() > (new Date().getYear() - 3);

export const isNotaFase2Valida = str => /^(1[0-5]|[0-9])$/.test(str);
export const isNotaFase2Invalida = str => !isNotaFase2Valida(str);
export const isNotaFase2HelperText = (nota) => "A Nota Informada deve ser entre 0 e 15";

export const isCNPJValid = str => {
    str = str.replace(/[^\d]+/g,'');
    
    if(str === '')
        return false;

    str = str.replaceAll(".","");
    str = str.replace("-","");
    str = str.replace("/","");

    if (str.length !== 14 || 
        str === "00000000000000" || 
        str === "11111111111111" || 
        str === "22222222222222" || 
        str === "33333333333333" || 
        str === "44444444444444" || 
        str === "55555555555555" || 
        str === "66666666666666" || 
        str === "77777777777777" || 
        str === "88888888888888" || 
        str === "99999999999999")
            return false;
    
    let tamanho = str.length - 2;
    let numeros = str.substring(0, tamanho);
    let digitos = str.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;

        if (pos < 2) {
            pos = 9;
        }
    }
    
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    
    if (resultado !== parseInt(digitos.charAt(0)))
        return false;
    
    tamanho = tamanho + 1;
    numeros = str.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) {
            pos = 9;
        }
    }
    
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    
    if (resultado !== parseInt(digitos.charAt(1)))
        return false;
    
    return true;
}
export const isCNPJInvalid = str => !isCNPJValid(str);
export const isCNPJHelperText = () => "CNPJ Inválido";