import React from 'react';
import { Bar } from "react-chartjs-2";

import Grid from '@material-ui/core/Grid';

export const InscricoesEscolasPorData = ({ inscricoes }) => {
    return (
        <>
            {inscricoes.length > 0 &&
                <Grid item md={12} lg={6} >
                    <Bar
                        data={{
                            labels: inscricoes.map((i) => i.dataFormatada),
                            datasets: [
                                {
                                    label: "Total de Escolas",
                                    data: inscricoes.map((i) => i.escolas),
                                    backgroundColor: "#1102BD",
                                    borderColor: "#1102BD",
                                },
                            ],
                        }}
                        options={{
                            elements: {
                                line: {
                                    tension: 0.5,
                                },
                            },
                            plugins: {
                                title: {
                                    text: "Escolas",
                                },
                            },
                        }}
                    />
                </Grid>
            }
        </>
    )
}

export default InscricoesEscolasPorData;