import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';

import Grid from '@material-ui/core/Grid';

import Cache from './Cache';

import RelatorioService from '../../services/Relatorio';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import Loading from '../../components/Loading/Loading';

import DadosDependenciasAdministrativas from '../../forms/Relatorio/DadosDependenciasAdministrativas';
import { ButtonExport } from '../../forms/Buttons/ButtonsExport';

import AuthorizedElement from '../../security/AuthorizedElement';
import { __RELATORIO_DEPENDENCIAS_ADMINISTRATIVAS_EXPORTAR } from '../../security/RoleConfiguration';

import { download } from '../../helper/FileHelper';

import { patternDependencia } from '../../variables/Enums/DependenciaAdministrativa';

import { Layout } from '../../layouts/private/Private';

export class DependenciasAdminstrativas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dependencias: [],
            ultimaChamada: '',
            loading: true,
        }
    }

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;

    // EXPORTAÇÃO
    handleExportChange = () => {
        const f = [];
        f.push({ field: 'exportarPlanilha', value: true });
        
        this.Toast.setState({
            message: {
                message: "A Planilha está sendo gerada para Exportação.",
                type: 'info',
                open: true,
                loading: true,
                autoHide: false,
            }
        })

        RelatorioService.exportReportDependenciasAdministrativas(f)
            .then(res => download(res))
    }

    buscarDependenciasAdministrativas = (forceRefresh = false) => {
        this.setState({ loading: true });
        const filters = [{ 'field': "forceRefresh", 'value': forceRefresh }];

        RelatorioService.filtersReportDependenciasAdministrativas(filters)
            .then(res => {
                this.setState({
                    dependencias: [...patternDependencia(res.data.cache)],
                    ultimaChamada: res.data.ultimaChamada,
                    loading: false 
                });
            })
            .catch(error => {
                const e = errors(error);
                this.setState({ loading: false });
                
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
    }

    componentDidMount() {
        this.props.loadContext();
        this.buscarDependenciasAdministrativas();
    }

    render() {
        const pageName = 'Dependências Administrativas';
        const links = [
            { 
                path: null, 
                name: 'Relatórios' 
            }
        ];

        return(
            <Layout>
                <Toast parentRef={this.setToast} />
                <Grid container >
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={pageName} />
                    </Grid>
                </Grid>
                <Title>
                    {pageName}
                    { this.state.ultimaChamada && 
                        <Cache ultimaAtualizacao={this.state.ultimaChamada} action={() => this.buscarDependenciasAdministrativas(true)} /> 
                    }
                </Title>

                <Grid container >
                    <AuthorizedElement roles={[__RELATORIO_DEPENDENCIAS_ADMINISTRATIVAS_EXPORTAR]}>
                        <ButtonExport
                        title="Exportar Planilha"
                        onClick={this.handleExportChange}
                        />
                    </AuthorizedElement>
                </Grid>

                { this.state.loading ? 
                    <Loading />
                :
                    <DadosDependenciasAdministrativas dependencias={this.state.dependencias} contexts={this.props.contexts} />
                }
            </Layout>
        )
    }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DependenciasAdminstrativas));