import React, { Component } from 'react';

import { isAfterDataFimInscricaoEDataFimPagamento } from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';

import Table from '../../components/Table/Table';

import { percentMask, numberFormat } from '../../helper/MaskHelper';

export class DadosInscricoesEdicao extends Component {
    render () {
        const columns = [
            { label: 'Edicão', name: 'edicao', func: null, key: true, width: '9', orderLabel: 'desc', },
            { label: 'Escolas', name: 'escolas', func: numberFormat, key: false, width: '9', },
            { label: 'Escolas Inscritas', name: 'escolasInscritas', func: numberFormat, key: false, width: '9', },
            { label: 'Escolas Aguardando Pagamento', name: 'escolasAguardandoPagamento', func: numberFormat, key: false, width: '9', hidden: isAfterDataFimInscricaoEDataFimPagamento(this.props.contexts), },
            { label: '% de Escolas Inscritas', name: 'escolasInscritasPorcentagem', func: percentMask, key: false, width: '9', },
            { label: 'Municipios', name: 'municipios', func: numberFormat, key: false, width: '9', },
            { label: 'Municipios Inscritos', name: 'municipiosInscritos', func: numberFormat, key: false, width: '9', },
            { label: '% de Municipios Inscritos', name: 'municipiosInscritosPorcentagem', func: percentMask, key: false, width: '9', },
            { label: 'Mirim 1 - Alunos Previstos', name: 'alunosPrevistosMirim1', func: numberFormat, key: false, width: '9', },
            { label: 'Mirim 2 - Alunos Previstos', name: 'alunosPrevistosMirim2', func: numberFormat, key: false, width: '9', },
            { label: 'Total de Alunos Previstos', name: 'alunosPrevistos', func: numberFormat, key: false, width: '9', },
        ];

        return (
            <Grid container spacing={3} style={{ marginTop: this.props.marginTop ?? 8 }}>
                <Grid item xs={12}>
                    <Table              
                        columns={columns}
                        tableData={this.props.edicoes}
                        actions={[]}
                        page={null}
                        rowsPerPage={null}
                        sort={null}
                        fixedHeader={true}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default DadosInscricoesEdicao;