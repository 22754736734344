import React from 'react';
import { Line } from "react-chartjs-2";

import Grid from '@material-ui/core/Grid';

export const InscricoesAlunosPorData = ({ inscricoes }) => {
    return (
        <>
            {inscricoes.length > 0 && 
                <Grid item md={12} lg={6} >
                    <Line
                        data={{
                            labels: inscricoes.map((i) => i.dataFormatada),
                            datasets: [
                                {
                                    label: "Nível - Mirim 1",
                                    data: inscricoes.map((i) => i.mirim1),
                                    backgroundColor: "#F77CEB",
                                    borderColor: "#F77CEB",
                                },
                                {
                                    label: "Nível - Mirim 2",
                                    data: inscricoes.map((i) => i.mirim2),
                                    backgroundColor: "#F26405",
                                    borderColor: "#F26405",
                                },
                                {
                                    label: "Total de Alunos",
                                    data: inscricoes.map((i) => i.alunos),
                                    backgroundColor: "#094F12",
                                    borderColor: "#094F12",
                                },
                            ],
                        }}
                        options={{
                            elements: {
                                line: {
                                    tension: 0.5,
                                },
                            },
                            plugins: {
                                title: {
                                    text: "Alunos",
                                },
                            },
                        }}
                    />
                </Grid>
            }
        </>
    )
}

export default InscricoesAlunosPorData;