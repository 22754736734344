import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import { 
  isAfterDataProvaFase1, 
  isPeriodoInscricoes, 
  isAfterDataFimInscricaoEDataFimPagamento, 
  isPeriodoInscricoesParaEscolas,
  isPeriodoInserirNotaFase2,
  isPeriodoRegistroParticipacaoCadastroAlunos,
  isPeriodoInscricoesModalidadeUm,
  isBeforeFimCentralResetarPremiacao,
  isAbertaDataAlternativaPremiacao,
} from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import DescriptionIcon from '@material-ui/icons/Description';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import PlaylistAddOutlinedIcon from '@material-ui/icons/PlaylistAddOutlined';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import SettingsBackupRestoreOutlinedIcon from '@material-ui/icons/SettingsBackupRestoreOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import ReportIcon from '@material-ui/icons/Report';
import IconButton from '@material-ui/core/IconButton';
import MarkunreadMailboxOutlinedIcon from '@material-ui/icons/MarkunreadMailboxOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';

import { green, amber, red } from '@material-ui/core/colors';

import EscolaService from '../../services/Escola';
import InscricaoService from '../../services/Inscricao';
import { errors } from '../../services/API';

import { 
  patternSchools,
  patternLabelStatusInscricao, 
  patternLabelDepAdmin, 
  patternModalidade 
} from '../../variables/Enums/Escola';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Table from '../../components/Table/Table';
import Toast from '../../components/Toast/Toast';
import {
  verifyPagingAndSorting,
  PAGE,
  SIZE,
  ASC,
  TOTAL_ELEMENTS,
  TOTAL_PAGES,
} from '../../components/Table/Utils';

import EscolaFilter from '../../forms/Escola/EscolaFilter';
import { ButtonExport } from '../../forms/Buttons/ButtonsExport';
import { ButtonAdd } from '../../forms/Buttons/ButtonsAdd';
import DialogChangePassword from '../../forms/Dialog/DialogChangePassword';
import DialogDefinirDataAlternativaRealizarPremiacao from '../../forms/Dialog/DialogDefinirDataAlternativaRealizarPremiacao';
import DialogOcorrencias from '../../forms/Dialog/DialogOcorrencias';
import DialogEnderecoEntrega from '../../forms/Dialog/DialogEnderecoEntrega';
import { 
  DialogConfirm as DialogHabilitarDownloadFase2,
  DialogConfirm as DialogDesfazerPremiacao 
}  from '../../forms/Dialog/DialogConfirm';
import {
  DialogRemove as DialogRemoverEscola,
  DialogRemove as DialogRemoverInscricao
} from '../../forms/Dialog/DialogRemove';

import { makeStyles } from '@material-ui/core/styles';

import { evasaoLimiterMask } from '../../helper/MaskHelper';
import { verifyPersistence, removePagingAndSorting } from '../../helper/PaginationHelper';
import { parseDate } from '../../helper/DateHelper';

import AuthorizedFunction from '../../security/AuthorizedFunction';
import AuthorizedElement from '../../security/AuthorizedElement';
import {
  __ESCOLA_ADICIONAR,
  __ESCOLA_EXPORTAR,
  __ESCOLA_EDITAR,
  __ESCOLA_DASHBOARD,
  __ESCOLA_EDITAR_SENHA,
  __ESCOLA_RESPONSAVEL_EDITAR,
  __ESCOLA_REMOVER,
  __INSCRICAO_REMOVER,
  __RECIBO_ESCOLA,
  __COORDENADOR,
  __APOIO_SECRETARIAL,
  __ESCOLA_ADICIONAR_OPCOES_AVANCADAS,
} from '../../security/RoleConfiguration';

import { Layout } from '../../layouts/private/Private';

export const useStyles = makeStyles(theme => ({
  body: {
    margin: theme.spacing(3, 0),
  },
}));

export class Escola extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: PAGE,
      size: SIZE,
      totalElements: TOTAL_ELEMENTS,
      totalPages: TOTAL_PAGES,
      sort: [{ orderBy: 'cdMecEscola', order: ASC }],
      defaultOrderBy: 'cdMecEscola',
      filtered: false,
      filters: [],
      schools: [],
      loading: true,
      sendToSchools: [],
      loadingButtonSave: false,
      jaFezBuscaDaUrl: false,
    }

    this.state = verifyPersistence(this.state, this.props.location);
  }

  // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
  setToast = t => this.Toast = t;
  setEscolaFilter = f => this.EscolaFilter = f;
  setDialogRemoverInscricao = r => this.DialogRemoverInscricao = r;
  setDialogRemoverEscola = r => this.DialogRemoverEscola = r;
  setDialogDesfazerPremiacao  = r => this.DialogDesfazerPremiacao = r;
  setDialogChangePassword = f => this.DialogChangePassword = f;
  setDialogOcorrencias = o => this.DialogOcorrencias = o;
  setDialogEnderecoEntrega = o => this.DialogEnderecoEntrega = o;
  setDialogHabilitarDownloadFase2 = d => this.DialogHabilitarDownloadFase2 = d;
  setDialogDefinirDataAlternativaRealizarPremiacao = v => this.DialogDefinirDataAlternativaRealizarPremiacao = v;

  // EDIÇÃO
  handleEditClick = id => {
    this.props.history.push({
      pathname: `/admin/escola/${id}/editar`,
      state: {
        history: {
          path: `/admin/escola`,
          state: this.state,
        }
      }
    });
  }

  // EDIÇÃO
  handleInscreverClick = id => {
    this.props.history.push({
      pathname: `/admin/escola/${id}/inscrever`,
      state: {
        history: {
          path: `/admin/escola`,
          state: this.state,
        }
      }
    });
  }

  // LABELS
  labelEscolaSemInscricao = "Escola sem Inscrição";
  labelEscolaInscrita = "Escola Já Inscrita";
  labelEscolaSemPremiacao = "Escola Sem Premiação Realizada";
  labelEscolaComPremiacao = "Escola Com Premiação Realizada";
  labelSemPermissao = "Você não tem permissão para fazer isso";
  labelEscolaNaoTratada = "A Escola Ainda Não Tratada ou Foi Tratada Como Inválida";
  labelForaPeriodoInscricao = "Estamos Fora do Período de Inscrições";
  labelInscreverDentroDoPagamento = "Não é possível Inscrever uma Escola Privada fora do Período de Inscrições para as Escolas";
  labelForaPeriodoLancamentoNotas = "Estamos Fora do Período de Lançamento de Notas e Realização de Premiação";
  labelForaPeriodoCentralResetarPremiacao = "Estamos Fora do Período para a Central Resetar Premiações das Escolas";
  labelEscolaJaPossuiAlgumAlunoComNota = "A Escola Já Lançou Ao Menos Uma Nota de Fase 2, portanto não pode ter mais acesso as Funcionalidades de Premiação";

  labelDownloadFase2 = id => !this.disabledDownloadFase2(id) ? `${this.verificarSeEscolaPodeAcessarDownloadFase2(id) ? "Desabilitar" : "Habilitar"} Acesso ao Download de Material da Fase 2` : !this.habilitarDownloadFase2(id) ? this.labelEscolaSemInscricao : this.labelSemPermissao;
  labelEditar = id => !this.disabledEditar(id) ? "Editar" : this.labelSemPermissao;
  labelInscrever = id => !this.disabledInscrever(id) ? "Inscrever" : !this.isEscolaValidaOuEmAnalise(id) ? this.labelEscolaNaoTratada : (!isPeriodoInscricoes(this.props.contexts) && (this.isEscolaPublica(id) && !isPeriodoInscricoesModalidadeUm(this.props.contexts))) ? this.labelForaPeriodoInscricao : this.isEscolaInscrita(id) ? this.labelEscolaInscrita : this.verificarSeCentralOuCoordNaoPodemInscreverDepoisDoPrazoGeral(id) ? this.labelInscreverDentroDoPagamento : this.labelSemPermissao;
  labelAtualizarSenha = id => !this.disabledAtualizarSenha(id) ? "Alterar Senha" : !this.habilitarAtualizarSenha(id) ? this.labelEscolaSemInscricao : this.labelSemPermissao;
  labelResponsavel = id => !this.disabledResponsavel(id) ? "Editar Responsável pela Inscrição" : !this.habilitarEdicaoResponsavel(id) ? this.labelEscolaSemInscricao : this.labelSemPermissao;
  labelEnderecoEntrega = id => !this.disabledEnderecoEntrega(id) ? "Editar Endereço de Entrega da Escola" : !this.habilitarEdicaoEnderecoEntrega(id) ? this.labelEscolaSemInscricao : this.labelSemPermissao;
  labelRecibo = id => !this.disabledRecibo(id) ? "Ver Recibo de Escola" : !this.habilitarRecibo(id) ? this.labelEscolaSemInscricao : this.labelSemPermissao;
  labelDashboard = id => !this.disabledDashboard(id) ? "Ver Dashboard" : !this.habilitarVisaoDashboard(id) ? this.labelEscolaSemInscricao : this.labelSemPermissao;
  labelExcluir = id => !this.disabledExcluir(id) ? "Excluir Escola" : this.labelSemPermissao;
  labelDesfazerPremiacao = id => !this.disabledDesfazerPremiacao(id) ? "Resetar / Zerar Premiação dessa Escola" : !this.isEscolaInscrita(id) ? this.labelEscolaSemInscricao : !this.isPremiacaoRealizada(id) ? this.labelEscolaSemPremiacao : !isBeforeFimCentralResetarPremiacao(this.props.contexts) ? this.labelForaPeriodoCentralResetarPremiacao : !isPeriodoInserirNotaFase2(this.props.contexts) || !this.isAbertaDataAlternativaPremiacaoEscola(id) ? this.labelForaPeriodoLancamentoNotas : this.labelSemPermissao;
  labelRemoverInscricao = id => !this.disabledRemoverInscricao(id) ? "Remover Inscrição Desta Escola" : !this.isEscolaInscrita(id) ? this.labelEscolaSemInscricao :  this.isPremiacaoRealizada(id) ? this.labelEscolaComPremiacao : this.labelSemPermissao;
  labelDefinirDataAlternativaRealizarPremiacao = id => !this.disabledDefinirDataAlternativaRealizarPremiacao(id) ? "Definir Data Alternativa para Escola Lançar Notas e Executar Premiação" : !this.isEscolaInscrita(id) ? this.labelEscolaSemInscricao : this.verificarSeTemAlunosComNotaFase2(id) ? this.labelEscolaJaPossuiAlgumAlunoComNota : this.labelSemPermissao;

  // DISABLES
  disabledDownloadFase2 = id => AuthorizedFunction([__ESCOLA_EDITAR]) ? !this.habilitarDownloadFase2(id) : !AuthorizedFunction([__ESCOLA_EDITAR]);
  disabledEditar = id => !AuthorizedFunction([__ESCOLA_EDITAR]);
  disabledInscrever = id => !this.habilitarInscreverEscola(id);
  disabledAtualizarSenha = id => AuthorizedFunction([__ESCOLA_EDITAR_SENHA]) ? !this.habilitarAtualizarSenha(id) : !AuthorizedFunction([__ESCOLA_EDITAR_SENHA]);
  disabledResponsavel = id => AuthorizedFunction([__ESCOLA_RESPONSAVEL_EDITAR]) ? !this.habilitarEdicaoResponsavel(id) : !AuthorizedFunction([__ESCOLA_RESPONSAVEL_EDITAR])
  disabledEnderecoEntrega = id => AuthorizedFunction([__ESCOLA_EDITAR]) ? !this.habilitarEdicaoEnderecoEntrega(id) : !AuthorizedFunction([__ESCOLA_EDITAR])
  disabledRecibo = id => AuthorizedFunction([__RECIBO_ESCOLA]) ? !this.habilitarRecibo(id) : !AuthorizedFunction([__RECIBO_ESCOLA]);
  disabledDashboard = id => AuthorizedFunction([__ESCOLA_DASHBOARD]) ? !this.habilitarVisaoDashboard(id) : !AuthorizedFunction([__ESCOLA_DASHBOARD]);
  disabledExcluir = id => !AuthorizedFunction([__ESCOLA_REMOVER]);
  disabledDesfazerPremiacao = id => AuthorizedFunction([__INSCRICAO_REMOVER]) ? !this.habilitarDesfazerPremiacao(id) : !AuthorizedFunction([__INSCRICAO_REMOVER]);
  disabledRemoverInscricao = id => AuthorizedFunction([__INSCRICAO_REMOVER]) ? !this.habilitarRemoverInscricao(id) : !AuthorizedFunction([__INSCRICAO_REMOVER]);
  disabledDefinirDataAlternativaRealizarPremiacao = id => AuthorizedFunction([__ESCOLA_EDITAR]) ? !this.habilitarDefinirDataAlternativaRealizarPremiacao(id) : !AuthorizedFunction([__ESCOLA_EDITAR]);

  // EXPORTAÇÂO
  handleExportPlan = () => {
    this.EscolaFilter.setState({ filter: true, exportPlan: true, }, () => this.EscolaFilter.handleFilterApply());

    this.Toast.setState({
      message: {
        message: "A Planilha está sendo gerada para Exportação.",
        type: 'info',
        open: true,
        loading: true,
        autoHide: false,
      }
    })
  }

  // EDIÇÃO RESPONSÁVEL
  handleEditResponsibleClick = id => {
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    this.props.history.push({
      pathname: `/admin/escola/${school.cdMecEscola}/responsavel/editar`,
      state: {
        history: {
          path: `/admin/escola`,
          state: this.state,
        }
      }
    })
  }

  isAbertaDataAlternativaPremiacaoEscola = (id) => {    
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    return isAbertaDataAlternativaPremiacao(school.dataAlternativaPremiacao); 
  }

  // ESCOLA INSCRITA
  isEscolaInscrita = (id) => {
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    return school.idResponsavel && school.isInscrita ? true : false;
  }

  // ESCOLA VALIDA
  isEscolaValida = (id) => {
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    return school.nmStatus === 'VALIDA' ? true : false;
  }

  // ESCOLA ELEGIVEL PARA INSCRICAO
  isEscolaValidaOuEmAnalise = (id) => {
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    return school.nmStatus === 'VALIDA' || school.nmStatus === 'EM_ANALISE' ? true : false;
  }

  // ESCOLA PRIVADA SEM CONVENIO
  isEscolaPrivadaSemConvenio = (id) => {
      const school = this.state.schools.find(s => s.cdMecEscola === id);
      return school.nmDependenciaAdministrativa === 'PRIVADA' && !school.isConveniadaTotal ? true : false;
  }

  // ESCOLA PUBLICA
  isEscolaPublica = (id) => {
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    return school.nmDependenciaAdministrativa === 'MUNICIPAL' || school.nmDependenciaAdministrativa === 'ESTADUAL' || school.nmDependenciaAdministrativa === 'FEDERAL' ? true : false;
  }

  // ESCOLA VALIDA
  isPremiacaoRealizada = (id) => {
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    return school.statusPremiacao !== null ? true : false;
  }

  // VERIFICAR SE O CAMPO PRECISA ESTAR HABILITADO OU DESABILITADO
  habilitarEdicaoResponsavel = id => this.isEscolaInscrita(id);

  // VERIFICAR SE EXISTE INSCRICAO
  habilitarEdicaoEnderecoEntrega = id => this.isEscolaInscrita(id);

  // VERIFICAR SE INSCRICAO PODE SER REMOVIDA
  habilitarRemoverInscricao = id => this.isEscolaInscrita(id) && !this.isPremiacaoRealizada(id)

  // ATUALIZAR SENHA
  habilitarAtualizarSenha = id => this.isEscolaInscrita(id)

  // VERIFICAR SE INSCRICAO PODE SER REMOVIDA
  habilitarDesfazerPremiacao = id => this.isEscolaInscrita(id) && this.isPremiacaoRealizada(id) && (isPeriodoRegistroParticipacaoCadastroAlunos(this.props.contexts) || isBeforeFimCentralResetarPremiacao(this.props.contexts))

  // ESCOLA INSCRITA
  habilitarInscreverEscola = id => !this.isEscolaInscrita(id) && this.isEscolaValidaOuEmAnalise(id) && (isPeriodoInscricoes(this.props.contexts) || (this.isEscolaPublica(id) && isPeriodoInscricoesModalidadeUm(this.props.contexts))) && !this.verificarSeCentralOuCoordNaoPodemInscreverDepoisDoPrazoGeral(id);
  verificarSeCentralOuCoordNaoPodemInscreverDepoisDoPrazoGeral = id => !isPeriodoInscricoesParaEscolas(this.props.contexts) && this.isEscolaPrivadaSemConvenio(id);

  // ABRIR MODAL DE ATUALIZAR SENHA
  handleOpenAtualizarSenhaClick = id => this.DialogChangePassword.setState({ open: true, id: id });

  // VERIFICAR EXIBIÇÃO DO BOTÃO DE DASHBOARD
  habilitarVisaoDashboard = id => this.isEscolaInscrita(id)

  // VERIFICAR CONTEXTO PARA VER RECIBO  
  habilitarRecibo = id => this.isEscolaInscrita(id) ? true : false;

  habilitarDefinirDataAlternativaRealizarPremiacao = id => this.isEscolaInscrita(id) && !this.verificarSeTemAlunosComNotaFase2(id);
  
  verificarSeEscolaPodeAcessarDownloadFase2 = (id) => {
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    return school.downloadFase2;
  }

  verificarSeTemAlunosComNotaFase2 = (id) => {
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    return school.temAlunosComNotaFase2;
  }

  habilitarDownloadFase2 = (id) => this.isEscolaInscrita(id) && this.isEscolaValida(id);

  // ABRIR MODAL DE CONFIRMAÇÃO DE REMOÇÃO
  handleExcluir = id => {
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    const escolaInscrita = school.isInscrita ? `A Inscrição desta Escola será Desfeita ` : ``;
    this.DialogRemoverEscola.setState({
      dialog: {
        open: true,
        title: `Deseja Remover a Escola ${school.nmEscola} - ${school.cdMecEscola} ?`,
        text: `${escolaInscrita ? `e` : `A`} Escola não poderá ser recuperada depois de ser removida.`,
        id: id,
        loading: false,
      }
    });
  }

  // REMOÇÃO DE ESCOLA
  handleRemoverEscolaConfirm = (id) => {
    this.DialogRemoverEscola.loading();

    EscolaService.remove(id)
      .then(res => {
        this.Toast.setState({
          message: {
            message: "A Escola foi Excluída com Sucesso.",
            type: 'success',
            open: true
          }
        })
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      })
      .finally(() => {
        this.DialogRemoverEscola.close();
        this.loadData();
      });
  }

  // ABRIR MODAL DE CONFIRMAÇÃO DE REMOÇÃO de INSCRIÇÃO
  handleRemoverInscricao = id => {
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    this.DialogRemoverInscricao.setState({
      dialog: {
        open: true,
        title: `Deseja Remover a Inscrição da Escola ${school.nmEscola} - ${school.cdMecEscola} ?`,
        text: `A Inscrição desta Escola será Desfeita. A Escola Poderá ser Inscrita Novamente com Outras Informações.`,
        id: id,
        loading: false,
      }
    });
  }

  // REMOÇÃO DE INSCRIÇÃO
  handleRemoverInscricaoConfirm = (id) => {
    this.DialogRemoverInscricao.loading();

    InscricaoService.remove(id)
      .then(res => {
        this.Toast.setState({
          message: {
            message: "A Inscrição foi Removida com Sucesso.",
            type: 'success',
            open: true
          }
        })
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      })
      .finally(() => {
        this.DialogRemoverInscricao.close();
        this.loadData();
      });
  }

  // ABRIR MODAL PARA DEFINIR DATA ALTERNATIVA PARA ACOES DE PREMIACAO A ESCOLA
  handleDefinirDataAlternativaRealizarPremiacao = id => {
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    this.DialogDefinirDataAlternativaRealizarPremiacao.setState({ 
      open: true, 
      school: school, 
      habilitarDataAlternativa: school.dataAlternativaPremiacao ? true : false, 
      dataAlternativa: parseDate(school.dataAlternativaPremiacao) ?? null, 
    });
  }

  // ABRIR MODAL DE CONFIRMAÇÃO DE REMOÇÃO de INSCRIÇÃO
  handleDesfazerPremiacao = id => {
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    this.DialogDesfazerPremiacao.setState({
      dialog: {
        open: true,
        title: `Deseja Resetar / Zerar a Premiação da Escola ${school.nmEscola} - ${school.cdMecEscola} ?`,
        text: `A partir desta ação a Escola estará habilitada a Adicionar / Editar as Notas de Fase 2 dos seus Alunos, caso esteja aberto o Período de Lançamento de Notas da Fase 2, 
                e a Premiação desta Escola deverá ser executada novamente, exclusivamente na Listagem de Alunos desta Escola, na ação de Finalizar Lançamento de Notas.`,
        id: id,
        size: "lg",
        loading: false,
        showConfirm: true,
      }
    });
  }

    // DESFAZER PREMIACAO
    handleDesfazerPremiacaoConfirm = (id) => {
      this.DialogDesfazerPremiacao.loading();
  
      InscricaoService.resetarPremiacao(id)
        .then(res => {
          this.Toast.setState({
            message: {
              message: "A Premiação foi Resetada / Zerada com Sucesso.",
              type: 'success',
              open: true
            }
          })
        })
        .catch(error => {
          const e = errors(error);
          this.Toast.setState({
            message: {
              message: e.message,
              type: e.type,
              open: true
            }
          })
        })
        .finally(() => {
          this.DialogDesfazerPremiacao.close();
          this.loadData();
        });
    }

  // VER DASHBOARD COMO ESCOLA
  handleViewDashboard = id => {
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    this.props.history.push({
      pathname: `/admin/inicio`,
      state: {
        escola: {
          id: school.cdMecEscola
        }
      }
    });
  }

  // VER RELATÓRIO DE ESCOLA
  handleViewReceipt = (id) => {
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    this.props.history.push({
      pathname: `/admin/inscricao/${school.cdMecEscola}/recibo`,
      state: {
        history: {
          path: `/admin/escola`,
          state: this.state,
        }
      }
    });
  }

  // DIALOG DE HABILITAR DOWNLOAD DE FASE 2
  handleOpenDownloadFase2 = (id) => {
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    this.DialogHabilitarDownloadFase2.setState({
      dialog: {
        open: true,
        title: `Deseja ${this.verificarSeEscolaPodeAcessarDownloadFase2(id) ? "Desabilitar" : "Habilitar"} para a Escola ${school.nmEscola} o Download de Material de Fase 2?`,
        id: id,
        loading: false,
        showConfirm: true,
      }
    });
  }

  handleHabilitarDownloadFase2 = (id) => {
    this.DialogHabilitarDownloadFase2.loading();
    const action = this.verificarSeEscolaPodeAcessarDownloadFase2(id) ? "Desabilitado" : "Habilitado"; 

    EscolaService.downloadFase2(id, !this.verificarSeEscolaPodeAcessarDownloadFase2(id))
      .then(res => {
        this.Toast.setState({
          message: {
            message: `Download de Material de Fase 2 ${action} com Sucesso.`,
            type: 'success',
            open: true
          }
        })
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      })
      .finally(() => {
        this.DialogHabilitarDownloadFase2.close();
        this.loadData();
      });
  }

  loadData = async () => {
    this.props.loadContext();

    let filters = verifyPagingAndSorting(this.state);

    this.setState({
      filters: [...filters],
      filtered: true,
      loading: true
    });

    if (!this.state.jaFezBuscaDaUrl) {
      const parseFilters = queryString.parse(this.props.location.search);

      if (parseFilters.status) filters.push({ field: 'nmStatus', value: parseFilters.status })
      if (parseFilters.inep) filters.push({ field: 'cdEscola', value: parseFilters.inep })
      if (parseFilters.modalidade) filters.push({ field: 'modalidade', value: parseFilters.modalidade })
      if (parseFilters.depAdmin) filters.push({ field: 'nmDependenciaAdministrativa', value: parseFilters.depAdmin })
      if (parseFilters.uf) filters.push({ field: 'cdUf', value: parseFilters.uf })
      if (parseFilters.municipio) filters.push({ field: 'cdMunicipio', value: parseFilters.municipio })
      if (parseFilters.inscrita) filters.push({ field: 'inscrita', value: parseFilters.inscrita })
      if (parseFilters.dataInscricao) filters.push({ field: 'dataInscricao', value: parseFilters.dataInscricao })

      this.setState({ jaFezBuscaDaUrl: true });
    }

    await EscolaService.filters(filters)
      .then(res => {
        if (res.data.content) {
          const schools = patternSchools(res.data.content);
          this.setState({
            schools: [...schools],
            page: res.data.number,
            size: res.data.size,
            totalElements: res.data.totalElements,
            totalPages: res.data.totalPages,
            loading: false,
          });

          this.EscolaFilter.handleFilterPersistence(filters);
        } else {
          this.setState({
            schools: [],
            page: PAGE,
            size: SIZE,
            totalElements: TOTAL_ELEMENTS,
            totalPages: TOTAL_PAGES,
            loading: false,
          });
        }
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })

        this.setState({ loading: false });
      })
  }

  // FILTROS
  filterData = (filters, isActive = true) => this.setState({ filtered: isActive, filters: [...removePagingAndSorting(filters)] }, () => this.loadData());

  // PÁGINA
  handlePage = page => this.setState({ page: page }, () => this.filterData(this.state.filters));

  // LINHAS POR PÁGINA
  handleRowsPerPage = (size, page) => this.setState({ size: size, page: page }, () => this.filterData(this.state.filters));

  // ORDENAÇÃO
  handleSort = (orderBy, order) => {
    if (this.state.sort.find(s => s.orderBy === orderBy)) {
        let newSort = this.state.sort.filter(s => s.orderBy !== orderBy);
        newSort.splice(this.state.sort.indexOf(this.state.sort.find(s => s.orderBy === orderBy)), 0, { orderBy: orderBy, order: order })
        this.setState({...this.state, sort: [...newSort], filtered: true });
    } else {
        this.setState({ ...this.state, sort: [...this.state.sort.filter(s => s.orderBy !== orderBy), { orderBy: orderBy, order: order }], filtered: true });
    }
  }
  handleClearSort = (orderBy) => {
      this.setState({ ...this.state, filtered: true, sort: orderBy ? [...this.state.sort.filter(s => s.orderBy !== orderBy)] : [{ orderBy: this.state.defaultOrderBy, order: ASC }] });
  };

  // TOTAL DE PAGINAS
  handleTotalPages = () => this.state.totalPages;

  // TOTAL DE ELEMENTOS
  handleTotalElements = () => this.state.totalElements;

  // VERIFICAR PERSISTENCIA NO FORMULARIO DE FILTROS
  handleFilterPersistence = () => this.EscolaFilter ? this.EscolaFilter.state ? this.EscolaFilter.handleFilterPersistence(this.state.filters) : null : null;

  componentDidMount() { this.loadData(); }

  buttonOpenModalOcorrencias = (school, name, icon) => {
    return <>
            {AuthorizedFunction([__ESCOLA_ADICIONAR_OPCOES_AVANCADAS]) && 
              <Tooltip title={name} TransitionComponent={Zoom}>
                <IconButton 
                    size="small" 
                    component="div" 
                    aria-label={name} 
                    onClick={() => this.handleOpenModalOcorrencias(school)}
                  >
                  {icon}
                </IconButton>
              </Tooltip>
            }
          </>
  }

  handleOpenModalOcorrencias = school => this.DialogOcorrencias.setState({ open: true, escola: school });

  handleOpenModalEnderecoEntrega = id => this.DialogEnderecoEntrega.setState({ open: true, school: { id: id }});

  patternLabelDepAdminList = (value, id) => {
    const school = this.state.schools.find(s => s.cdMecEscola === id);

    return <span style={{ display: "flex", flexDirection: "row", alignItems: "center" }}> 
            {patternLabelDepAdmin(school.nmDependenciaAdministrativa)}

            {(school.nmStatus === "VALIDA" && school.ocorrencias.length > 0) ?
              this.buttonOpenModalOcorrencias(school, "Ocorrências Tratadas", <CheckCircleIcon style={{ color: green[700] }} />)

            : school.nmStatus === "EM_ANALISE" ? school.ocorrencias.length > 0 ? this.buttonOpenModalOcorrencias(school, "Escola Necessita de Validação de Ocorrência", <ReportProblemIcon style={{ color: amber[700] }} />)
              : <Tooltip title="Escola Necessita de Validação de Ocorrência" TransitionComponent={Zoom}><ReportProblemIcon style={{ color: amber[700] }} /></Tooltip>

            : school.nmStatus === "INVALIDA" ? school.ocorrencias.length > 0 ? this.buttonOpenModalOcorrencias(school, "Escola Inválida", <ReportIcon style={{ color: red[700] }} />)
              : <Tooltip title="Escola Inválida" TransitionComponent={Zoom}><ReportIcon style={{ color: red[700] }} /></Tooltip> 

            : <></> }
          </span>
  }
  
  render() {
    const pageName = 'Escolas';
    const links = [
      {
        path: null,
        name: 'Administração'
      }
    ];

    const columns = [
      { label: 'INEP', name: 'cdMecEscola', func: null, key: true },
      { label: 'Escola', name: 'nmEscola', func: null, key: false },
      { label: 'Dep. Adm.', name: 'nmDependenciaAdministrativa', func: this.patternLabelDepAdminList, key: false },
      { label: 'UF', name: 'nmUf', func: null, key: false, ordering: false },
      { label: 'Município', name: 'nmMunicipio', func: null, key: false, ordering: false },
      { label: 'Modalidade', name: isAfterDataFimInscricaoEDataFimPagamento(this.props.contexts) ? 'modalidadeInscricao' : 'modalidade', func: patternModalidade, key: false, ordering: false },
      { label: 'Status de Inscrição', name: 'statusInscricao', func: patternLabelStatusInscricao, key: false, ordering: false },
      { label: 'Total de Alunos Previstos', name: 'qtdAlunosPrevistosTotal', func: null, key: false, ordering: false },
      { label: 'Total de Alunos Participantes', name: 'qtdAlunosParticipantesTotal', func: null, key: false, ordering: false },
      { label: 'Evasão Total', name: 'evasaoTotal', func: isAfterDataProvaFase1(this.props.contexts) ? evasaoLimiterMask : () => "Prova Não Realizada", key: false, ordering: false },
    ];

    const actions = [
      {
        name: this.labelEditar,
        func: this.handleEditClick,
        icon: <EditOutlinedIcon />,
        disabled: this.disabledEditar,
      },
      {
        name: this.labelInscrever,
        func: this.handleInscreverClick,
        icon: <PlaylistAddOutlinedIcon />,
        disabled: this.disabledInscrever, 
      },
      {
        name: this.labelResponsavel,
        func: this.handleEditResponsibleClick,
        icon: <SupervisorAccountIcon />,
        disabled: this.disabledResponsavel,
      },
      {
        name: this.labelEnderecoEntrega,
        func: this.handleOpenModalEnderecoEntrega,
        icon: <MarkunreadMailboxOutlinedIcon />,
        disabled: this.disabledEnderecoEntrega,
      },
      {
        name: this.labelAtualizarSenha,
        func: this.handleOpenAtualizarSenhaClick,
        icon: <VpnKeyOutlinedIcon />,
        disabled: this.disabledAtualizarSenha,
      },
      {
        name: this.labelDownloadFase2,
        func: this.handleOpenDownloadFase2,
        icon: (id) => this.verificarSeEscolaPodeAcessarDownloadFase2(id) ? <LockOpenOutlinedIcon /> : <LockIcon />,
        disabled: this.disabledDownloadFase2,
      },
      {
        name: this.labelRecibo,
        func: this.handleViewReceipt,
        icon: <DescriptionIcon />,
        disabled: this.disabledRecibo,
      },
      {
        name: this.labelDashboard,
        func: this.handleViewDashboard,
        icon: <LaptopMacIcon />,
        disabled: this.disabledDashboard,
      },
      {
        name: this.labelDefinirDataAlternativaRealizarPremiacao,
        func: this.handleDefinirDataAlternativaRealizarPremiacao,
        icon: <DateRangeOutlinedIcon />,
        disabled: this.disabledDefinirDataAlternativaRealizarPremiacao,
      },
      {
        name: this.labelDesfazerPremiacao,
        func: this.handleDesfazerPremiacao,
        icon: <SettingsBackupRestoreOutlinedIcon />,
        disabled: this.disabledDesfazerPremiacao,
        color: "secondary"
      },
      {
        name: this.labelRemoverInscricao,
        func: this.handleRemoverInscricao,
        icon: <HighlightOffOutlinedIcon />,
        disabled: this.disabledRemoverInscricao,
        color: "secondary"
      },
      {
        name: this.labelExcluir,
        func: this.handleExcluir,
        icon: <DeleteOutlinedIcon />,
        disabled: this.disabledExcluir,
        color: "secondary"
      },
    ];

    const actionsCoord = [
      {
        name: this.labelInscrever,
        func: this.handleInscreverClick,
        icon: <PlaylistAddOutlinedIcon />,
        disabled: this.disabledInscrever, 
      },
      {
        name: this.labelAtualizarSenha,
        func: this.handleOpenAtualizarSenhaClick,
        icon: <VpnKeyOutlinedIcon />,
        disabled: this.disabledAtualizarSenha,
      },
      {
        name: this.labelRecibo,
        func: this.handleViewReceipt,
        icon: <DescriptionIcon />,
        disabled: this.disabledRecibo,
      },
    ];

    this.handleFilterPersistence();

    return (
      <Layout>
        <Toast parentRef={this.setToast} />
        <Grid container >
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={pageName} />
          </Grid>
        </Grid>
        <Title>{pageName}</Title>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <EscolaFilter
              parentRef={this.setEscolaFilter}
              handleFilterChange={this.filterData}
            />
            <Grid container spacing={3}>
              <AuthorizedElement roles={[__ESCOLA_ADICIONAR]}>
                <ButtonAdd
                  to={{ pathname: '/admin/escola/adicionar', state: { history: { path: '/admin/escola', state: this.state, } } }}
                  title="Adicionar Escola"
                />
              </AuthorizedElement>
              <AuthorizedElement roles={[__ESCOLA_EXPORTAR]}>
                {this.state.filters.length > 3 &&
                  <ButtonExport
                    title="Exportar Planilha"
                    onClick={this.handleExportPlan}
                  />
                }
              </AuthorizedElement>
            </Grid>
            <Table
              columns={columns}
              tableData={this.state.schools}
              actions={AuthorizedFunction([__COORDENADOR]) || AuthorizedFunction([__APOIO_SECRETARIAL]) ? actionsCoord : actions}
              page={this.state.page}
              handlePage={this.handlePage}
              handleTotalPages={this.handleTotalPages}
              handleTotalElements={this.handleTotalElements}
              rowsPerPage={this.state.size}
              handleRowsPerPage={this.handleRowsPerPage}
              sort={this.state.sort}
              handleSort={this.handleSort}
              handleClearSort={this.handleClearSort}
              loading={this.state.loading}
            />
          </Grid>
        </Grid>

        {AuthorizedFunction([__ESCOLA_EDITAR_SENHA]) &&
          <DialogChangePassword
              parentRef={this.setDialogChangePassword}
              handleAtualizarSenha={EscolaService.atualizarSenha}
          />
        }

        {AuthorizedFunction([__ESCOLA_EDITAR]) &&
          <>
            <DialogHabilitarDownloadFase2
              parentRef={this.setDialogHabilitarDownloadFase2}
              handleConfirm={this.handleHabilitarDownloadFase2}
            />

            <DialogDesfazerPremiacao
              parentRef={this.setDialogDesfazerPremiacao}
              handleConfirm={this.handleDesfazerPremiacaoConfirm}
            />

            <DialogDefinirDataAlternativaRealizarPremiacao
              parentRef={this.setDialogDefinirDataAlternativaRealizarPremiacao}
              handleClose={this.loadData}
            />
          </>
        }

        {AuthorizedFunction([__ESCOLA_REMOVER]) &&
          <DialogRemoverEscola
            parentRef={this.setDialogRemoverEscola}
            handleConfirm={this.handleRemoverEscolaConfirm}
          />
        }

        {AuthorizedFunction([__INSCRICAO_REMOVER]) &&
          <DialogRemoverInscricao
            parentRef={this.setDialogRemoverInscricao}
            handleConfirm={this.handleRemoverInscricaoConfirm}
          />
        }

        {AuthorizedFunction([__ESCOLA_ADICIONAR_OPCOES_AVANCADAS]) &&
          <DialogOcorrencias 
            parentRef={this.setDialogOcorrencias} 
            handleClose={this.loadData}
          />
        }

        {AuthorizedFunction([__ESCOLA_EDITAR]) &&
          <DialogEnderecoEntrega 
            parentRef={this.setDialogEnderecoEntrega}
            handleClose={this.loadData}
          />
        }
      </Layout>
    )
  }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Escola));