import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Table from '../../components/Table/Table';

import { numberFormat } from '../../helper/MaskHelper';

export class DadosInscricoesMunicipio extends Component {
    render () {
        const columns = [
            { label: 'Edicão', name: 'edicao', func: null, key: true, width: '35', orderLabel: 'desc', },
            { label: 'Municipios Inscritos', name: 'municipios', func: numberFormat, key: false, width: '65', },
        ];

        return (
            <Grid container spacing={3} style={{ marginTop: this.props.marginTop ?? 8 }}>
                <Grid item xs={12}>
                    <Table              
                        columns={columns}
                        tableData={this.props.edicoes}
                        actions={[]}
                        page={null}
                        rowsPerPage={null}
                        sort={null}
                        fixedHeader={true}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default DadosInscricoesMunicipio;