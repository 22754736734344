import React, { Component } from 'react';

import { isAfterDataProvaFase1, isAfterDataFimInscricaoEDataFimPagamento } from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';

import Table, { DataTableLineTotals as Totals } from '../../components/Table/Table';

import { evasaoLimiterMask, numberFormat } from '../../helper/MaskHelper';

import { 
    ButtonNewTabEscolasPorUf,
    ButtonNewTabEscolasPorUfAndStatus,
    ButtonNewTabAlunosInscritosAndUf, 
    ButtonNewTabAlunosInscritosAndUfAndNivel, 
    ButtonNewTabAlunosParticipantesAndUf, 
    ButtonNewTabAlunosParticipantesAndUfAndNivel, 
} from '../Buttons/ButtonsNewTab';

export class DadosUfs extends Component {
    // SOMA DE UF'S
    sumUfs = () => this.props.ufs.length;

    // SOMA GERAL PARA ESCOLAS INSCRITAS
    sumEscolas = () => this.props.ufs.reduce((total, uf) => total + uf.qtdEscolasTotal, 0);
    sumEscolasInscritas = () => this.props.ufs.reduce((total, uf) => total + uf.qtdEscolasInscritasTotal, 0);
    sumEscolasAguardandoPagamento = () => this.props.ufs.reduce((total, uf) => total + uf.qtdEscolasAguardandoPagamentoTotal, 0);

    sumMunicipios = () => this.props.ufs.reduce((total, uf) => total + uf.qtdMunicipiosTotal, 0);
    sumMunicipiosInscritos = () => this.props.ufs.reduce((total, uf) => total + uf.qtdMunicipiosInscritosTotal, 0);

    // SOMA GERAL PARA ALUNOS PREVISTOS
    sumAlunosPrevistos = () => this.props.ufs.reduce((total, uf) => total + uf.qtdAlunosPrevistosTotal, 0);

    // SOMA GERAL PARA ALUNOS PREVISTOS MIRIM 1
    sumAlunosPrevistosMirim1 = () => this.props.ufs.reduce((total, uf) => total + uf.qtdAlunosPrevistosMirim1, 0);

    // SOMA GERAL PARA ALUNOS PREVISTOS MIRIM 2
    sumAlunosPrevistosMirim2 = () => this.props.ufs.reduce((total, uf) => total + uf.qtdAlunosPrevistosMirim2, 0);

    // SOMA GERAL PARA ALUNOS PARTICIPANTES
    sumAlunosParticipantes = () => this.props.ufs.reduce((total, uf) => total + uf.qtdAlunosParticipantesTotal, 0);

    sumAlunosInscritosFase2 = () => this.props.ufs.reduce((total, uf) => total + uf.qtdAlunosInscritosFase2, 0);
    sumAlunosInscritosFase2Mirim1 = () => this.props.ufs.reduce((total, uf) => total + uf.qtdAlunosInscritosFase2Mirim1, 0);
    sumAlunosInscritosFase2Mirim2 = () => this.props.ufs.reduce((total, uf) => total + uf.qtdAlunosInscritosFase2Mirim2, 0);
    
    sumAlunosParticipantesFase2 = () => this.props.ufs.reduce((total, uf) => total + uf.qtdAlunosParticipantesFase2, 0);
    sumAlunosParticipantesFase2Mirim1 = () => this.props.ufs.reduce((total, uf) => total + uf.qtdAlunosParticipantesFase2Mirim1, 0);
    sumAlunosParticipantesFase2Mirim2 = () => this.props.ufs.reduce((total, uf) => total + uf.qtdAlunosParticipantesFase2Mirim2, 0);

    // CALCULAR EVASÃO
    calcEvasao = () => 100 - ((this.sumAlunosParticipantes() * 100) / this.sumAlunosPrevistos());

    newTabEscolasPorUf = (qtd, uf) => <ButtonNewTabEscolasPorUf qtd={numberFormat(qtd)} uf={uf} />
    newTabEscolasPorUfAndStatus = (qtd, uf, status) => <ButtonNewTabEscolasPorUfAndStatus qtd={numberFormat(qtd)} uf={uf} status={status} />
    newTabInscritosAndUf = (qtd, uf) => <ButtonNewTabAlunosInscritosAndUf qtd={numberFormat(qtd)} uf={uf} />
    newTabInscritosAndUfAndNivel = (qtd, uf, nivel) => <ButtonNewTabAlunosInscritosAndUfAndNivel qtd={numberFormat(qtd)} uf={uf} nivel={nivel} />
    newTabParticipantesAndUf = (qtd, uf) => <ButtonNewTabAlunosParticipantesAndUf qtd={numberFormat(qtd)} uf={uf} />
    newTabParticipantesAndUfAndNivel = (qtd, uf, nivel) => <ButtonNewTabAlunosParticipantesAndUfAndNivel qtd={numberFormat(qtd)} uf={uf} nivel={nivel} />

    render () {
        const columns = [
            { label: 'UF', name: 'nmUf', func: null, key: true, width: '5', orderLabel: 'asc', },
            { label: 'Total de Escolas', name: 'qtdEscolasTotal', func: this.newTabEscolasPorUf, key: false, width: '5', },
            { label: 'Total de Escolas Inscritas', name: 'qtdEscolasInscritasTotal', func: (qtd, uf) => this.newTabEscolasPorUfAndStatus(qtd, uf, "CONCLUIDA"), key: false, width: '5', },
            { label: 'Total de Escolas Aguardando Pagamento', name: 'qtdEscolasAguardandoPagamentoTotal', func: (qtd, uf) => this.newTabEscolasPorUfAndStatus(qtd, uf, "AGUARDANDO_PAGAMENTO"), key: false, width: '5', hidden: isAfterDataFimInscricaoEDataFimPagamento(this.props.contexts), },
            { label: 'Total de Municipios', name: 'qtdMunicipiosTotal', func: numberFormat, key: false, width: '5', },
            { label: 'Total de Municipios Inscritos', name: 'qtdMunicipiosInscritosTotal', func: numberFormat, key: false, width: '5', },
            { label: 'Fase 1 - Mirim 1 - Alunos Inscritos', name: 'qtdAlunosPrevistosMirim1', func: numberFormat, key: false, width: '5', },
            { label: 'Fase 1 - Mirim 2 - Alunos Inscritos', name: 'qtdAlunosPrevistosMirim2', func: numberFormat, key: false, width: '5', },
            { label: 'Fase 1 - Total de Alunos Inscritos', name: 'qtdAlunosPrevistosTotal', func: numberFormat, key: false, width: '5', },
            { label: 'Fase 1 - Total de Alunos Participantes', name: 'qtdAlunosParticipantesTotal', func: numberFormat, key: false, width: '5', },
            { label: 'Fase 1 - Evasão Total', name: 'evasaoTotal', func: isAfterDataProvaFase1(this.props.contexts) ? evasaoLimiterMask : () => "Prova Não Realizada", key: false, width: '5', },
            { label: 'Fase 2 - Mirim 1 - Alunos Inscritos', name: 'qtdAlunosInscritosFase2Mirim1', func: (qtd, uf) => this.newTabInscritosAndUfAndNivel(qtd, uf, "MIRIM_1"), key: false, width: '5', },
            { label: 'Fase 2 - Mirim 2 - Alunos Inscritos', name: 'qtdAlunosInscritosFase2Mirim2', func: (qtd, uf) => this.newTabInscritosAndUfAndNivel(qtd, uf, "MIRIM_2"), key: false, width: '5', },
            { label: 'Fase 2 - Total de Alunos Inscritos', name: 'qtdAlunosInscritosFase2', func: this.newTabInscritosAndUf, key: false, width: '5', },
            { label: 'Fase 2 - Mirim 1 - Alunos Com Nota Lançada', name: 'qtdAlunosParticipantesFase2Mirim1', func: (qtd, uf) => this.newTabParticipantesAndUfAndNivel(qtd, uf, "MIRIM_1"), key: false, width: '5', },
            { label: 'Fase 2 - Mirim 2 - Alunos Com Nota Lançada', name: 'qtdAlunosParticipantesFase2Mirim2', func: (qtd, uf) => this.newTabParticipantesAndUfAndNivel(qtd, uf, "MIRIM_2"), key: false, width: '5', },
            { label: 'Fase 2 - Total de Alunos Com Nota Lançada', name: 'qtdAlunosParticipantesFase2', func: this.newTabParticipantesAndUf, key: false, width: '5', },
        ];
        
        const actions = [];

        const totals = [
            { column: 0, value: this.sumUfs(), width: '5', },
            { column: 1, value: numberFormat(this.sumEscolas()), width: '5', },
            { column: 2, value: numberFormat(this.sumEscolasInscritas()), width: '5', },
            { column: 3, value: numberFormat(this.sumEscolasAguardandoPagamento()), width: '5', hidden: isAfterDataFimInscricaoEDataFimPagamento(this.props.contexts), },
            { column: 4, value: numberFormat(this.sumMunicipios()), width: '5', },
            { column: 5, value: numberFormat(this.sumMunicipiosInscritos()), width: '5', },
            { column: 6, value: numberFormat(this.sumAlunosPrevistosMirim1()), width: '5', },
            { column: 7, value: numberFormat(this.sumAlunosPrevistosMirim2()), width: '5', },
            { column: 8, value: numberFormat(this.sumAlunosPrevistos()), width: '5', },
            { column: 9, value: numberFormat(this.sumAlunosParticipantes()), width: '5', },
            { column: 10, value: isAfterDataProvaFase1(this.props.contexts) ? evasaoLimiterMask(this.calcEvasao()) : () => "Prova Não Realizada", width: '5', },
            { column: 11, value: numberFormat(this.sumAlunosInscritosFase2Mirim1()), width: '5', },
            { column: 12, value: numberFormat(this.sumAlunosInscritosFase2Mirim2()), width: '5', },
            { column: 13, value: numberFormat(this.sumAlunosInscritosFase2()), width: '5', },
            { column: 14, value: numberFormat(this.sumAlunosParticipantesFase2Mirim1()), width: '5', },
            { column: 15, value: numberFormat(this.sumAlunosParticipantesFase2Mirim2()), width: '5', },
            { column: 16, value: numberFormat(this.sumAlunosParticipantesFase2()), width: '5', },
        ];

        return (
            <Grid container spacing={3} style={{ marginTop: this.props.marginTop ?? 8 }}>
                <Grid item xs={12}>
                    <Table              
                        columns={columns}
                        tableData={this.props.ufs}
                        actions={actions}
                        page={null}
                        rowsPerPage={null}
                        sort={null}
                        fixedHeader={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Totals lineData={totals} columns={columns} />
                </Grid>
            </Grid>
        )
    }
}

export default DadosUfs;