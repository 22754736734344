import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Table, { DataTableLineTotals as Totals } from '../../components/Table/Table';
import {
    PAGE,
    TOTAL_ELEMENTS,
    TOTAL_PAGES,
} from '../../components/Table/Utils';

import { ButtonNewTabInep, ButtonNewTabModalidade } from '../Buttons/ButtonsNewTab';

import { moneyMask, numberFormat } from '../../helper/MaskHelper';

import { patternModalidade } from '../../variables/Enums/Escola';

export class DadosPagamentos extends Component {
    constructor (props) {
        super(props);
        this.state = {
            page: PAGE,
            size: 25,
            totalElements: TOTAL_ELEMENTS,
            totalPages: TOTAL_PAGES,
        }
    }

    sumEscolas = () => this.props.schools.length;
    sumPagamentosInscricao = () => this.props.schools.reduce((total, sch) => total + parseInt(sch.valorInscricao, 10), 0);
    sumPagamentosPremiacao = () => this.props.schools.reduce((total, sch) => total + parseInt(sch.valorPremiacao, 10), 0);
    sumPagamentosTotal = () => this.props.schools.reduce((total, sch) => total + parseInt(sch.valorTotal, 10), 0);

    // PAGINAÇÃO APENAS NA VIEW
    handlePage = (value) => this.setState({ ...this.state, page: value, });
    handleRowsPerPage = (value) => this.setState({ ...this.state, size: value, page: 0, });
    handleTotalPages = () => Math.ceil(this.props.schools.length / this.state.size);
    handleTotalElements = () => this.props.schools.length;

    newTabInep = (inep) => <ButtonNewTabInep inep={inep} />
    newTabModalidade = (modalidade) => <ButtonNewTabModalidade value={modalidade} label={patternModalidade(modalidade)} />

    render () {
        const columns = [
            { label: 'INEP', name: 'inep', func: this.newTabInep, key: true, width: '7', ordering: false, orderLabel: 'asc', },
            { label: 'Escola', name: 'nome', func: null, key: false, width: '18', ordering: false },
            { label: 'Dep. Adm.', name: 'dependencia', func: null, key: false, width: '5', ordering: false },
            { label: 'Modalidade', name: 'modalidade', func: this.newTabModalidade, key: false, width: '10', ordering: false },
            { label: 'Valor de Inscrição', name: 'valorInscricao', func: moneyMask, key: false, width: '10', ordering: false },
            { label: 'Valor de Premiação', name: 'valorPremiacao', func: moneyMask, key: false, width: '10', ordering: false },
            { label: 'Valor Total', name: 'valorTotal', func: moneyMask, key: false, width: '10', ordering: false },
            { label: 'Região', name: 'regiao', func: null, key: false, width: '5', ordering: false },
            { label: 'Municipio', name: 'municipio', func: null, key: false, width: '15', ordering: false },
            { label: 'UF', name: 'uf', func: null, key: false, width: '5', ordering: false },
        ];
        
        const actions = [];

        const totals = [
            { column: 0, label: 'Totais', value: "#", width: '5', },
            { column: 1, label: 'Escolas', value: numberFormat(this.sumEscolas()), width: '20', },
            { column: 2, label: 'Valor Total de Inscrições', value: moneyMask(this.sumPagamentosInscricao()), width: '25', },
            { column: 3, label: 'Valor Total de Premiações', value: moneyMask(this.sumPagamentosPremiacao()), width: '25', },
            { column: 4, label: 'Valor Total', value: moneyMask(this.sumPagamentosTotal()), width: '25', },
        ];

        return (
            <Grid container spacing={3} style={{ marginTop: this.props.marginTop ?? 8 }}>
                <Grid item xs={12}>
                    <Table              
                        columns={columns}
                        tableData={this.props.schools.slice(this.state.page * this.state.size, (this.state.page * this.state.size) + this.state.size)}
                        actions={actions}
                        page={this.state.page}
                        handlePage={this.handlePage}
                        handleTotalPages={this.handleTotalPages}
                        handleTotalElements={this.handleTotalElements}
                        rowsPerPage={this.state.size}
                        handleRowsPerPage={this.handleRowsPerPage}
                        sort={null}
                        fixedHeader={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Totals lineData={totals} columns={columns} showHead={true} />
                </Grid>
            </Grid>
        )
    }
}

export default DadosPagamentos;