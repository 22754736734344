import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { loadContext } from '../../store/actions/Contexto';
import { isPeriodoInscricoesParaEscolas, isPeriodoInscricoesModalidadeUmParaEscolas } from '../../helper/ContextHelper';

import { useKeycloak } from '@react-keycloak/web'

import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';

import { MenuMirim, BannerHeader } from './Style';

import useStyles, { ButtonAppBarCollapse } from './Style';

import AuthorizedFunction from '../../security/AuthorizedFunction';
import {
    __ADMINISTRADOR,
    __APOIO_SECRETARIAL,
    __CENTRAL,
    __COORDENADOR,
} from '../../security/RoleConfiguration';

const Header = props => {
    const classes = useStyles();

    const contexts = useSelector(store => store.contexts);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadContext());
    }, [dispatch])

    return (
        <div className={props.className}>
            <BannerHeader />

            <MenuMirim>
                <ButtonAppBarCollapse className={classes.menuColapsado}>
                    {(isPeriodoInscricoesParaEscolas(contexts) || isPeriodoInscricoesModalidadeUmParaEscolas(contexts)) &&
                        <MenuItem style={{ backgroudColor: '#E60480' }}>
                            <ButtonInscricao />
                        </MenuItem>
                    }
                    <MenuItem style={{ backgroudColor: '#E60480' }}>
                        <ButtonQuemSomos />
                    </MenuItem>
                    <MenuItem style={{ backgroudColor: '#E60480' }}>
                        <ButtonCalendario />
                    </MenuItem>
                    <MenuItem style={{ backgroudColor: '#E60480' }}>
                        <ButtonListaEscolasPublico />
                    </MenuItem>
                    <MenuItem style={{ backgroudColor: '#E60480' }}>
                        <ButtonRegulamento />
                    </MenuItem>
                    <MenuItem style={{ backgroudColor: '#E60480' }}>
                        <ButtonMaterialDidatico />
                    </MenuItem>
                    <MenuItem>
                        <ButtonAreaRestrita />
                    </MenuItem>
                </ButtonAppBarCollapse>

                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    align="center"
                    className={classes.menuNormal}
                >
                    {(isPeriodoInscricoesParaEscolas(contexts) || isPeriodoInscricoesModalidadeUmParaEscolas(contexts)) &&
                        <Grid item container xs={12} sm={2} lg={2} spacing={1} justify="center">
                            <ButtonInscricao />
                        </Grid>
                    }
                    <Grid item container xs={12} sm={2} lg={2} spacing={2} justify="center">
                        <ButtonQuemSomos />
                    </Grid>
                    <Grid item container xs={12} sm={2} lg={2} spacing={1} justify="center">
                        <ButtonCalendario />
                    </Grid>
                    <Grid item container xs={12} sm={1} lg={1} spacing={1} justify="center">
                        <ButtonListaEscolasPublico />
                    </Grid>
                    <Grid item container xs={12} sm={2} lg={2} spacing={1} justify="center">
                        <ButtonRegulamento />
                    </Grid>
                    <Grid item container xs={12} sm={1} lg={1} spacing={1} justify="center">
                        <ButtonMaterialDidatico />
                    </Grid>
                    <Grid item container xs={12} sm={2} lg={2} spacing={1} justify="center">
                        <ButtonAreaRestrita />
                    </Grid>
                </Grid>
            </MenuMirim>
        </div>
    )
}

const ButtonAreaRestrita = () => {
    const classes = useStyles();
    const history = useHistory();

    const [keycloak,] = useKeycloak();
    const handleSubmitSignIn = () => keycloak.login({ redirectUri: `${process.env.REACT_APP_PUBLIC_URL}/admin/inicial` });
    const handleRestrictArea = () => history.push(`/admin/${AuthorizedFunction([ __ADMINISTRADOR, __CENTRAL, __COORDENADOR, __APOIO_SECRETARIAL ]) ? `inicial` : `inicio`}`);

    return (
        <Button
            variant="contained"
            color="primary"
            className={classes.buttonMenu}
            onClick={!keycloak.authenticated ? handleSubmitSignIn : handleRestrictArea}
        >
            Acesso à Área Restrita
        </Button>
    )
}

const ButtonRegulamento = () => {
    const classes = useStyles();

    const history = useHistory();
    const handleRegulamento = () => {
        history.push({ pathname: `/regulamento` });
    }

    return (
        <Button
            variant="contained"
            color="secondary"
            disableElevation
            className={classes.buttonMenu}
            style={{ backgroundColor: '#E60480' }}
            onClick={handleRegulamento}
        >
            Regulamento
        </Button>
    )
}

const ButtonQuemSomos = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Button 
                onClick={handleMenu}
                variant="contained"
                color="secondary"
                disableElevation
                className={classes.buttonMenu}
                style={{ backgroundColor: '#E60480' }}
            >
                Quem Somos
            </Button>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                onClose={handleClose}
            >
                <MenuItem style={{ backgroudColor: '#E60480' }}>
                    <ButtonApresentacao />
                </MenuItem>
                <MenuItem style={{ backgroudColor: '#E60480' }}>
                    <ButtonEmNumeros />
                </MenuItem>
                <MenuItem style={{ backgroudColor: '#E60480' }}>
                    <ButtonContato />
                </MenuItem>
                <MenuItem style={{ backgroudColor: '#E60480' }}>
                    <ButtonPrivacidade />
                </MenuItem>
            </Menu>
        </>
    );
}

const ButtonApresentacao = () => {
    const classes = useStyles();

    const history = useHistory();
    const handleApresentacao = () => {
        history.push({ pathname: `/apresentacao` });
    }

    return (
        <Button
            variant="contained"
            color="primary"
            disableElevation
            className={classes.buttonMenu}
            onClick={handleApresentacao}
        >
            Apresentação
        </Button>
    )
}

const ButtonEmNumeros = () => {
    const classes = useStyles();

    const history = useHistory();
    const handleEmNumeros = () => {
        history.push({ pathname: `/em-numeros` });
    }

    return (
        <Button
            variant="contained"
            color="primary"
            disableElevation
            className={classes.buttonMenu}
            onClick={handleEmNumeros}
        >
            Mirim em Números
        </Button>
    )
}

const ButtonContato = () => {
    const classes = useStyles();

    const history = useHistory();
    const handleContato = () => {
        history.push({ pathname: `/contato` });
    }

    return (
        <Button
            variant="contained"
            color="primary"
            disableElevation
            className={classes.buttonMenu}
            onClick={handleContato}
        >
            Contato
        </Button>
    )
}

const ButtonPrivacidade = () => {
    const classes = useStyles();

    const history = useHistory();
    const handlePrivacidade = () => {
        history.push({ pathname: `/privacidade` });
    }

    return (
        <Button
            variant="contained"
            color="primary"
            disableElevation
            className={classes.buttonMenu}
            onClick={handlePrivacidade}
        >
            Aviso de Privacidade
        </Button>
    )
}

const ButtonMaterialDidatico = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Button 
                onClick={handleMenu}
                variant="contained"
                color="secondary"
                disableElevation
                className={classes.buttonMenu}
                style={{ backgroundColor: '#E60480' }}
            >
                Material Didático
            </Button>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                onClose={handleClose}
            >
                <MenuItem style={{ backgroudColor: '#E60480' }}>
                    <ButtonProvasSolucoes />
                </MenuItem>
                <MenuItem style={{ backgroudColor: '#E60480' }}>
                    <ButtonQuebraCabecaMatematica />
                </MenuItem>
            </Menu>
        </>
    );
}

const ButtonProvasSolucoes = () => {
    const classes = useStyles();

    const history = useHistory();
    const handleProvasSolucoes = () => {
        history.push({ pathname: `/provas-solucoes` });
    }

    return (
        <Button
            variant="contained"
            color="primary"
            className={classes.buttonMenu}
            onClick={handleProvasSolucoes}
        >
            Provas e Soluções
        </Button>
    )
}

const ButtonQuebraCabecaMatematica = () => {
    const classes = useStyles();

    const handleQuebraCabecaMatematica = () => {
        window.open("https://portaldaobmep.impa.br/index.php/site/index?a=4", "_blank");
    }

    return (
        <Button
            variant="contained"
            color="primary"
            className={classes.buttonMenu}
            onClick={handleQuebraCabecaMatematica}
        >
            Quebra Cabeças de Matemática
        </Button>
    )
}

const ButtonListaEscolasPublico = () => {
    const classes = useStyles();

    const history = useHistory();
    const handleListaEscolasPublico = () => {
        history.push({ pathname: `/escolas` });
    }

    return (
        <Button
            variant="contained"
            color="primary"
            className={classes.buttonMenu}
            onClick={handleListaEscolasPublico}
        >
            Lista de Escolas
        </Button>
    )
}

const ButtonCalendario = () => {
    const classes = useStyles();

    const history = useHistory();
    const handleCalendario = () => {
        history.push({ pathname: `/calendario` });
    }

    return (
        <Button
            variant="contained"
            color="secondary"
            disableElevation            
            className={classes.buttonMenu}
            style={{ backgroundColor: '#E60480' }}
            onClick={handleCalendario}
        >
            Calendário
        </Button>
    )
}

const ButtonInscricao = () => {
    const classes = useStyles();

    const history = useHistory();
    const [keycloak,] = useKeycloak();
    
    const handleFormularioInscricao = () => {
        if (keycloak.authenticated)
            keycloak.logout({ redirectUri: `${process.env.REACT_APP_PUBLIC_URL}/inscricao` });
        
        history.push({ pathname: `/inscricao` });
    }

    return (
        <Button
            variant="contained"
            color="primary"
            className={classes.buttonMenu}
            onClick={handleFormularioInscricao}
        >
            Escola, clique aqui e se inscreva
        </Button>
    )
}

export default Header;
