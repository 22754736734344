import React, { useState } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import Zoom from '@material-ui/core/Zoom';
import Badge from '@material-ui/core/Badge';
import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

import Text from '../../components/Inputs/Text/Text';
import Loading from '../../components/Loading/Loading';

import { useStyles } from './Style';

const DataTable = props => {
  const classes = useStyles();
  const { columns, tableData, actions, loading, page, rowsPerPage, fixedHeader, sort } = props;

  const [ selected, setSelected ] = useState([]);

  const handleRequestSort = (event, property) => {
    const sortable = sort ? sort.find(s => s.orderBy === property) : null
    const ordering = sortable && sortable.order === 'asc' ? 'desc' : 'asc';

    props.handleSort(property, ordering);
    props.handlePage(0);
  };

  const handleClearSort = (event, property) => {
    props.handleClearSort(property);
    props.handlePage(0);
  };

  const data = columnsFilter(columns, tableData);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map(d => d.id);
      setSelected(newSelecteds);
      props.handleChecks(newSelecteds);
      return;
    }
    setSelected([]);
    props.handleChecks([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const getSortVerify = (name) => sort ? sort.find(s => s.orderBy === name) : null;

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    props.handleChecks(newSelected);
  };

  return (
    <>
      <Table stickyHeader={fixedHeader ?? false} className={classes.spacingTable} size='small'>
        {( props.topActions || props.handleClearSort) &&
          <TableHead>
            <TableRow>
              <TableCell align="right" colSpan={columns.length + actions.length} rowSpan={2}>
                { props.topActions && props.topActions() }
                <Chip
                  label="Ordenação Padrão"
                  onDelete={() => handleClearSort(null)}
                />
              </TableCell>
            </TableRow>
          </TableHead>
        }
        <TableHead>
          <TableRow>
            {columns.map((c, i) => (
              !c.hidden ?
                c.item === 'checkbox' ?
                  props.handleChecks ?
                    <TableCell key={i} align={c.align ?? "inherit"} >
                      <Checkbox
                        defaultChecked
                        color="primary"
                        indeterminate={selected.length > 0 && selected.length < data.length}
                        checked={data.length > 0 && selected.length === data.length}
                        onChange={event => handleSelectAllClick(event)}
                        inputProps={{ 'aria-label': 'Selecionar Todos' }}
                      />
                    </TableCell>
                  : null
                : 
                <TableCell 
                  key={i}
                  className={classes.bolder} 
                  padding='default'
                  align={c.align ?? "inherit"}
                  sortDirection={getSortVerify(c.filter ?? c.name) ? getSortVerify(c.filter ?? c.name).order : false}
                >
                  {sort && c.ordering !== false ?
                    <Sort 
                      column={c}
                      sort={getSortVerify(c.filter ?? c.name)}
                      sortLevel={sort.indexOf(getSortVerify(c.filter ?? c.name))}
                      requestSort={handleRequestSort}
                      clearSort={handleClearSort}
                      sizeSort={sort.length}
                    />
                  : c.orderLabel ?
                    <TableSortLabel
                      disabled
                      active={true}
                      direction={c.orderLabel}
                    >
                      {typeof c.label === "function" ? c.label() : c.label}
                    </TableSortLabel>
                  :
                    typeof c.label === "function" ? c.label() : c.label
                  }
                </TableCell>
              : null
            ))}
            {actions.some((action) => action.collapse) && <ActionCellHead action={actions[0]} /> }
            {actions.filter(action => !action.collapse).map((action) => <ActionCellHead action={action} key={action.name} /> )}
          </TableRow>
        </TableHead>
        <TableBody>
          { loading ?
            <TableRow>
              <TableCell align="center" colSpan={columns.length + actions.length} rowSpan={2}>
                <Loading />
              </TableCell>
            </TableRow>
          : 
            data.length > 0 ?
              data.map((d, i) => {
                const isItemSelected = isSelected(d.id);
                const labelId = `enhanced-table-checkbox-${i}`;

                return (
                  <TableRow key={d.id} hover>
                    {columns.map((c, i) => (
                      !c.hidden ?
                        c.change && c.item === "textBox" ? 
                          <TableCell key={i} align={c.align ?? "inherit"} style={ c.width ? { width: `${c.width}%` } : null }>
                            <Text 
                              required
                              name={c.name}
                              label={d[c.label]}
                              value={d[c.name] ? d[c.name] : 0}
                              onChange={e => c.change(e, d.id)}
                              onBlur={c.blur ? e => c.blur(e, d.id) : null}
                            /> 
                          </TableCell>
                        : c.item === 'checkbox' ?
                          props.handleChecks ?
                            <TableCell key={i} align={c.align ?? "inherit"} style={ c.width ? { width: `${c.width}%` } : null }>
                              <Checkbox
                                defaultChecked
                                color="primary"
                                checked={isItemSelected}
                                onChange={event => handleClick(event, d.id)}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </TableCell>
                        : null 
                      :
                        <TableCell key={i} align={c.align ?? "inherit"} style={ c.width ? { width: `${c.width}%` } : null }>
                          { c.value ? c.value : c.func ? c.func(d[c.name], d.id) : d[c.name] }
                        </TableCell>
                      : null
                    ))}
                    {actions.some((action) => action.collapse) && <CollapseActions id={d.id} actions={actions.filter(action => action.collapse)} />}
                    {actions.filter(action => !action.collapse).map((action, key) => <ActionCellBody action={action} id={d.id} key={key} /> )}
                  </TableRow>
                )
              })
            :
              <TableRow style={{ height: 53 }}>
                <TableCell colSpan={columns.length + actions.length} align="center">
                  Não Foram Encontrados Registros para Exibição
                </TableCell>
              </TableRow>
          }
        </TableBody>
      </Table>
      {props.rowsPerPage !== null && props.page !== null &&
        <Pagination 
          page={page}
          changePage={props.handlePage}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={props.handleRowsPerPage}
          handleTotalElements={props.handleTotalElements}
          handleTotalPages={props.handleTotalPages}
          handleSelected={setSelected}
          mostrarTodos={props.mostrarTodos}
        />
      }
    </>
  )
}

export const DataTableLineTotals = ({ lineData, columns, showHead }) => {
  const classes = useStyles();

  const Cells = ({ data, columns }) => {
    const Cell = () => columns.map((c,i) => {
      const d = data.find((d) => d.column === i);
      return d ? !d.hidden ? <TableCell key={i} style={ d.width ? { width: `${d.width}%` } : {} }>{d.value}</TableCell> : null : <TableCell key={i}></TableCell>;
    })

    return <Cell />
  }

  const Heads = ({ data, columns }) => {
    const Head = () => columns.map((c,i) => {
      const d = data.find((d) => d.column === i);
      return d ? !d.hidden ? <TableCell key={i} className={classes.bolder} style={ d.width ? { width: `${d.width}%` } : {} }>{d.label}</TableCell> : null : <TableCell key={i}></TableCell>;
    })

    return <Head />
  }

  return (    
    <>
      <Table className={classes.spacingTable} size='small'>
        {showHead &&
          <TableHead>
            <TableRow>
              <Heads data={lineData} columns={columns}/>
            </TableRow>
          </TableHead>  
        }
        <TableBody>
          <TableRow>
            <Cells data={lineData} columns={columns}/>
          </TableRow>
        </TableBody>
      </Table>
    </>
  )
}

export const Pagination = props => {
  const [ page, setPage ] = useState(props.page);
  const [ rowsPerPage, setRowsPerPage ] = useState(props.rowsPerPage);

  const handleChangePage = (event, newPage) => {
    props.changePage(newPage);
    setPage(newPage);

    if (props.handleSelected)
      props.handleSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    props.changeRowsPerPage(rowsPerPage === -1 ? handleTotalElements() : rowsPerPage, 0);
    setRowsPerPage(rowsPerPage);
    setPage(0);

    if (props.handleSelected)
      props.handleSelected([]);
  };

  const handleTotalPages = () => props.handleTotalPages();
  const handleTotalElements = () => props.handleTotalElements();

  return (
    <TablePagination
      labelDisplayedRows={({from, to, count}) => `Página ${page + 1} de ${handleTotalPages()} / Mostrando ${from} a ${(to === -1) ? `1` : to} de ${handleTotalElements()} registros`}
      labelRowsPerPage="Linhas Por Página"
      nextIconButtonText="Próxima Página"
      backIconButtonText="Página Anterior"
      rowsPerPageOptions={[3, 5, 10, 25, 50, 100, !props.mostrarTodos ? 200 : { value: '-1', label: 'Todos' } ]}
      component="div"
      count={handleTotalElements() ?? 0}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  )
}

const ActionCellBody = ({ action, id }) => {
  if (!action.func) {
    return null
  }

  return (
    <TableCell align="right">
      <Tooltip title={action.name(id)} TransitionComponent={Zoom}> 
        <span style={action.disabled(id) ? { cursor: 'not-allowed' } : {}}>
          <IconButton 
              size="small" 
              component="div" 
              color={action.color ?? "primary"}
              aria-label={action.name(id)} 
              onClick={() => action.func(id)} 
              disabled={action.disabled(id)} 
              style={action.disabled(id) ? { pointerEvents: "none", display: "flex" } : { display: "flex" }}
            >
            {typeof action.icon === "function" ? action.icon(id) : action.icon}
          </IconButton>
        </span>
      </Tooltip>
    </TableCell>
  )
}

const ActionCellHead = ({ action }) => {
  if (!action) {
    return null
  }

  return <TableCell align="right"></TableCell>
}

const CollapseActions = ({ actions, id }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);
  
  return (
    <TableCell align="right" id={`collapse-actions`} >
      <Tooltip title="Ações" TransitionComponent={Zoom}> 
        <IconButton 
            size="small" 
            component="div" 
            color="primary"
            aria-label="Ações" 
            onClick={handleClickMenu} 
            style={{ display: "flex" }}
          >
          <SettingsOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="collapse-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {actions.map((action, key) => 
          !action.disabled(id) &&
            <MenuItem key={key}>
              <IconButton 
                  size="small" 
                  component="div" 
                  color={action.color ?? "primary"}
                  aria-label={action.name(id)} 
                  onClick={() => action.func(id)}
                  style={{ display: "flex", marginRight: "7px" }}
                >
                {typeof action.icon === "function" ? action.icon(id) : action.icon}
              </IconButton>
              {action.name(id)}
            </MenuItem>
        )}
      </Menu>
    </TableCell>
  )
}

const Sort = ({ sort, sortLevel, sizeSort, column, requestSort, clearSort }) => {
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    requestSort(event, property);
  };

  const clearSortHandler = (property) => (event) => {
    clearSort(event, property);
  };

  const columnNameSortable = column.filter ?? column.name;

  return (
    <>
      <TableSortLabel
        active={sort ? sort.orderBy === columnNameSortable : false}
        direction={sort && sort.orderBy === columnNameSortable ? sort.order : 'asc'}
        onClick={createSortHandler(columnNameSortable)}
      >
        {column.label}
        {sort && sort.orderBy === columnNameSortable && (
          <span className={classes.visuallyHidden}>
            {sort.order === 'desc' ? 'Ordenação Descendente' : 'Ordenação Ascendente'}
          </span>
        )}
      </TableSortLabel>

      {sort &&
        (sizeSort > 1 && (sortLevel + 1 > 0) ?
          <Chip
            label={sortLevel + 1}
            onDelete={clearSortHandler(columnNameSortable)}
          />
          : <Badge className={classes.badgeTable} anchorOrigin={{ vertical: 'top', horizontal: 'left' }} overlap="circle" color="default" badgeContent={sortLevel + 1} />
        )
      }
    </>
  )
}

const columnsFilter = ( columns, data ) => {
  let array = [];

  if (data != null) {
    data.forEach(d => {
      const object = {};

      columns.forEach(c => {
        if(c.key) object['id'] = d[c.name];

        object[c.name] = d[c.name];
      })

      array.push(object);
    })
  }

  return array;
}

export default DataTable;