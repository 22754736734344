import React, { Component } from 'react'

import Dialog from '../../components/Dialog/Dialog'
import Toast from '../../components/Toast/Toast'

import { Endereco as EnderecoEntrega } from '../../forms/Endereco/Endereco'
import { ButtonSave, ButtonCancel } from '../../forms/Buttons/ButtonsForm'

import EscolaService from '../../services/Escola';

export class DialogEnderecoEntrega extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            school: {                
                id: null,
                name: "",
                mecCode: "", 
            },
            open: false,
            loadingButtonSave: false,
        }
    }

    // DECLARAÇÕES DE REFERENCIA DOS COMPONENTES
    setToast = t => this.Toast = t;
    setEnderecoEntrega = e => this.EnderecoEntrega = e;

    async componentDidUpdate() {
        if (this.state.school.id && !this.state.school.mecCode) {
            await EscolaService.find(this.state.school.id)
                .then(res => {
                    this.setState({
                        school: {
                            id: res.data.cdMecEscola,
                            mecCode: res.data.cdMecEscola,
                            name: res.data.nmEscola, 
                            municipio: res.data.enderecoEntrega.municipio.cdMunicipioRegiao,  
                        },
                    });

                    this.EnderecoEntrega.findCities(res.data.enderecoEntrega.municipio.uf.cdUf, res.data.enderecoEntrega.municipio.cdMunicipioRegiao);
                    this.EnderecoEntrega.setState({
                        address: {
                            zipCode: res.data.enderecoEntrega.nmCep,
                            address: res.data.enderecoEntrega.nmEndereco,
                            number: res.data.enderecoEntrega.nmNumero,
                            complement: res.data.enderecoEntrega.nmComplemento,
                            district: res.data.enderecoEntrega.nmBairro,
                            city: res.data.enderecoEntrega.municipio.cdMunicipioRegiao,
                            UF: res.data.enderecoEntrega.municipio.uf.cdUf,
                        }
                    })
                })
                .catch(err => {
                    this.Toast.setState({
                        message: {
                            message: "Não Foi Possível Buscar a Escola para Edição de Endereço de Entrega.",
                            type: 'error',
                            open: true
                        }
                    })

                    this.setState({ loading: false })
                })
        }
    }
    
    handleSubmit = async () => {
        this.setState({ loadingButtonSave: true });
    
        const formEnderecoEntregaIsValid = await this.EnderecoEntrega.isValid();
        
        if (!formEnderecoEntregaIsValid) {
            this.setState({ loadingButtonSave: false });
            return
        }
    
        const endereco = {
            nmCep: this.EnderecoEntrega.state.address.zipCode,
            nmEndereco: this.EnderecoEntrega.state.address.address.toUpperCase().trim(),
            nmNumero: this.EnderecoEntrega.state.address.number,
            nmComplemento: this.EnderecoEntrega.state.address.complement,
            nmBairro: this.EnderecoEntrega.state.address.district.toUpperCase().trim(),
            cdMunicipioRegiao: this.EnderecoEntrega.state.address.city,
        }
    
        EscolaService.atualizarEnderecoEntrega(this.state.school.mecCode, endereco)
            .then(res => {
                this.Toast.setState({
                    message: {    
                        message: "Endereço de Entrega Atualizado com Sucesso",
                        type: "success",
                        open: true
                    }
                })
        
                setTimeout(() => this.handleClose(), 2000);
            })
            .catch(err => {
                this.Toast.setState({
                    message: {
                        message: "Ocorreu um Problema ao Atualizar o Endereço de Entrega da Escola",
                        type: "error",
                        open: true
                    }
                })
            })
            .finally(() => this.setState({ loadingButtonSave: false }));
    }

    // AÇÕES MODAL
    buttons = () =>
        <>
            <ButtonSave 
                onClick={this.handleSubmit} 
                loading={this.state.loadingButtonSave}
            />
            <ButtonCancel 
                onBack={this.handleClose} 
                nameOnBack="Fechar"
            />
        </>

    // FECHAR MODAL
    handleClose = () => {
        this.props.handleClose();
        this.setState({
            school: {                
                id: null,
                name: "",
                mecCode: "", 
            },
            open: false,
            loadingButtonSave: false,
        });
    }

    render() {
        return (
            <>  
                <Toast parentRef={this.setToast} />
                <Dialog
                    maxWidth={"lg"}
                    fullWidth={true}
                    closeDialog={this.handleClose}
                    openDialog={this.state.open}
                    title={`Endereço de Entrega ${this.state.school.id ? `- ${this.state.school.name} (${this.state.school.mecCode})` : ""}`}
                    actions={this.buttons()}
                >
                    <EnderecoEntrega 
                        parentRef={this.setEnderecoEntrega} 
                        municipioValido={this.state.school.id ? this.state.school.municipio : null} 
                    />
                </Dialog>
            </>
        )
    }
}

export default DialogEnderecoEntrega;