import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import Cache from './Cache';

import RelatorioService from '../../services/Relatorio';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import Filters from '../../components/Filters/Filters';
import Loading from '../../components/Loading/Loading';

import DadosProvasEspeciais from '../../forms/Relatorio/DadosProvasEspeciais';
import EscolaFilter from '../../forms/Escola/EscolaFilterFields';
import EnderecoFilter from '../../forms/Endereco/EnderecoFilter';
import DependenciaAdministrativaFilter from '../../forms/DependenciaAdministrativa/DependenciaAdministrativaFilter';
import { ButtonExport } from '../../forms/Buttons/ButtonsExport';

import AuthorizedElement from '../../security/AuthorizedElement';
import { __RELATORIO_PROVAS_ESPECIAIS_EXPORTAR } from '../../security/RoleConfiguration';

import { download } from '../../helper/FileHelper';

import { patternSchools } from '../../variables/Enums/Escola';

import { Layout } from '../../layouts/private/Private';

export class ProvasEspeciais extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schools: [],
      ultimaChamada: '',
      loading: true,
    }
  }

  // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
  setToast = t => this.Toast = t;
  setEscolaFilter = f => this.EscolaFilter = f;
  setEnderecoFilter = f => this.EnderecoFilter = f;
  setDependenciaAdministrativaFilter = d => this.DependenciaAdministrativaFilter = d;

  // FILTROS
  ApplyFilters = () => {
    this.setState({ loading: true });

    const f = [];
    const filtersSchool = this.EscolaFilter.state.filters;
    const filtersEndereco = this.EnderecoFilter.state.filters;
    const filtersDepAdm = this.DependenciaAdministrativaFilter.state.filters;

    if (filtersSchool.inepSchool) f.push({ field: 'cdEscola', value: filtersSchool.inepSchool })
    if (filtersEndereco.UF) f.push({ field: 'cdUf', value: filtersEndereco.UF })
    if (filtersEndereco.region) f.push({ field: 'cdRegiao', value: filtersEndereco.region })
    if (filtersDepAdm.adminDep) f.push({ field: 'nmDependenciaAdministrativa', value: filtersDepAdm.adminDep })

    f.push({ field: 'forceRefresh', value: true });

    RelatorioService.filtersReportProvasEspeciais(f)
      .then(res => {
        this.setState({ 
          schools: [...patternSchools(res.data.cache)],
          ultimaChamada: res.data.ultimaChamada,
          loading: false 
        });
      })
      .catch(error => {
        this.setState({ loading: false });
      })
  }

  // EXPORTAÇÃO
  handleExportChange = () => {
    const f = [];
    const filtersSchool = this.EscolaFilter.state.filters;
    const filtersEndereco = this.EnderecoFilter.state.filters;
    const filtersDepAdm = this.DependenciaAdministrativaFilter.state.filters;

    if (filtersSchool.inepSchool) f.push({ field: 'cdEscola', value: filtersSchool.inepSchool })
    if (filtersEndereco.UF) f.push({ field: 'cdUf', value: filtersEndereco.UF })
    if (filtersEndereco.region) f.push({ field: 'cdRegiao', value: filtersEndereco.region })
    if (filtersDepAdm.adminDep) f.push({ field: 'nmDependenciaAdministrativa', value: filtersDepAdm.adminDep })

    f.push({ field: 'exportarPlanilha', value: true });
    f.push({ field: 'forceRefresh', value: true });

    this.Toast.setState({
      message: {
        message: "A Planilha está sendo gerada para Exportação.",
        type: 'info',
        open: true,
        loading: true,
        autoHide: false,
      }
    })

    RelatorioService.exportReportProvasEspeciais(f)
      .then(res => download(res))
  }

  buscarProvasEspeciais = (forceRefresh = false) => {
    this.setState({ loading: true });

    const filters = [{ 'field': "forceRefresh", 'value': forceRefresh }];

    RelatorioService.filtersReportProvasEspeciais(filters)
      .then(res => {        
        this.setState({ 
          schools: [...patternSchools(res.data.cache)],
          ultimaChamada: res.data.ultimaChamada,
          loading: false 
        });
      })
      .catch(error => {
        const e = errors(error);
        this.setState({ loading: false });

        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      });
  }

  componentDidMount() { 
    this.buscarProvasEspeciais();
  }

  // LIMPAR FILTROS
  handleFilterClear = () => {
    this.EscolaFilter.setState({
      filters: {
        inepSchool: '',
      }
    })

    this.EnderecoFilter.setState({
      filters: {
        UF: '',
        region: '',
      }
    })

    this.DependenciaAdministrativaFilter.setState({
      filters: {
        adminDep: '',
      }
    })
  }

  render() {
    const pageName = 'Provas Especiais';
    const links = [
      {
        path: null,
        name: 'Relatórios'
      }
    ];

    return (
      <Layout>
        <Toast parentRef={this.setToast} />
        <Grid container >
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={pageName} />
          </Grid>
        </Grid>
        <Title>
          {pageName}
          { this.state.ultimaChamada && 
            <Cache ultimaAtualizacao={this.state.ultimaChamada} action={() => this.buscarProvasEspeciais(true)} /> 
          }
        </Title>

        <Filters
          handleFilter={this.ApplyFilters}
          handleFilterClear={this.handleFilterClear}
        >
          <Grid item container spacing={3}>
            <EscolaFilter
              parentRef={this.setEscolaFilter}
              disabledNameSchool={true}
              disabledEmail={true}
            /> 
            <EnderecoFilter
              parentRef={this.setEnderecoFilter}
              disabledCities={true}
              withRegion={true}
            />
            <DependenciaAdministrativaFilter
              parentRef={this.setDependenciaAdministrativaFilter}
              withFederal={true}
            />
          </Grid>
        </Filters>
        <Grid container >
          <AuthorizedElement roles={[__RELATORIO_PROVAS_ESPECIAIS_EXPORTAR]}>
            <ButtonExport
              title="Exportar Planilha"
              onClick={this.handleExportChange}
            />
          </AuthorizedElement>
        </Grid>

        {this.state.loading ?
          <Loading />
          :
          <DadosProvasEspeciais schools={this.state.schools} />
        }
      </Layout>
    )
  }
}

export default withRouter(ProvasEspeciais);