import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Cache from '../Relatorio/Cache';
import InscricoesEscolasPorData from './InscricoesEscolasPorData';
import InscricoesAlunosPorData from './InscricoesAlunosPorData';

import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import Loading from '../../components/Loading/Loading';

import RelatorioService from '../../services/Relatorio';
import { errors } from '../../services/API';

import { parseDate } from "../../helper/DateHelper";

export class InscricoesPorData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inscricoes: [],
            ultimaChamada: '',
            loading: true,
        }
    }

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;

    // FILTROS
    refresh = (forceRefresh = false) => {
        this.setState({ loading: true });

        const f = [];        
        f.push({ field: "forceRefresh", value: f.length > 0 ? true : forceRefresh })

        RelatorioService.filtersReportInscricoesPorData(f)
            .then(res => {
                this.setState({ 
                    inscricoes: res.data.cache.sort((a, b) => parseDate(a.dataFormatada).getTime() - parseDate(b.dataFormatada).getTime()),
                    ultimaChamada: res.data.ultimaChamada,
                    loading: false,
                });
            })
            .catch(error => {                
                const e = errors(error);                
                this.setState({ loading: false });
                
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            });
    }

    componentDidMount() {
        this.refresh(false);
    }

    render() {
        const pageName = 'Inscrições Por Data';

        return(
            <>
                <Toast parentRef={this.setToast} />
                <Title>
                    {pageName}
                    { this.state.ultimaChamada && 
                        <Cache ultimaAtualizacao={this.state.ultimaChamada} action={() => this.refresh(true)} /> 
                    }
                </Title>

                { this.state.loading ? 
                    <Loading />
                :
                    <Grid container spacing={3} style={{ marginTop: 8 }}>
                        <InscricoesEscolasPorData inscricoes={this.state.inscricoes} />
                        <InscricoesAlunosPorData inscricoes={this.state.inscricoes} />
                    </Grid>
                }
            </>
        )
    }
}

export default InscricoesPorData;