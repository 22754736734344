import React, { Component } from 'react';

import { getEdicao } from '../../helper/ContextHelper';

import Cache from './Cache';

import RelatorioService from '../../services/Relatorio';
import { errors } from '../../services/API';

import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import Loading from '../../components/Loading/Loading';

import DadosGestaoEdicao from '../../forms/Relatorio/DadosGestaoEdicao';

export class GestaoPorEdicao extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edicoes: [],
            ultimaChamada: '',
            loading: true,
        }
    }

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;

    // FILTROS
    ApplyFilters = async (forceRefresh = false) => {
        this.setState({ loading: true });

        const f = [];
        const edicoes = [];

        f.push({ field: "forceRefresh", value: forceRefresh })

        await RelatorioService.filtersReportGestaoPorEdicao(f)
            .then(res => {
                this.setState({ 
                    ultimaChamada: res.data.ultimaChamada,
                    loading: false,
                });

                edicoes.push(...res.data.cache);
            })
            .catch(error => {                
                const e = errors(error);                
                this.setState({ loading: false });
                
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            });
        
        for (let i = (getEdicao(this.props.contexts) - 1); i >= 2; i--)
            await RelatorioService.filtersReportGestaoPorEdicaoOtherEdition(i, f)
                    .then(res => edicoes.push(...res.data.cache))
                    .catch(() => null);

        if (edicoes.length > 0)
            this.setState({ 
                edicoes: [...edicoes.sort((a, b) => b.edicao - a.edicao)], 
            });
    }

    componentDidMount() {
        this.ApplyFilters();
    }

    render() {
        const pageName = 'Edicão';

        return(
            <>
                <Toast parentRef={this.setToast} />
                <Title>
                    {pageName}
                    { this.state.ultimaChamada && 
                        <Cache ultimaAtualizacao={this.state.ultimaChamada} action={() => this.ApplyFilters(true)} /> 
                    }
                </Title>

                { this.state.loading ? 
                    <Loading />
                :
                    <DadosGestaoEdicao edicoes={this.state.edicoes} />
                }
            </>
        )
    }
}

export default GestaoPorEdicao;