import { makeStyles } from '@material-ui/core/styles';

import { drawerWidth } from '../Drawer/Style';

const useStyles = makeStyles( 
  (theme) => (
    {
      main: {
        minHeight: '65vh',
      },
      content: {
        flexGrow: 1,
        paddingLeft: theme.spacing(3),
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
      },
      contentShift: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
      },
    }
  ) 
);

export default useStyles;