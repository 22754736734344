import React, { useState, Component } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import { isAfterInicioConsumoServicosImpressaoGrafica } from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';
import CircularProgress from "@material-ui/core/CircularProgress";
import FunctionsIcon from "@material-ui/icons/Functions";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Table from "@material-ui/core/Table";
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from "@material-ui/core/Paper";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormatListNumberedRtlOutlinedIcon from '@material-ui/icons/FormatListNumberedRtlOutlined';
import Alert from "@material-ui/lab/Alert";
import DescriptionIcon from "@material-ui/icons/Description";

import InscricaoService from "../../services/Inscricao";
import EscolaService from "../../services/Escola";
import TipoDocumentoService from "../../services/TipoDocumento";
import { errors } from "../../services/API";

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import ButtonPrimary from "../../components/Button/Button";
import { Pagination } from "../../components/Table/Table";
import Loading from "../../components/Loading/Loading";
import Divisor from "../../components/Divisor/Divisor";
import Dialog from '../../components/Dialog/Dialog'
import {
    ASC,
    PAGE,
    TOTAL_PAGES,
    verifyPagingAndSorting
} from "../../components/Table/Utils";

import AuthorizedElement from "../../security/AuthorizedElement";
import { __ESCOLA_EXPORTAR } from "../../security/RoleConfiguration";

import EscolaMateriaisFilter from "../../forms/Escola/EscolaMateriaisFilter";
import ButtonExport from "../../forms/Buttons/ButtonsExport";

import { patternSchools } from "../../variables/Enums/Escola";

import { removePagingAndSorting } from "../../helper/PaginationHelper";
import { numberFormat } from '../../helper/MaskHelper';

import { Layout } from '../../layouts/private/Private';

import { ButtonIgnorarMaterial } from "./IgnorarMaterial";
import AlertaProcessamento from "./AlertaProcessamento";

export const DialogQuantidade = ({ tipos, escola, open, handleClose }) => {
    return (
        <Dialog
            maxWidth={"md"}
            fullWidth={true}
            closeDialog={handleClose}
            openDialog={open}
            title={`Quantidades de Materiais`}
        >
            <Grid container >
                <Grid item sm={12} >
                    <Typography component="div" style={{ textAlign: "center" }} >
                        <Typography variant="body2" style={{ fontWeight: 'bold' }} display="inline" >{escola.cdMecEscola}</Typography>
                        <Typography variant="body2" color="textSecondary" display="inline" > - {escola.nmEscola}</Typography>
                        <Tooltip title="Ver Detalhes da Escola" TransitionComponent={Zoom}>
                            <IconButton
                                color="primary"
                                component="a"
                                href={`${process.env.REACT_APP_PUBLIC_URL}/admin/inscricao/${escola.cdMecEscola}/recibo`}
                                target="_blank"
                                aria-label="Ver Detalhes da Escola"
                            >
                                <DescriptionIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Typography>
                </Grid>

                <Grid item sm={12} lg={12}>
                    <Alert severity="info" style={{ marginTop: '10px' }} >
                        <Grid container >
                            <Grid item sm={12} lg={12}>
                                <Typography component="div" >
                                    <Typography variant="body2">
                                        Quantidade de Alunos Mirim 1 (2º e 3º Anos): <b>{escola.mirim1QtdAlunosPrevistos}</b>
                                        &nbsp; - &nbsp;
                                        <span style={{ color: 'darkblue' }}>No <b>Mirim 1</b>, sua escola poderá classificar <b>até {escola.mirim1QtdMaximaAlunosClassificaveis} alunos</b> para a 2ª Fase.</span>
                                    </Typography>
                                    <Typography variant="body2">
                                        Quantidade de Alunos Mirim 2 (4º e 5º Anos): <b>{escola.mirim2QtdAlunosPrevistos}</b>
                                        &nbsp; - &nbsp;
                                        <span style={{ color: 'darkblue' }}>No <b>Mirim 2</b>, sua escola poderá classificar <b>até {escola.mirim2QtdMaximaAlunosClassificaveis} alunos</b> para a 2ª Fase.</span>
                                    </Typography>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Alert>
                </Grid>

                <Grid item sm={12} >
                    <Table size='small' style={{ marginTop: 16 }} >
                        <TableBody>
                            {escola.documentos.map(doc => {
                                const t = tipos.find(t => doc.cdTipoDocumento === t.codigoDocumento);
                                return (
                                    <TableRow key={doc.cdTipoDocumento} hover>
                                        <TableCell align="left"><b>#{t.codigoDocumento}</b> - {t.nomeTipoDocumento}</TableCell>
                                        <TableCell align="right" style={{ fontWeight: 'bold' }}>{doc.qtdDocumento}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Dialog>
    )
}

const CardsQuantidades = props => {
    const { tipos, escolas, loading, page, rowsPerPage } = props;

    const [openDialogQuantidade, setOpenDialogQuantidade] = useState(false);
    const [escolaSelecionada, setEscolaSelecionada] = useState(null)

    const handleOpenDialogQuantidadeMateriais = (escola) => {
        setEscolaSelecionada(escola);
        setOpenDialogQuantidade(true);
    }

    const handleCloseDialogQuantidadeMateriais = () => {
        setEscolaSelecionada(null);
        setOpenDialogQuantidade(false);
    }

    const pagination = () => {
        return (
            <Grid container justify="center">
                <Pagination
                    page={page}
                    changePage={props.handlePage}
                    rowsPerPage={rowsPerPage}
                    changeRowsPerPage={props.handleRowsPerPage}
                    handleTotalElements={props.handleTotalElements}
                    handleTotalPages={props.handleTotalPages}
                    mostrarTodos={props.mostrarTodos}
                />
            </Grid>
        )
    }

    return (
        <Grid container spacing={3} style={{ marginTop: '24px' }}>
            { loading ?
                <Loading />
            :
                <>
                    {escolas.length > 0 ?
                        <>
                            {pagination()}

                            <Grid item container lg={12} spacing={2}>
                                {escolas.map(escola =>
                                    <Grid item key={escola.cdMecEscola} lg={3}>
                                        <Card variant="outlined" style={{ textAlign: "center" }} >
                                            <CardContent >
                                                <Grid container alignItems="center" >
                                                    <Grid item lg={11}>
                                                        <Typography variant="body2" style={{ fontWeight: 'bold' }}>{escola.cdMecEscola}</Typography>
                                                        <Typography variant="body2" color="textSecondary">{escola.nmEscola}</Typography>
                                                    </Grid>
                                                    <Grid item lg={1}>
                                                        <Tooltip title="Exibir Quantidades de Materiais" TransitionComponent={Zoom}>
                                                            <IconButton
                                                                component="div"
                                                                onClick={() => handleOpenDialogQuantidadeMateriais(escola)}
                                                                color="primary"
                                                            >
                                                                <FormatListNumberedRtlOutlinedIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )}
                            </Grid>

                            {pagination()}

                            {openDialogQuantidade && escolaSelecionada &&
                                <DialogQuantidade
                                    tipos={tipos}
                                    escola={escolaSelecionada}
                                    open={openDialogQuantidade}
                                    handleClose={handleCloseDialogQuantidadeMateriais}
                                />
                            }
                        </>
                    :
                        <Table size='small'>
                            <TableBody>
                                <TableRow style={{ height: 53 }}>
                                    <TableCell colSpan={2} align="center">
                                        Não Foram Encontrados Registros para Exibição
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    }
                </>
            }
        </Grid>
    )
}

export class CalculoMaterial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: PAGE,
            size: 50,
            totalElements: 50,
            totalPages: TOTAL_PAGES,
            sort: [{ orderBy: 'cdMecEscola', order: ASC }],
            schools: [],
            tipos: [],
            filters: [],
            filtered: false,
            loading: true,
            materialCalculado: false,
            mostrarAlerta: false,
            calculoValido: true,
            calculoMotivoNaoValidade: null,
            loadingButtonCalc: false,
            quantidadeEscolasInscritasValidas: 0,
        }
    }

    setToast = t => this.Toast = t;
    setEscolaMateriaisFilter = f => this.EscolaMateriaisFilter = f;

    handleCalcDocsSchools = () => {
        this.setState({
            schools: [],
            materialCalculado: false,
            mostrarAlerta: false,
            loadingButtonCalc: true
        });

        InscricaoService.calcularMateriaisEscolasInscritas()
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: "Cálculo de Materiais Realizado com Sucesso",
                        type: "success",
                        open: true
                    }
                });

                this.setState({ materialCalculado: true })
                this.loadData();
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
            .finally(() => this.setState({ loadingButtonCalc: false }));
    }

    handleExportPlan = () => {
        this.EscolaMateriaisFilter.setState({ filter: true, exportPlan: true, }, () => this.EscolaMateriaisFilter.handleFilterApply());

        this.Toast.setState({
            message: {
                message: "A Planilha está sendo gerada para Exportação.",
                type: 'info',
                open: true,
                loading: true,
                autoHide: false,
            }
        })
    }

    loadData = async () => {
        this.props.loadContext();

        const materialCalculado = await InscricaoService.verificarCalculoMateriais()
            .then(res => {
                this.setState({
                    materialCalculado: res.data.executada,
                    mostrarAlerta: true,
                    calculoValido: res.data.valida,
                    calculoMotivoNaoValidade: res.data.motivo,
                    loading: res.data.executada,
                });

                return res.data.executada;
            })
            .catch(() => false);

        EscolaService.filters([{ field: "nmStatus", value: 'VALIDA' }, { field: "inscrita", value: 'CONCLUIDA' }, { field: "enviarGrafica", value: true }])
            .then(res => this.setState({ quantidadeEscolasInscritasValidas: res.data.totalElements }) );

        if (materialCalculado) {
            let filters = verifyPagingAndSorting(this.state);
            this.setState({
                filters: [...filters],
                filtered: true,
                loading: true
            });

            await TipoDocumentoService.filters([{ field: "materialRegular", value: true }])
                .then(res => this.setState({ tipos: [...res.data]}));

            await EscolaService.filters([...filters, { field: "comMaterialCalculado", value: true }])
                .then(res => {
                    if (res.data.content) {
                        const schools = patternSchools(res.data.content);
                        this.setState({
                            schools: [...schools],
                            page: res.data.number,
                            size: res.data.size,
                            totalElements: res.data.totalElements,
                            totalPages: res.data.totalPages,
                            loading: false,
                        });
                    } else {
                        this.setState({
                            schools: [],
                            page: PAGE,
                            size: 50,
                            totalElements: 50,
                            totalPages: TOTAL_PAGES,
                            loading: false,
                        });
                    }
                })
                .catch(error => {
                    const e = errors(error);
                    this.Toast.setState({
                        message: {
                            message: e.message,
                            type: e.type,
                            open: true
                        }
                    })

                    this.setState({ loading: false });
                });
        }
    }

    // FILTROS
    filterData = (filters, isActive = true) => this.setState({ filtered: isActive, filters: [...removePagingAndSorting(filters)] }, () => this.loadData());

    // PÁGINA
    handlePage = page => this.setState({ page: page }, () => this.filterData(this.state.filters));

    // LINHAS POR PÁGINA
    handleRowsPerPage = (size, page) => this.setState({ size: size, page: page }, () => this.filterData(this.state.filters));

    // TOTAL DE PAGINAS
    handleTotalPages = () => this.state.totalPages;

    // TOTAL DE ELEMENTOS
    handleTotalElements = () => this.state.totalElements;

    // VERIFICAR PERSISTENCIA NO FORMULARIO DE FILTROS
    handleFilterPersistence = () => this.EscolaMateriaisFilter ? this.EscolaMateriaisFilter.state ? this.EscolaMateriaisFilter.handleFilterPersistence(this.state.filters) : null : null;

    componentDidMount() { this.loadData(); }

    render () {
        const pageName = 'Cálculo de Materiais de Escolas';
        const links = [
            {
                path: null,
                name: 'Configurações'
            },
            {
                path: null,
                name: 'Materiais'
            },
        ];

        return (
            <Layout>
                <Toast parentRef={this.setToast} />
                <Grid container >
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={pageName} />
                    </Grid>
                </Grid>
                <Title>{pageName}</Title>

                <Grid container >
                    <Grid item lg={12}>
                        <Paper style={{ marginTop: '8px', padding: '24px' }} elevation={1}>
                            <Grid container spacing={3}>
                                <Grid item sm={12} lg={12} >
                                    <Typography component={'div'} variant="body2">
                                        <b>Informações:</b>

                                        <ul style={{ marginTop: 16, paddingLeft: 36 }}>
                                            <li>A ação de <b>Calcular Materiais</b>, realiza um cálculo dos tipos de materiais informados como <b>Materiais Regulares</b> necessários à Escola.</li>
                                            <li>O cálculo usa como base as quantidades de alunos inscritos, e os Tipos de Provas informados pela Escola no cadastro de alunos classificados.</li>
                                            <li>Os quantitativos calculados serão listados abaixo para consulta, e também disponibilizados nos serviços para que a gráfica possa realizar as impressões, distribuições e envios destes materiais para as Escolas com a inscrição concluída.</li>
                                            <li>O cálculo de materiais <b>ignora</b> em sua execução as <b>Escolas Ignoradas para Ações de Impressão</b>, fazendo com que estas Escolas <b>NÃO</b> estejam disponíveis nos serviços liberados à grafica.</li>
                                            <li>Esta funcionalidade pode ser executada diversas vezes, considerando que a execução destes cálculos só refletirão mudanças nas Escolas que tiverem alguma das suas quantidades alteradas, após a execução anterior.</li>
                                            <li>Após a <b>Data para Início do Consumo de Informações de Impressão</b> por parte da gráfica, os cálculos <b>NÃO</b> poderão ser alterados, pois impacta diretamente na logística de impressão, distribuição e envio de materiais.</li>
                                        </ul>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

                { (this.state.mostrarAlerta && !isAfterInicioConsumoServicosImpressaoGrafica(this.props.contexts)) &&
                    <Grid container spacing={2} style={{ marginTop: '16px' }}>
                        <AlertaProcessamento
                            acao="Cálculo de Materiais"
                            processado={this.state.materialCalculado}
                            valido={this.state.calculoValido}
                            motivo={this.state.calculoMotivoNaoValidade}
                        />
                    </Grid>
                }

                <Grid container spacing={2} style={{ marginTop: '16px' }}>
                    <Grid item sm={12} lg={5}>
                        <ButtonPrimary
                            startIcon={this.state.loadingButtonCalc ? <CircularProgress size={16} /> : <FunctionsIcon />}
                            onClick={this.handleCalcDocsSchools}
                            name={`${this.state.materialCalculado ? `Recalcular` : `Calcular`} Quantidades de Impressões de Materiais para ${numberFormat(this.state.quantidadeEscolasInscritasValidas)} Escolas`}
                            disabled={this.state.loadingButtonCalc || isAfterInicioConsumoServicosImpressaoGrafica(this.props.contexts)}
                        />
                    </Grid>
                </Grid>

                <ButtonIgnorarMaterial linkToBack={`/admin/materiais/calculo`} />

                {this.state.materialCalculado &&
                    <>
                        <Divisor />

                        <EscolaMateriaisFilter
                            parentRef={this.setEscolaMateriaisFilter}
                            handleFilterChange={this.filterData}
                        />

                        <Grid container spacing={3}>
                            <AuthorizedElement roles={[__ESCOLA_EXPORTAR]}>
                                <ButtonExport
                                    title="Exportar Planilha"
                                    onClick={this.handleExportPlan}
                                />
                            </AuthorizedElement>
                        </Grid>

                        <CardsQuantidades
                            tipos={this.state.tipos}
                            escolas={this.state.schools}
                            page={this.state.page}
                            handlePage={this.handlePage}
                            handleTotalPages={this.handleTotalPages}
                            handleTotalElements={this.handleTotalElements}
                            rowsPerPage={this.state.size}
                            handleRowsPerPage={this.handleRowsPerPage}
                            loading={this.state.loading}
                        />
                    </>
                }
            </Layout>
        )
    }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CalculoMaterial));