import React, { Component } from 'react';

import { isAfterDataProvaFase1 } from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';

import Table, { DataTableLineTotals as Totals } from '../../components/Table/Table';
import {
    PAGE,
    TOTAL_ELEMENTS,
    TOTAL_PAGES,
} from '../../components/Table/Utils';

import { evasaoLimiterMask, numberFormat } from '../../helper/MaskHelper';

import { 
    ButtonNewTabInep, 
    ButtonNewTabAlunosInscritos, 
    ButtonNewTabAlunosInscritosAndNivel, 
    ButtonNewTabAlunosParticipantes, 
    ButtonNewTabAlunosParticipantesAndNivel 
} from '../Buttons/ButtonsNewTab';

import { patternAdminDep } from '../../variables/Enums/DependenciaAdministrativa';

export class DadosEscolas extends Component {
    constructor (props) {
        super(props);
        this.state = {
            page: PAGE,
            size: 50,
            totalElements: TOTAL_ELEMENTS,
            totalPages: TOTAL_PAGES,
        }
    }
    
    // SOMA GERAL PARA MIRIM 1
    sumAlunosPrevistosMirim1 = () => this.props.schools.reduce((total, sch) => total + parseInt(sch.mirim1QtdAlunosPrevistos, 10), 0);
    sumAlunosParticipantesMirim1 = () => this.props.schools.reduce((total, sch) => total + parseInt(sch.mirim1QtdAlunosParticipantes, 10), 0);
    calcEvasaoMirim1 = () => 100 - ((this.sumAlunosParticipantesMirim1() * 100) / this.sumAlunosPrevistosMirim1());
    
    // SOMA GERAL PARA MIRIM 2
    sumAlunosPrevistosMirim2 = () => this.props.schools.reduce((total, sch) => total + parseInt(sch.mirim2QtdAlunosPrevistos, 10), 0);
    sumAlunosParticipantesMirim2 = () => this.props.schools.reduce((total, sch) => total + parseInt(sch.mirim2QtdAlunosParticipantes, 10), 0);
    calcEvasaoMirim2 = () => 100 - ((this.sumAlunosParticipantesMirim2() * 100) / this.sumAlunosPrevistosMirim2());

    // SOMA GERAL PARA MIRIM 1 + MIRIM 2
    sumAlunosPrevistosTotal = () => this.props.schools.reduce((total, sch) => total + parseInt(sch.qtdAlunosPrevistosTotal, 10), 0);
    sumAlunosParticipantesTotal = () => this.props.schools.reduce((total, sch) => total + parseInt(sch.qtdAlunosParticipantesTotal, 10), 0);
    calcEvasaoTotal = () => 100 - ((this.sumAlunosParticipantesTotal() * 100) / this.sumAlunosPrevistosTotal());

    // SOMA TOTAL DE ESCOLAS
    sumEscolasParticipantes = () => this.props.schools.length;

    // SOMA DE ALUNOS CLASSIFICAVEIS
    sumQtdAlunosClassificaveisFase2Mirim1 = () => this.props.schools.reduce((total, sch) => total + parseInt(sch.qtdAlunosClassificaveisFase2Mirim1, 10), 0);
    sumQtdAlunosClassificaveisFase2Mirim2 = () => this.props.schools.reduce((total, sch) => total + parseInt(sch.qtdAlunosClassificaveisFase2Mirim2, 10), 0);
    sumQtdAlunosClassificaveisFase2 = () => this.props.schools.reduce((total, sch) => total + parseInt(sch.qtdAlunosClassificaveisFase2, 10), 0);
    
    // SOMA DE ALUNOS INSCRITOS
    sumQtdAlunosInscritosFase2Mirim1 = () => this.props.schools.reduce((total, sch) => total + parseInt(sch.qtdAlunosInscritosFase2Mirim1, 10), 0);
    sumQtdAlunosInscritosFase2Mirim2 = () => this.props.schools.reduce((total, sch) => total + parseInt(sch.qtdAlunosInscritosFase2Mirim2, 10), 0);
    sumQtdAlunosInscritosFase2 = () => this.props.schools.reduce((total, sch) => total + parseInt(sch.qtdAlunosInscritosFase2, 10), 0);

    // SOMA DE ALUNOS COM NOTA LANÇADA
    sumQtdAlunosParticipanteFase2Mirim1 = () => this.props.schools.reduce((total, sch) => total + parseInt(sch.qtdAlunosParticipanteFase2Mirim1, 10), 0);
    sumQtdAlunosParticipanteFase2Mirim2 = () => this.props.schools.reduce((total, sch) => total + parseInt(sch.qtdAlunosParticipanteFase2Mirim2, 10), 0);
    sumQtdAlunosParticipanteFase2 = () => this.props.schools.reduce((total, sch) => total + parseInt(sch.qtdAlunosParticipanteFase2, 10), 0);

    // PAGINAÇÃO APENAS NA VIEW
    handlePage = (value) => this.setState({ ...this.state, page: value, });
    handleRowsPerPage = (value) => this.setState({ ...this.state, size: value, page: 0, });
    handleTotalPages = () => Math.ceil(this.props.schools.length / this.state.size);
    handleTotalElements = () => this.props.schools.length;

    newTabInep = (inep) => <ButtonNewTabInep inep={inep} />
    newTabInscritos = (qtd, inep) => <ButtonNewTabAlunosInscritos qtd={numberFormat(qtd)} inep={inep} />
    newTabInscritosAndNivel = (qtd, inep, nivel) => <ButtonNewTabAlunosInscritosAndNivel qtd={numberFormat(qtd)} inep={inep} nivel={nivel} />
    newTabParticipantes = (qtd, inep) => <ButtonNewTabAlunosParticipantes qtd={numberFormat(qtd)} inep={inep} />
    newTabParticipantesAndNivel = (qtd, inep, nivel) => <ButtonNewTabAlunosParticipantesAndNivel qtd={numberFormat(qtd)} inep={inep} nivel={nivel} />

    render () {
        const columns = [
            { label: 'UF', name: 'nmUf', func: null, key: false, width: '4', ordering: false },
            { label: 'Municipio', name: 'nmMunicipio', func: null, key: false, width: '10', ordering: false },
            { label: 'Dep. Adm. ', name: 'nmDependenciaAdministrativa', func: patternAdminDep, key: false, width: '4', ordering: false },
            { label: 'INEP', name: 'cdMecEscola', func: this.newTabInep, key: true, width: '7', ordering: false, orderLabel: 'asc', },
            { label: 'Escola', name: 'nmEscola', func: null, key: false, width: '12', ordering: false },
            { label: 'Fase 1 - Mirim 1 - Alunos Inscritos', name: 'mirim1QtdAlunosPrevistos', func: numberFormat, key: false, width: '5', ordering: false },
            { label: 'Fase 1 - Mirim 1 - Alunos Participantes', name: 'mirim1QtdAlunosParticipantes', func: numberFormat, key: false, width: '7', ordering: false },
            { label: 'Fase 1 - Mirim 1 - Evasão', name: 'mirim1Evasao', func: isAfterDataProvaFase1(this.props.contexts) ? evasaoLimiterMask : () => "Prova Não Realizada", key: false, width: '5', ordering: false },
            { label: 'Fase 1 - Mirim 2 - Alunos Inscritos', name: 'mirim2QtdAlunosPrevistos', func: numberFormat, key: false, width: '5', ordering: false },
            { label: 'Fase 1 - Mirim 2 - Alunos Participantes', name: 'mirim2QtdAlunosParticipantes', func: numberFormat, key: false, width: '8', ordering: false },
            { label: 'Fase 1 - Mirim 2 - Evasão', name: 'mirim2Evasao', func: isAfterDataProvaFase1(this.props.contexts) ? evasaoLimiterMask : () => "Prova Não Realizada", key: false, width: '5', ordering: false },
            { label: 'Fase 1 - Total de Alunos Inscritos', name: 'qtdAlunosPrevistosTotal', func: numberFormat, key: false, width: '5', ordering: false },
            { label: 'Fase 1 - Total de Alunos Participantes ', name: 'qtdAlunosParticipantesTotal', func: numberFormat, key: false, width: '7', ordering: false },
            { label: 'Fase 1 - Evasão Total', name: 'evasaoTotal', func: isAfterDataProvaFase1(this.props.contexts) ? evasaoLimiterMask : () => "Prova Não Realizada", key: false, width: '5', ordering: false },
            { label: 'Fase 2 - Mirim 1 - Alunos Classificaveis', name: 'qtdAlunosClassificaveisFase2Mirim1', func: numberFormat, key: false, width: '8', ordering: false },
            { label: 'Fase 2 - Mirim 2 - Alunos Classificaveis', name: 'qtdAlunosClassificaveisFase2Mirim2', func: numberFormat, key: false, width: '8', ordering: false }, 
            { label: 'Fase 2 - Total de Alunos Classificaveis', name: 'qtdAlunosClassificaveisFase2', func: numberFormat, key: false, width: '8', ordering: false },
            { label: 'Fase 2 - Mirim 1 - Alunos Inscritos', name: 'qtdAlunosInscritosFase2Mirim1', func: (qtd, inep) => this.newTabInscritosAndNivel(qtd, inep, "MIRIM_1"), key: false, width: '8', ordering: false },
            { label: 'Fase 2 - Mirim 2 - Alunos Inscritos', name: 'qtdAlunosInscritosFase2Mirim2', func: (qtd, inep) => this.newTabInscritosAndNivel(qtd, inep, "MIRIM_2"), key: false, width: '8', ordering: false },
            { label: 'Fase 2 - Total de Alunos Inscritos', name: 'qtdAlunosInscritosFase2', func: this.newTabInscritos, key: false, width: '8', ordering: false },
            { label: 'Fase 2 - Mirim 1 - Alunos Com Nota Lançada', name: 'qtdAlunosParticipanteFase2Mirim1', func: (qtd, inep) => this.newTabParticipantesAndNivel(qtd, inep, "MIRIM_1"), key: false, width: '8', ordering: false },
            { label: 'Fase 2 - Mirim 2 - Alunos Com Nota Lançada', name: 'qtdAlunosParticipanteFase2Mirim2', func: (qtd, inep) => this.newTabParticipantesAndNivel(qtd, inep, "MIRIM_2"), key: false, width: '8', ordering: false },
            { label: 'Fase 2 - Total de Alunos Com Nota Lançada', name: 'qtdAlunosParticipanteFase2', func: this.newTabParticipantes, key: false, width: '8', ordering: false },
        ];
        
        const actions = [];

        const totals = [
            { column: 0, label: 'Totais', value: "#", width: '5', },
            { column: 1, label: 'Escolas', value: this.sumEscolasParticipantes(), width: '10', },
            { column: 2, label: 'Fase 1 - Mirim 1 - Alunos Inscritos', value: numberFormat(this.sumAlunosPrevistosMirim1()), width: '7', },
            { column: 3, label: 'Fase 1 - Mirim 1 - Alunos Participantes', value: numberFormat(this.sumAlunosParticipantesMirim1()), width: '7', },
            { column: 4, label: 'Fase 1 - Mirim 1 - Evasão', value: isAfterDataProvaFase1(this.props.contexts) ? evasaoLimiterMask(this.calcEvasaoMirim1()) : () => "Prova Não Realizada", width: '7', },
            { column: 5, label: 'Fase 1 - Mirim 2 - Alunos Inscritos', value: numberFormat(this.sumAlunosPrevistosMirim2()), width: '7', },
            { column: 6, label: 'Fase 1 - Mirim 2 - Alunos Participantes', value: numberFormat(this.sumAlunosParticipantesMirim2()), width: '7', },
            { column: 7, label: 'Fase 1 - Mirim 2 - Evasão', value: isAfterDataProvaFase1(this.props.contexts) ? evasaoLimiterMask(this.calcEvasaoMirim2()) : () => "Prova Não Realizada", width: '7', },
            { column: 8, label: 'Fase 1 - Total de Alunos Inscritos', value: numberFormat(this.sumAlunosPrevistosTotal()), width: '7', },
            { column: 9, label: 'Fase 1 - Total de Alunos Participantes', value: numberFormat(this.sumAlunosParticipantesTotal()), width: '7', },            
            { column: 10, label: 'Fase 1 - Evasão Total', value: isAfterDataProvaFase1(this.props.contexts) ? evasaoLimiterMask(this.calcEvasaoTotal()) : () => "Prova Não Realizada", width: '7', },
            { column: 11, label: 'Fase 2 - Mirim 1 - Alunos Classificaveis', value: numberFormat(this.sumQtdAlunosClassificaveisFase2Mirim1()), width: '7', },
            { column: 12, label: 'Fase 2 - Mirim 2 - Alunos Classificaveis', value: numberFormat(this.sumQtdAlunosClassificaveisFase2Mirim2()), width: '7', },
            { column: 13, label: 'Fase 2 - Total de Alunos Classificaveis', value: numberFormat(this.sumQtdAlunosClassificaveisFase2Mirim2()), width: '7', },
            { column: 14, label: 'Fase 2 - Mirim 1 - Alunos Inscritos', value: numberFormat(this.sumQtdAlunosInscritosFase2Mirim1()), width: '7', },
            { column: 15, label: 'Fase 2 - Mirim 2 - Alunos Inscritos', value: numberFormat(this.sumQtdAlunosInscritosFase2Mirim2()), width: '7', },
            { column: 16, label: 'Fase 2 - Total de Alunos Inscritos', value: numberFormat(this.sumQtdAlunosInscritosFase2()), width: '7', },
            { column: 17, label: 'Fase 2 - Mirim 1 - Alunos Com Nota Lançada', value: numberFormat(this.sumQtdAlunosParticipanteFase2Mirim1()), width: '7', },
            { column: 18, label: 'Fase 2 - Mirim 2 - Alunos Com Nota Lançada', value: numberFormat(this.sumQtdAlunosParticipanteFase2Mirim2()), width: '7', },
            { column: 19, label: 'Fase 2 - Total de Alunos Com Nota Lançada', value: numberFormat(this.sumQtdAlunosParticipanteFase2()), width: '7', },
        ];

        return (
            <Grid container spacing={3} style={{ marginTop: this.props.marginTop ?? 8 }}>
                <Grid item xs={12}>
                    <Table              
                        columns={columns}
                        tableData={this.props.schools.slice(this.state.page * this.state.size, (this.state.page * this.state.size) + this.state.size)}
                        actions={actions}
                        page={this.state.page}
                        handlePage={this.handlePage}
                        handleTotalPages={this.handleTotalPages}
                        handleTotalElements={this.handleTotalElements}
                        rowsPerPage={this.state.size}
                        handleRowsPerPage={this.handleRowsPerPage}
                        sort={null}
                        fixedHeader={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Totals lineData={totals} columns={columns} showHead={true} />
                </Grid>
            </Grid>
        )
    }
}

export default DadosEscolas;