import React from 'react';
import Chip from '@material-ui/core/Chip';

import { patternAdminDep } from './DependenciaAdministrativa';

export const patternSchools = schools => {
    let array = [];
    schools.forEach((school) => array.push(patternSchool(school)));
    return array;
}

export const patternSchool = school => {
    return {
        cdMecEscola: school.cdMecEscola,
        nmEscola: school.nmEscola,
        nmUf: school.endereco.municipio.uf.nmUf,
        nmMunicipio: school.endereco.municipio.nmMunicipio,
        cdRegiao: school.endereco.municipio.cdRegiao,
        qtdAlunosPrevistosTotal: school.inscricao ? school.inscricao.qtdAlunosPrevistosTotal : "Sem Inscrição",
        qtdAlunosParticipantesTotal: school.inscricao ? school.inscricao.qtdAlunosParticipantesTotal : "Sem Inscrição",
        evasaoTotal: school.inscricao ? school.inscricao.evasaoTotal : "0",
        idResponsavel: school.responsavel ? school.responsavel.cdResponsavel : null,
        isInscrita: school.inscricao !== null ? true : false,
        nmStatus: school.nmStatus,
        statusInscricao: school.statusInscricao,
        statusPremiacao: school.inscricao !== null ? school.inscricao.statusPremiacao : null,
        nmDependenciaAdministrativa: school.nmDependenciaAdministrativa,
        inscrita: school.inscrita ? "Sim" : "Não",
        downloadFase2: school.downloadFase2,
        qtdAlunosDeficienciaParcialMirim1: school.inscricao ? school.inscricao.qtdAlunosDeficienciaParcialMirim1 : "0",
        qtdAlunosDeficienciaTotalMirim1: school.inscricao ? school.inscricao.qtdAlunosDeficienciaTotalMirim1 : "0",
        qtdAlunosDeficienciaParcialMirim2: school.inscricao ? school.inscricao.qtdAlunosDeficienciaParcialMirim2 : "0",
        qtdAlunosDeficienciaTotalMirim2: school.inscricao ? school.inscricao.qtdAlunosDeficienciaTotalMirim2 : "0",
        modalidade: school.inscricao ? school.inscricao.modalidade : "Sem Inscrição",
        modalidadeInscricao: school.inscricao ? school.inscricao.modalidadeInscricao : "Sem Inscrição",
        isConveniadaTotal: school.possuiConvenio ? school.dependenciaConvenio === 'TOTAL' : false,
        ocorrencias: school.ocorrencias,
        possuiConvenio: school.possuiConvenio + "",
        depConvenio: school.dependenciaConvenio,
        tipoConvenio: school.dependenciaAlunoConvenio,
        nrOrdemImpressao: school.nrOrdemImpressao,
        documentos: school.documentos,
        mirim1QtdAlunosPrevistos: school.inscricao ? school.inscricao.mirim1.qtdAlunosPrevistos : "0",
        mirim2QtdAlunosPrevistos: school.inscricao ? school.inscricao.mirim2.qtdAlunosPrevistos : "0",
        mirim1QtdMaximaAlunosClassificaveis: school.inscricao ? school.inscricao.mirim1.qtdMaximaAlunosClassificaveis : "0",
        mirim2QtdMaximaAlunosClassificaveis: school.inscricao ? school.inscricao.mirim2.qtdMaximaAlunosClassificaveis : "0",
        enderecoEntrega: school.inscricao ? school.enderecoEntrega : null,
        temAlunosComNotaFase2: school.inscricao ? school.inscricao.qtdAlunosComNotaFase2Mirim1 + school.inscricao.qtdAlunosComNotaFase2Mirim2 > 0 : false,
        dataAlternativaPremiacao: school.inscricao ? school.inscricao.dataAlternativaPremiacao : null,
    };
}


export const optionsStatus = [
    { value: 'VALIDA', label: 'Válida' },
    { value: 'EM_ANALISE', label: 'Em Análise' },
    { value: 'INVALIDA', label: 'Inválida' }
];

export const optionsStatusOcorrencia = [
    { value: 'VALIDA', label: 'Válida' },
    { value: 'TRATAMENTO_INICIADO', label: 'Tratamento Iniciado' },
    { value: 'EM_ANALISE', label: 'Em Análise' },
    { value: 'INVALIDA', label: 'Inválida' },
    { value: 'INVALIDA_INFORMANDO_OUTRO_INEP', label: 'Escola Nova Inválida Informando Outro INEP' }
];

export const optionsStatusOcorrenciaConvenio = [
    { value: 'VALIDA', label: 'Válida' },
    { value: 'TRATAMENTO_INICIADO', label: 'Tratamento Iniciado' },
    { value: 'EM_ANALISE', label: 'Em Análise' },
    { value: 'INVALIDA', label: 'Inválida' }
];

export const optionsOrigem = [
    { value: false, label: 'Importada da Base MEC' },
    { value: true, label: 'Cadastrada por Responsável' }
];

export const optionsNivel = [
    { value: 'MIRIM_1', label: 'Mirim 1' },
    { value: 'MIRIM_2', label: 'Mirim 2' },
];

export const optionsTipoOcorrencia = [
    { value: 'ESCOLA_NOVA', label: 'Escola Nova' },
    { value: 'CONVENIO_TOTAL', label: 'Convênio Total' },
];

export const optionsConvenio = [
    { value: 'NENHUM', label: 'Nenhum' },
    { value: 'PARCIAL', label: 'Parcial' },
    { value: 'TOTAL', label: 'Total' },
];

export const optionsInscricao = [
    { value: 'NAO_INICIADA', label: 'Não Inscrita' },
    { value: 'CONCLUIDA', label: 'Inscrita' },
    { value: 'AGUARDANDO_PAGAMENTO', label: 'Sem Pagamento' },
    { value: 'DESCLASSIFICADA', label: 'Desclassificada' },
];

export const optionsInscricaoComFiltroPagamento = [
    { value: 'NAO_INICIADA', label: 'Não Inscrita' },
    { value: 'CONCLUIDA', label: 'Inscrita' },
    { value: 'AGUARDANDO_PAGAMENTO', label: 'Sem Pagamento' },
];

export const optionsInscricaoSemFiltroPagamento = [
    { value: 'NAO_INICIADA', label: 'Não Inscrita' },
    { value: 'CONCLUIDA', label: 'Inscrita' },
];

export const optionsModalidade = [
    { value: 'MODALIDADE_UM', label: 'Modalidade 1' },
    { value: 'MODALIDADE_DOIS', label: 'Modalidade 2' },
    { value: 'MODALIDADE_TRES', label: 'Modalidade 3' },
    { value: 'MODALIDADE_QUATRO', label: 'Modalidade 4' },
];

export const optionsStatusPremiacao = [
    { value: 'CONCLUIDA', label: 'Concluída' },
    { value: 'AGUARDANDO_DESEMPATE', label: 'Aguardando Desempate' },
    { value: 'INICIADA', label: 'Iniciada' },
    { value: 'NAO_INICIADA', label: 'Não Iniciada' },
];

export const patternLabelInscrita = label => {
    const color = label === "Sim" ? "#0da627" : "#a6120d";
    return <Chip size="small" label={label} style={{ backgroundColor: color, fontWeight: '600', color: "white"}} />
}

export const patternLabelStatusInscricao = value => {
    const color = value === "CONCLUIDA" ? "#32A852" : value === "AGUARDANDO_PAGAMENTO" ? "#1D6C99" : value === "DESCLASSIFICADA" ? "#BD492A" : "#696766";
    const label = value === "CONCLUIDA" ? "Concluída" : value === "AGUARDANDO_PAGAMENTO" ? "Sem Pagamento" : value === "DESCLASSIFICADA" ? "Desclassificada" : "Não Inscrita";
    return <Chip size="small" label={label} style={{ backgroundColor: color, fontWeight: '600', color: "white"}} />
}

export const patternLabelStatusInscricaoPaginaPublica = value => {
    const color = value === "CONCLUIDA" ? "#013214" : value === "AGUARDANDO_PAGAMENTO" ? "#8B0000" : value === "DESCLASSIFICADA" ? "#696766" : "#8B0000";
    const label = value === "CONCLUIDA" ? "Concluída" : value === "AGUARDANDO_PAGAMENTO" ? "Não Inscrita" : value === "DESCLASSIFICADA" ? "Desclassificada" : "Não Inscrita";
    return <Chip size="small" label={label} style={{ backgroundColor: color, fontWeight: '600', color: "white"}} />
}

export const patternLabelDepAdmin = label => {
    return label === "PRIVADA" ? <Chip size="small" label={patternAdminDep(label)} style={{ backgroundColor: "#1159cf", fontWeight: '600', color: "white", marginRight: '2px'}} /> : patternAdminDep(label)
}

export const patternModalidade = label => {
    return label === "MODALIDADE_UM" ? "Modalidade 1" : label === "MODALIDADE_DOIS" ? "Modalidade 2" : 
                label === "MODALIDADE_TRES" ? "Modalidade 3" : label === "MODALIDADE_QUATRO" ? "Modalidade 4" : "Sem Inscrição";
}

export const patternTipoOcorrencia = label => {
    return label === "ESCOLA_NOVA" ? "Escola Nova" : label === "CONVENIO_TOTAL" ? "Convênio Total" : "";
}