import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import { isAfterInicioConsumoServicosImpressaoGrafica } from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import SwapVertOutlinedIcon from "@material-ui/icons/SwapVertOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import FormatListNumberedRtlOutlinedIcon from "@material-ui/icons/FormatListNumberedRtlOutlined";

import EscolaMateriaisFilter from "../../forms/Escola/EscolaMateriaisFilter";
import ButtonExport from "../../forms/Buttons/ButtonsExport";

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import { ButtonPrimary } from "../../components/Button/Button";
import Table from "../../components/Table/Table";
import Divisor from "../../components/Divisor/Divisor";
import {
    ASC,
    PAGE,
    TOTAL_PAGES,
    verifyPagingAndSorting,
} from "../../components/Table/Utils";

import InscricaoService from "../../services/Inscricao";
import EscolaService from "../../services/Escola";
import TipoDocumentoService from "../../services/TipoDocumento";
import { errors } from "../../services/API";

import { patternLabelDepAdmin, patternSchools } from "../../variables/Enums/Escola";

import { removePagingAndSorting } from "../../helper/PaginationHelper";
import { numberFormat } from '../../helper/MaskHelper';

import AuthorizedElement from "../../security/AuthorizedElement";
import {__ESCOLA_EXPORTAR} from "../../security/RoleConfiguration";

import { Layout } from '../../layouts/private/Private';

import { ButtonIgnorarMaterial } from "./IgnorarMaterial";
import AlertaProcessamento from "./AlertaProcessamento";
import { DialogQuantidade } from "./CalculoMaterial";

export class OrdemImpressao extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: PAGE,
            size: 50,
            totalElements: 50,
            totalPages: TOTAL_PAGES,
            sort: [{ orderBy: 'inscricao.nrOrdemImpressao', order: ASC }],
            schools: [],
            tipos: [],
            filters: [],
            filtered: false,
            ordenacaoJaRealizada: false,
            mostrarAlerta: false,
            ordenacaoValida: true,
            ordenacaoMotivaoNaoValidade: null,
            loading: true,
            loadingButtonOrder: false,
            quantidadeEscolasInscritasValidas: 0,
            escolaSelecionada: null,
            openDialogQuantidade: false,
        }
    }

    setToast = t => this.Toast = t;
    setEscolaMateriaisFilter = f => this.EscolaMateriaisFilter = f;

    handleOpenDialogQuantidadeMateriais = (escola) => {
        this.setState({
            escolaSelecionada: escola,
            openDialogQuantidade: true,
        });
    }

    handleCloseDialogQuantidadeMateriais = () => {
        this.setState({
            escolaSelecionada: null,
            openDialogQuantidade: false,
        });
    }

    handleOrderSchools = () => {
        this.setState({
            schools: [],
            ordenacaoJaRealizada: false,
            mostrarAlerta: false,
            loadingButtonOrder: true
        });

        InscricaoService.ordenarEscolasIncritas()
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: "Ordenação Realizada com Sucesso",
                        type: "success",
                        open: true
                    }
                });

                this.setState({ ordenacaoJaRealizada: true })
                this.loadData();
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
            .finally(() => this.setState({ loadingButtonOrder: false }));
    }

    handleExportPlan = () => {
        this.EscolaMateriaisFilter.setState({ filter: true, exportPlan: true, }, () => this.EscolaMateriaisFilter.handleFilterApply());

        this.Toast.setState({
            message: {
                message: "A Planilha está sendo gerada para Exportação.",
                type: 'info',
                open: true,
                loading: true,
                autoHide: false,
            }
        })
    }

    loadData = async () => {
        this.props.loadContext();

        const ordenacaoRealizada = await InscricaoService.verificarOrdenacao()
            .then(res => {
                this.setState({
                    ordenacaoJaRealizada: res.data.executada,
                    mostrarAlerta: true,
                    ordenacaoValida: res.data.valida,
                    ordenacaoMotivoNaoValidade: res.data.motivo,
                    loading: res.data.executada,
                });

                return res.data.executada;
            })
            .catch(() => false);

        EscolaService.filters([{ field: "nmStatus", value: 'VALIDA' }, { field: "inscrita", value: 'CONCLUIDA' }, { field: "enviarGrafica", value: true }])
            .then(res => this.setState({ quantidadeEscolasInscritasValidas: res.data.totalElements }) );

        if (ordenacaoRealizada) {
            let filters = verifyPagingAndSorting(this.state);
            this.setState({
                filters: [...filters],
                filtered: true,
                loading: true
            });

            await TipoDocumentoService.filters([{ field: "materialRegular", value: true }])
                .then(res => this.setState({ tipos: [...res.data] }));

            EscolaService.filters([...filters, { field: "comOrdemImpressao", value: true }])
                .then(res => {
                    if (res.data.content) {
                        const schools = patternSchools(res.data.content);
                        this.setState({
                            schools: [...schools],
                            page: res.data.number,
                            size: res.data.size,
                            totalElements: res.data.totalElements,
                            totalPages: res.data.totalPages,
                            loading: false,
                        });
                    } else {
                        this.setState({
                            schools: [],
                            page: PAGE,
                            size: 50,
                            totalElements: 50,
                            totalPages: TOTAL_PAGES,
                            loading: false,
                        });
                    }
                })
                .catch(error => {
                    const e = errors(error);
                    this.Toast.setState({
                        message: {
                            message: e.message,
                            type: e.type,
                            open: true
                        }
                    })

                    this.setState({loading: false});
                });
        }
    }

    // FILTROS
    filterData = (filters, isActive = true) => this.setState({ filtered: isActive, filters: [...removePagingAndSorting(filters)] }, () => this.loadData());

    // PÁGINA
    handlePage = page => this.setState({ page: page }, () => this.filterData(this.state.filters));

    // LINHAS POR PÁGINA
    handleRowsPerPage = (size, page) => this.setState({ size: size, page: page }, () => this.filterData(this.state.filters));

    // TOTAL DE PAGINAS
    handleTotalPages = () => this.state.totalPages;

    // TOTAL DE ELEMENTOS
    handleTotalElements = () => this.state.totalElements;

    // VERIFICAR PERSISTENCIA NO FORMULARIO DE FILTROS
    handleFilterPersistence = () => this.EscolaMateriaisFilter ? this.EscolaMateriaisFilter.state ? this.EscolaMateriaisFilter.handleFilterPersistence(this.state.filters) : null : null;

    componentDidMount() { this.loadData(); }

    patternOrdem = (value) => <span style={{ fontWeight: "bold", fontSize: "24px", color: "darkblue" }}>{numberFormat(value)}</span>;

    render () {
        const pageName = 'Ordem de Impressão de Escolas';
        const links = [
            {
                path: null,
                name: 'Configurações'
            },
            {
                path: null,
                name: 'Materiais'
            },
        ];

        const columns = [
            { label: 'Ordem de Impressão', name: 'nrOrdemImpressao', func: this.patternOrdem, key: false, ordering: false, align: "center" },
            { label: 'INEP', name: 'cdMecEscola', func: null, key: true, ordering: false  },
            { label: 'Escola', name: 'nmEscola', func: null, key: false, ordering: false },
            { label: 'Dep. Adm.', name: 'nmDependenciaAdministrativa', func: patternLabelDepAdmin, key: false, ordering: false },
            { label: 'UF', name: 'nmUf', func: null, key: false, ordering: false },
            { label: 'Região', name: 'cdRegiao', func: null, key: false, ordering: false },
            { label: 'Município', name: 'nmMunicipio', func: null, key: false, ordering: false },
        ];

        const actions = [
            {
                name: () => "Exibir Quantidades de Materiais",
                func: (id) => this.handleOpenDialogQuantidadeMateriais(this.state.schools.find(s => s.cdMecEscola === id)),
                icon: <FormatListNumberedRtlOutlinedIcon />,
                disabled: () => false,
            },
        ]

        return (
            <Layout>
                <Toast parentRef={this.setToast} />
                <Grid container >
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={pageName} />
                    </Grid>
                </Grid>
                <Title>{pageName}</Title>

                <Grid container >
                    <Grid item lg={12}>
                        <Paper style={{ marginTop: '8px', padding: '24px' }} elevation={1}>
                            <Grid container spacing={3}>
                                <Grid item sm={12} lg={12} >
                                    <Typography component={'div'} variant="body2">
                                        <b>Informações:</b>

                                        <ul style={{ marginTop: 16, paddingLeft: 36 }}>
                                            <li>A ação de <b>Ordenar para Impressão</b>, realiza a ordenação das Escolas para impressão de materiais seguindo um critério pré-definido com os Correios, junto a equipe da OBMEP.</li>
                                            <li>A ordem definida será listada abaixo para consulta, e também disponibilizada nos serviços para que a gráfica possa realizar as impressões, distribuições e envios destes materiais para as Escolas com a <b>inscrição concluída</b>.</li>
                                            <li>A ordenação para impressão <b>ignora</b> em sua execução as <b>Escolas Ignoradas para Ações de Impressão</b>, fazendo com que estas Escolas <b>NÃO</b> estejam disponíveis nos serviços liberados à grafica.</li>
                                            <li>Esta funcionalidade pode ser executada diversas vezes, considerando que a execução desta ordenação irá redistribuir todas as Escolas nas posições de impressão que seguirem os critérios já pré-definidos.</li>
                                            <li>Após a <b>Data para Início do Consumo de Informações de Impressão</b> por parte da gráfica, a ordenação <b>NÃO</b> poderá ser alterada, pois impacta diretamente na logística de impressão, distribuição e envio de materiais.</li>
                                        </ul>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

                { (this.state.mostrarAlerta && !isAfterInicioConsumoServicosImpressaoGrafica(this.props.contexts)) &&
                    <Grid container spacing={2} style={{ marginTop: '16px' }}>
                        <AlertaProcessamento
                            acao="Ordem de Impressão"
                            processado={this.state.ordenacaoJaRealizada}
                            valido={this.state.ordenacaoValida}
                            motivo={this.state.ordenacaoMotivoNaoValidade}
                        />
                    </Grid>
                }

                <Grid container spacing={2} style={{ marginTop: '16px' }}>
                    <Grid item sm={12} lg={5}>
                        <ButtonPrimary
                            startIcon={this.state.loadingButtonOrder ? <CircularProgress size={16} /> : <SwapVertOutlinedIcon />}
                            onClick={this.handleOrderSchools}
                            name={`${this.state.ordenacaoJaRealizada ? `Reordenar` : `Ordenar`} ${numberFormat(this.state.quantidadeEscolasInscritasValidas)} Escolas para Impressão de Materiais`}
                            disabled={this.state.loadingButtonOrder || isAfterInicioConsumoServicosImpressaoGrafica(this.props.contexts)}
                        />
                    </Grid>
                </Grid>

                <ButtonIgnorarMaterial linkToBack={`/admin/materiais/ordem-impressao`} />

                {this.state.ordenacaoJaRealizada &&
                    <>
                        <Divisor />

                        <EscolaMateriaisFilter
                            parentRef={this.setEscolaMateriaisFilter}
                            handleFilterChange={this.filterData}
                        />

                        <Grid container spacing={3}>
                            <AuthorizedElement roles={[__ESCOLA_EXPORTAR]}>
                                <ButtonExport
                                    title="Exportar Planilha"
                                    onClick={this.handleExportPlan}
                                />
                            </AuthorizedElement>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Table
                                    columns={columns}
                                    tableData={this.state.schools}
                                    actions={actions}
                                    page={this.state.page}
                                    handlePage={this.handlePage}
                                    handleTotalPages={this.handleTotalPages}
                                    handleTotalElements={this.handleTotalElements}
                                    rowsPerPage={this.state.size}
                                    handleRowsPerPage={this.handleRowsPerPage}
                                    sort={this.state.sort}
                                    loading={this.state.loading}
                                />
                            </Grid>
                        </Grid>

                        {this.state.openDialogQuantidade && this.state.escolaSelecionada &&
                            <DialogQuantidade
                                tipos={this.state.tipos}
                                escola={this.state.escolaSelecionada}
                                open={this.state.openDialogQuantidade}
                                handleClose={this.handleCloseDialogQuantidadeMateriais}
                            />
                        }
                    </>
                }
            </Layout>
        )
    }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrdemImpressao));