import React from 'react';
import clsx from 'clsx';

import apoio from '../../assets/img/regua-apoio.png';
import realizacao from '../../assets/img/regua-realizacao.png';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { drawerWidth } from '../Drawer/Style';

const useStyles = makeStyles(
    (theme) => (
        {
            regua: {
                textAlign: 'center',
                position: 'relative',
                marginTop: theme.spacing(8),
                ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
                    marginTop: theme.spacing(8),
                },
            },
            logo: {
                width: '50%',
                display: 'inline-block',
                verticalAlign: 'top',
                ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
                    width: '100%',
                    marginBottom: theme.spacing(2),
                },
            },
            realizacao: {
                width: '74%',
                ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
                    width: '80%',
                },
            },
            apoio: {
                width: '48%',
                paddingTop: '2%',
                paddingLeft: '5%',
                ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
                    width: '48%',
                    paddingLeft: '4%',
                },
            },
            content: {
                flexGrow: 1,
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                marginLeft: 0,
            },
            contentShift: {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginLeft: drawerWidth,
            },
        }
    )
);

export const Regua = (props) => {
    const classes = useStyles(props);
    return (
        <Box className={clsx(classes.regua, classes.content, { [classes.contentShift]: props.open ?? false })} >
            <div className={classes.logo} >
                <picture>
                    <img src={apoio} className={classes.apoio} alt="Apoio" />
                </picture>
            </div>
            <div className={classes.logo} >
                <picture>
                    <img src={realizacao} className={classes.realizacao} alt="Realização" />
                </picture>
            </div>
        </Box>
    )
}

export default Regua;