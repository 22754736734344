import React, { useEffect, useState } from 'react';
import clsx from "clsx";

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Loading from '../../components/Loading/Loading';

import { percentMask, numberFormat } from '../../helper/MaskHelper';

import useStyles from './Style';

export const Quadros = ({ report, mostrarPrivadas = true }) => {
  const classes = useStyles();

  const [ data, setData ] = useState({});
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    report()
      .then(res => {
        setData(res.data.cache);
        setLoading(false);
      });
  }, [report]);

  return (
    <>
      {loading ? 
        <Loading /> 
      :
        <>
          <Grid 
            container 
            align='center' 
            direction="row" 
            justify="center"
            alignItems="center"
            className={classes.wrapper}
          >
            <Grid item lg={12} >
              <TableContainer component="div">
                <Table className={classes.table} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={4} className={clsx(classes.bolder, classes.box)} align="center">Inscrições na 1ª Fase</TableCell>
                    </TableRow>
                    <TableRow>                              
                      <TableCell className={clsx(classes.box)}></TableCell>
                      <TableCell className={clsx(classes.bolder, classes.box)} align="center">Públicas</TableCell>
                      {mostrarPrivadas &&
                        <>
                          <TableCell className={clsx(classes.bolder, classes.box)} align="center">Privadas</TableCell>
                          <TableCell className={clsx(classes.bolder, classes.box)} align="center">Todos</TableCell>
                        </>
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow hover>
                      <TableCell className={clsx(classes.bolder, classes.box)} align="center">Escolas</TableCell>
                      <TableCell align="center">{numberFormat(data.escolasFase1Publicas)}</TableCell>
                      {mostrarPrivadas &&
                        <>
                          <TableCell align="center">{numberFormat(data.escolasFase1Privadas)}</TableCell>
                          <TableCell align="center">{numberFormat(data.escolasFase1Publicas + data.escolasFase1Privadas)}</TableCell>
                        </>
                      }
                    </TableRow>
                    <TableRow hover>
                      <TableCell className={clsx(classes.bolder, classes.box)} align="center">Alunos</TableCell>
                      <TableCell align="center">{numberFormat(data.alunosFase1Publicas)}</TableCell>
                      {mostrarPrivadas &&
                        <>
                          <TableCell align="center">{numberFormat(data.alunosFase1Privadas)}</TableCell>
                          <TableCell align="center">{numberFormat(data.alunosFase1Publicas + data.alunosFase1Privadas)}</TableCell>
                        </>
                      }
                    </TableRow>
                    <TableRow hover>
                      <TableCell className={clsx(classes.bolder, classes.box)} align="center">Municípios</TableCell>
                      <TableCell align="center">{percentMask(data.municipiosFase1Publicas)}</TableCell>
                      {mostrarPrivadas &&
                        <>
                          <TableCell align="center">{percentMask(data.municipiosFase1Privadas)}</TableCell>
                          <TableCell align="center">{percentMask(data.municipiosFase1)}</TableCell>
                        </>
                      }
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <Grid 
            container 
            align='center' 
            direction="row" 
            justify="center"
            alignItems="center"
            className={classes.wrapper}
          >
            <Grid item lg={12} >
              <TableContainer component="div">
                <Table className={classes.table} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={4} className={clsx(classes.bolder, classes.box)} align="center">Inscrições na 2ª Fase</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={clsx(classes.bolder, classes.box)} align="center"></TableCell>
                      <TableCell className={clsx(classes.bolder, classes.box)} align="center">Públicas</TableCell>
                      {mostrarPrivadas &&
                        <>
                          <TableCell className={clsx(classes.bolder, classes.box)} align="center">Privadas</TableCell>
                          <TableCell className={clsx(classes.bolder, classes.box)} align="center">Todos</TableCell>
                        </>
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow hover>
                      <TableCell className={clsx(classes.bolder, classes.box)} align="center">Escolas</TableCell>
                      <TableCell align="center">{numberFormat(data.escolasFase2Publicas)}</TableCell>
                      {mostrarPrivadas &&
                        <>
                          <TableCell align="center">{numberFormat(data.escolasFase2Privadas)}</TableCell>
                          <TableCell align="center">{numberFormat(data.escolasFase2Publicas + data.escolasFase2Privadas)}</TableCell>
                        </>
                      }
                    </TableRow>
                    <TableRow hover>
                      <TableCell className={clsx(classes.bolder, classes.box)} align="center">Alunos</TableCell>
                      <TableCell align="center">
                        <TableGenero 
                          masculino={numberFormat(data.alunosFase2MasculinoPublicas)} 
                          feminino={numberFormat(data.alunosFase2FemininoPublicas)}
                          total={numberFormat(data.alunosFase2MasculinoPublicas + data.alunosFase2FemininoPublicas)} 
                        />
                      </TableCell>
                      {mostrarPrivadas &&
                        <>
                          <TableCell align="center">
                            <TableGenero
                              masculino={numberFormat(data.alunosFase2MasculinoPrivadas)}
                              feminino={numberFormat(data.alunosFase2FemininoPrivadas)}
                              total={numberFormat(data.alunosFase2MasculinoPrivadas + data.alunosFase2FemininoPrivadas)}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <TableGenero
                              masculino={numberFormat(data.alunosFase2MasculinoPublicas + data.alunosFase2MasculinoPrivadas)}
                              feminino={numberFormat(data.alunosFase2FemininoPublicas + data.alunosFase2FemininoPrivadas)}
                              total={numberFormat(data.alunosFase2MasculinoPublicas + data.alunosFase2MasculinoPrivadas + data.alunosFase2FemininoPublicas + data.alunosFase2FemininoPrivadas)}
                            />
                          </TableCell>
                        </>
                      }
                    </TableRow>
                    <TableRow hover>
                      <TableCell className={clsx(classes.bolder, classes.box)} align="center">Municípios</TableCell>                  
                      <TableCell align="center">{percentMask(data.municipiosFase2Publicas)}</TableCell>
                      {mostrarPrivadas &&
                        <>
                          <TableCell align="center">{percentMask(data.municipiosFase2Privadas)}</TableCell>
                          <TableCell align="center">{percentMask(data.municipiosFase2)}</TableCell>
                        </>
                      }
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </>
      }
    </>
  )
}

export const TableGenero = ({ masculino, feminino, total }) => {
  const classes = useStyles();

  return (
    <Table className={classes.table} size="small">
      <TableHead>
        <TableRow>
          <TableCell className={clsx(classes.bolder, classes.box)} align="center">Masculino</TableCell>
          <TableCell className={clsx(classes.bolder, classes.box)} align="center">Feminino</TableCell>
          <TableCell className={clsx(classes.bolder, classes.box)} align="center">Todos</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align="center">{masculino}</TableCell>
          <TableCell align="center">{feminino}</TableCell>
          <TableCell align="center">{total}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default Quadros;
