import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import SendIcon from '@material-ui/icons/Send';
import Chip from '@material-ui/core/Chip';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import Text from '../../components/Inputs/Text/Text';
import Loading from '../../components/Loading/Loading';
import { ButtonSecondary } from '../../components/Button/Button';

import { errors } from '../../services/API';

import { ButtonSave } from '../../forms/Buttons/ButtonsForm';
import DialogPreviewEmail from '../../forms/Dialog/DialogPreviewEmail';

import { patternSendSchoolWithResponsible } from '../../variables/Enums/Email';

import ObjetoPostalService from '../../services/ObjetoPostal';
import EmailService from '../../services/Email';

import AuthorizedFunction from '../../security/AuthorizedFunction';
import { __EMAIL_ENVIAR } from '../../security/RoleConfiguration';

import {
    isBlank,
    isBlankHelperText
} from '../../helper/ValidationHelper';

import { Layout } from '../../layouts/private/Private';

const INEP = "{inep}";
const NOME_ESCOLA = "{nomeEscola}";
const CODIGO_SEDEX = "{codigoSedex}";
const ULTIMO_STATUS = "{ultimoStatusSedex}";
const ENDERECO_UNIDADE_RETIRADA = "{enderecoUnidadeRetirada}";
const LOGRADOURO_DESTINO = "{logradouroDestino}";
const BAIRRO_DESTINO = "{bairroDestino}";
const NUMERO_DESTINO = "{numeroDestino}";
const CEP_DESTINO = "{cepDestino}";
const CIDADE_DESTINO = "{cidadeDestino}";
const UF_DESTINO = "{ufDestino}";

export class ObjetoPostalEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mail: {
                to: [],
                message: '',
                subject: '',
            },
            errors: {
                message: false,
                subject: false,
            },
            helpers: {
                message: null,
                subject: null,
            },
            showAll: false,
            maxShow: 15,
            loading: true,
            loadingButtonSender: false,
        }
    }

    // DECLARAÇÕES DE REFERENCIA DOS COMPONENTES
    setToast = t => this.Toast = t;
    setDialogPreviewEmail = p => this.DialogPreviewEmail = p;

    // INPUTS
    handleChange = e => {
        this.setState({
            mail: { ...this.state.mail, [e.target.name]: e.target.value },
            errors: { ...this.state.errors, [e.target.name]: false },
            helpers: { ...this.state.helpers, [e.target.name]: null }
        });
    };

    handleChangeEditor = editor => {
        this.setState({
            ...this.state,
            mail: {
                ...this.state.mail,
                message: editor.getData()
            }
        })
    }

    // REMOVER EMAIL DA LISTAGEM PARA ENVIO
    handleDelete = mail => {
        this.setState({
            mail: {
                ...this.state.mail,
                to: this.state.mail.to.filter((m) => m.email !== mail.email),
            }
        });
    };

    // MOSTRAR PREVIEW DE EMAIL MONTADO
    handlePreview = to => {
        if (!this.isValidMessage()) 
            return

        this.DialogPreviewEmail.setState({
            dialog: {
                open: true,
                title: `Pré-Visualização de Envio`,
                to: `${to.email} - ${to.nome} (${to.codigoMec})`,
                subject: this.formatTagsText(to, this.state.mail.subject),
                message: this.formatTagsText(to, this.state.mail.message),
            }
        });
    };

    // MOSTRAR / OCULTAR EXCESSO DE EMAILS AMOSTRA
    handleShowAll = () => {
        this.setState({
            ...this.state,
            showAll: !this.state.showAll,
        })
    }

    // VALIDAÇÕES DE SALVAR MENSAGEM
    isValidMessage = () => {
        if (!this.state.mail.subject || !this.state.mail.message) {
            this.setState({
                errors: {
                    subject: isBlank(this.state.mail.subject) ? true : false,
                    message: isBlank(this.state.mail.message) ? true : false,
                },
                helpers: {
                    subject: isBlank(this.state.mail.subject) ? isBlankHelperText() : null,
                    message: isBlank(this.state.mail.message) ? isBlankHelperText() : null,
                }
            });
            return false;
        }
        return true;
    }

    formatTagsText = (to, text) => {
        let msg = text;

        msg = msg.replaceAll(INEP, to.codigoMec)
        msg = msg.replaceAll(NOME_ESCOLA, to.nome)
        msg = msg.replaceAll(CODIGO_SEDEX, to.numeroSedex)
        msg = msg.replaceAll(ULTIMO_STATUS, to.ultimoStatus)
        msg = msg.replaceAll(ENDERECO_UNIDADE_RETIRADA, to.enderecoUnidadeRetirada)
        msg = msg.replaceAll(LOGRADOURO_DESTINO, to.logradouroDestino)
        msg = msg.replaceAll(BAIRRO_DESTINO, to.bairroDestino)
        msg = msg.replaceAll(NUMERO_DESTINO, to.numeroDestino)
        msg = msg.replaceAll(CEP_DESTINO, to.cepDestino)
        msg = msg.replaceAll(CIDADE_DESTINO, to.cidadeDestino)
        msg = msg.replaceAll(UF_DESTINO, to.ufDestino)

        return msg;
    }

    partitionDivisor = array => {
        const result = [];

        for (let i = 0; i < array.length; i += 50)
            result.push(array.slice(i, i + 50));        

        return result;
    }

    enviarEmails = (mailsPartitions) => {
        for (const mails of mailsPartitions) {
            try {
                EmailService.sendBatch(mails);
            } catch (e) {
                this.Toast.setState({
                    message: {
                        message: "Ocorreu um Problema ao Configurar o Envio de Emails. Tente Novamente Mais Tarde",
                        type: "error",
                        open: true
                    }
                });

                this.setState({ loadingButtonSender: false });
                return;
            }
        }

        this.Toast.setState({
            message: {
                message: "O Envio de Emails foi Configurado e em Instantes será Realizado o Disparo",
                type: "success",
                open: true
            }
        });

        this.setState({ loadingButtonSender: false });
    }

    // ENVIAR EMAIL PARA TODAS AS ESCOLAS
    handleSend = () => {
        if (!this.isValidMessage()) 
            return
        
        this.setState({ loadingButtonSender: true })
        
        const mails = [
            ...this.state.mail.to.map(to => {
                return {
                    para: to.email,
                    tipoEmail: "COMUNICACAO_STATUS_OBJETO_POSTAL",
                    assunto: this.formatTagsText(to, this.state.mail.subject),
                    mensagem: this.formatTagsText(to, this.state.mail.message),                    
                    escola: to.codigoMec,
                }
            })
        ]

        this.enviarEmails(this.partitionDivisor(mails));
    }

    componentDidMount() {
        const query = queryString.parse(this.props.location.search)
        let filters = [];

        if (query.status) {
            if (typeof query.status === 'object')
                query.status.forEach(s => filters.push({ field: 'status', value: s }));
            else
                filters.push({ field: 'status', value: query.status });

            if (query.conteudo)
                filters.push({ field: 'conteudo', value: query.conteudo });

            filters.push({ field: 'pageable', value: false });
        }

        ObjetoPostalService.filters(filters)
            .then(res =>
                this.setState({
                    mail: {
                        to: [...patternSendSchoolWithResponsible(res.data.content)],
                    },
                    loading: false,
                })
            )
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
                this.setState({ loading: false, });
            })
    }

    handleBack = () => this.props.history.goBack();

    render() {
        const pageName = `Email Para Escolas Por Status Sedex`;
        const links = [
            {
                path: null,
                name: 'Correios'
            },
            {
                path: null,
                name: 'Objetos Postais'
            },
            {
                path: '/admin/objetosPostais/status',
                name: 'Objetos Postais Por Status',
            }
        ];

        return (
            <Layout>
                <Toast parentRef={this.setToast} />
                <Grid container >
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={pageName} />
                    </Grid>
                </Grid>
                <Title>{pageName}</Title>

                {this.state.loading ?
                    <Loading />
                :
                    <>
                        <Grid container alignItems='center'>
                            <Grid item sm={12} lg={12}>
                                <Paper elevation={1} style={{ padding: 24 }} >
                                    <Typography variant="h6">Para:</Typography>

                                    {this.state.mail.to.map((t, i) =>
                                        (this.state.showAll || (!this.state.showAll && i < this.state.maxShow)) &&
                                        <div key={i} style={{ margin: "4px", display: "inline-block" }}>
                                            <Chip
                                                label={`${t.email} - ${t.nome} (${t.codigoMec})`}
                                                onClick={AuthorizedFunction([__EMAIL_ENVIAR]) ? () => this.handlePreview(t) : null}
                                                onDelete={AuthorizedFunction([__EMAIL_ENVIAR]) ? () => this.handleDelete(t) : null}
                                            />
                                        </div>
                                    )}

                                    { this.state.mail.to.length > this.state.maxShow &&
                                        <div style={{ margin: "4px", display: "inline-block" }}>
                                            <Chip
                                                color={this.state.showAll ? `secondary` : `primary`}
                                                icon={<MoreHorizIcon />}
                                                onClick={() => this.handleShowAll()}
                                                label={this.state.showAll ? `Ocultar ${this.state.mail.to.length - this.state.maxShow}` : `+ ${this.state.mail.to.length - this.state.maxShow}`}
                                            />
                                        </div>
                                    }
                                </Paper>
                            </Grid>

                            <Grid item sm={12} lg={12}>
                                <Alert severity="warning">
                                    <AlertTitle>Atenção</AlertTitle>
                                    <Typography variant="body2" style={{ paddingTop: 12 }}>
                                        Utilize as <b>tag`s opcionais</b> no Assunto e no Corpo do Email para adicionar dados personaizados para cada email: 
                                        <ul style={{ paddingTop: '7px', paddingLeft: '25px' }} >
                                            <li><span style={{ color: "darkblue" }}> <b>{INEP}</b></span> para INEP</li>
                                            <li><span style={{ color: "darkblue" }}> <b>{NOME_ESCOLA}</b></span> para o Nome da Escola</li>
                                            <li><span style={{ color: "darkblue" }}> <b>{CODIGO_SEDEX}</b></span> para o Código Sedex</li>
                                            <li><span style={{ color: "darkblue" }}> <b>{ULTIMO_STATUS}</b></span> para o Último Status Sedex do Objeto Postal da Escola</li>
                                            <li><span style={{ color: "darkblue" }}> <b>{ENDERECO_UNIDADE_RETIRADA}</b></span> para o Endereço da Unidade dos Correios para Retirada do Objeto Postal</li>
                                            <li><span style={{ color: "darkblue" }}> <b>{LOGRADOURO_DESTINO}</b></span> para o Logradouro do Endereço de Entrega da Escola</li>
                                            <li><span style={{ color: "darkblue" }}> <b>{BAIRRO_DESTINO}</b></span> para o Bairro do Endereço de Entrega da Escola</li>
                                            <li><span style={{ color: "darkblue" }}> <b>{NUMERO_DESTINO}</b></span> para o Número do Endereço de Entrega da Escola</li>
                                            <li><span style={{ color: "darkblue" }}> <b>{CEP_DESTINO}</b></span> para o CEP do Endereço de Entrega da Escola</li>
                                            <li><span style={{ color: "darkblue" }}> <b>{CIDADE_DESTINO}</b></span> para a Cidade do Endereço de Entrega da Escola</li>
                                            <li><span style={{ color: "darkblue" }}> <b>{UF_DESTINO}</b></span> para a UF do Endereço de Entrega da Escola</li>
                                        </ul>
                                    </Typography>
                                </Alert>
                            </Grid>
                            
                            <Grid item sm={12} lg={12}>
                                <Text
                                    required
                                    name="subject"
                                    label="Assunto"
                                    value={this.state.mail.subject}
                                    error={this.state.errors.subject}
                                    onChange={this.handleChange}
                                    helperText={this.state.helpers.subject}
                                />

                                <Typography variant="body2" display="inline" style={{ paddingTop: 8, fontSize: 12, color: 'rgba(0, 0, 0, 0.54)' }}>
                                    Corpo do Email <sup>*</sup> &nbsp;
                                </Typography>

                                { this.state.errors.message && <Typography variant="body2" display="inline" style={{ fontSize: 12, color: '#f34539' }}>{this.state.helpers.message}</Typography> }

                                <CKEditor
                                    editor={ClassicEditor}
                                    data={this.state.mail.message}
                                    config={{
                                        toolbar: [],
                                        language: 'pt-br',
                                    }}
                                    onChange={(event, editor) => this.handleChangeEditor(editor)}
                                />
                            </Grid>

                            <Grid item sm={12} lg={12} style={{ marginTop: "24px" }}>
                                <Typography variant="body2">
                                    Para <b>PRÉ-VISUALIZAR</b> um dos email que será disparado com as <b>tags</b> já trocadas pelos dados personalizados, basta após escrever o <i>Assunto</i> e a <i>Mensagem</i>, clicar no email desejado na Lista de Emails do campo <i>Para</i>.
                                </Typography>
                                <br />

                                <Typography variant="body2">
                                    Ao clicar no botão abaixo, os emails com os dados personalizados serão montados e será disparado à <b>TODAS AS ESCOLAS E SEUS RESPONSÁVEIS</b> listadas no campo <b>para</b>.
                                </Typography>

                                <Grid item sm={12} lg={4} style={{ marginTop: "16px" }}>
                                    <ButtonSave
                                        startIcon={<SendIcon />}
                                        onClick={this.handleSend}
                                        name="Enviar Email para Escolas"
                                        saveDisabled={!AuthorizedFunction([__EMAIL_ENVIAR]) || this.state.mail.to.length === 0}
                                        loading={this.state.loadingButtonSender}
                                    />
                                    <ButtonSecondary
                                        startIcon={<ArrowBackIcon />}
                                        style={{ margin: 4 }}
                                        onClick={() => this.handleBack()}
                                        name={"Voltar"}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <DialogPreviewEmail parentRef={this.setDialogPreviewEmail} />
                    </>
                }
            </Layout>
        )
    }
}

export default withRouter(ObjetoPostalEmail);
