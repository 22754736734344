import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Table, { DataTableLineTotals as Totals } from '../../components/Table/Table';
import {
    PAGE,
    TOTAL_ELEMENTS,
    TOTAL_PAGES,
} from '../../components/Table/Utils';

import { ButtonNewTabInep, ButtonNewTabNivelAndTipoProva } from '../Buttons/ButtonsNewTab';

import { numberFormat } from '../../helper/MaskHelper';

import { patternAdminDep } from '../../variables/Enums/DependenciaAdministrativa';

export class DadosProvasEspeciais extends Component {
    constructor (props) {
        super(props)
        this.state = {
            page: PAGE,
            size: 25,
            totalElements: TOTAL_ELEMENTS,
            totalPages: TOTAL_PAGES,
        }
    }

    // SOMA TOTAL DE ESCOLAS
    sumEscolasParticipantes = () => this.props.schools.length;

    sumAlunosDeficienciaParcialMirim1 = () => this.props.schools.reduce((total, sch) => total + sch.qtdAlunosDeficienciaParcialMirim1, 0);
    sumAlunosDeficienciaTotalMirim1 = () => this.props.schools.reduce((total, sch) => total + sch.qtdAlunosDeficienciaTotalMirim1, 0);
    sumAlunosDeficienciaParcialMirim2 = () => this.props.schools.reduce((total, sch) => total + sch.qtdAlunosDeficienciaParcialMirim2, 0);
    sumAlunosDeficienciaTotalMirim2 = () => this.props.schools.reduce((total, sch) => total + sch.qtdAlunosDeficienciaTotalMirim2, 0);
    
    // PAGINAÇÃO APENAS NA VIEW
    handlePage = (value) => this.setState({ ...this.state, page: value, });
    handleRowsPerPage = (value) => this.setState({ ...this.state, size: value, page: 0, });
    handleTotalPages = () => Math.ceil(this.props.schools.length / this.state.size);
    handleTotalElements = () => this.props.schools.length;

    newTabInep = (inep) => <ButtonNewTabInep inep={inep} />
    newTabNivelAndTipoProva = (qtd, inep, nivel, tipo) => <ButtonNewTabNivelAndTipoProva qtd={numberFormat(qtd)} inep={inep} nivel={nivel} tipo={tipo} />

    render () {
        const columns = [
            { label: 'UF', name: 'nmUf', func: null, key: false, width: '4', },
            { label: 'Região', name: 'cdRegiao', func: null, key: false, width: '4', },
            { label: 'Municipio', name: 'nmMunicipio', func: null, key: false, width: '10', },
            { label: 'Dep. Adm. ', name: 'nmDependenciaAdministrativa', func: patternAdminDep, key: false, width: '5', },
            { label: 'INEP', name: 'cdMecEscola', func: this.newTabInep, key: true, width: '7', orderLabel: 'asc', },
            { label: 'Escola', name: 'nmEscola', func: null, key: false, width: '12', },
            { label: 'Mirim 1 - Provas Ampliadas', name: 'qtdAlunosDeficienciaParcialMirim1', func: (qtd, inep) => this.newTabNivelAndTipoProva(qtd, inep, "MIRIM_1", "AMPLIADA"), key: false, width: '5', },
            { label: 'Mirim 1 - Provas Braile', name: 'qtdAlunosDeficienciaTotalMirim1', func: (qtd, inep) => this.newTabNivelAndTipoProva(qtd, inep, "MIRIM_1", "BRAILE"), key: false, width: '7', },
            { label: 'Mirim 2 - Provas Ampliadas', name: 'qtdAlunosDeficienciaParcialMirim2', func: (qtd, inep) => this.newTabNivelAndTipoProva(qtd, inep, "MIRIM_2", "AMPLIADA"), key: false, width: '5', },
            { label: 'Mirim 2 - Provas Braile', name: 'qtdAlunosDeficienciaTotalMirim2', func: (qtd, inep) => this.newTabNivelAndTipoProva(qtd, inep, "MIRIM_2", "BRAILE"), key: false, width: '8', },
        ];
        
        const actions = [];

        const totals = [
            { column: 0, label: 'Totais', value: "#", width: '40', },
            { column: 1, label: 'Escolas', value: numberFormat(this.sumEscolasParticipantes()), width: '12', },
            { column: 2, label: 'Mirim 1 - Provas Ampliadas', value: numberFormat(this.sumAlunosDeficienciaParcialMirim1()), width: '12', },
            { column: 3, label: 'Mirim 1 - Provas Braile', value: numberFormat(this.sumAlunosDeficienciaTotalMirim1()), width: '12', },
            { column: 5, label: 'Mirim 2 - Provas Ampliadas', value: numberFormat(this.sumAlunosDeficienciaParcialMirim2()), width: '12', },
            { column: 6, label: 'Mirim 2 - Provas Braile', value: numberFormat(this.sumAlunosDeficienciaTotalMirim2()), width: '12', },
        ];

        return (
            <Grid container spacing={3} style={{ marginTop: this.props.marginTop ?? 8 }}>
                <Grid item xs={12}>
                    <Table              
                        columns={columns}                        
                        tableData={this.props.schools.slice(this.state.page * this.state.size, (this.state.page * this.state.size) + this.state.size)}
                        actions={actions}
                        page={this.state.page}
                        handlePage={this.handlePage}
                        handleTotalPages={this.handleTotalPages}
                        handleTotalElements={this.handleTotalElements}
                        rowsPerPage={this.state.size}
                        handleRowsPerPage={this.handleRowsPerPage}
                        sort={null}
                        fixedHeader={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Totals lineData={totals} columns={columns} showHead={true} />
                </Grid>
            </Grid>
        )
    }
}

export default DadosProvasEspeciais;