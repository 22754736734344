import React, { Component } from 'react';

import { getEdicao } from '../../helper/ContextHelper';

import Cache from './Cache';

import RelatorioService from '../../services/Relatorio';
import { errors } from '../../services/API';

import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import Loading from '../../components/Loading/Loading';

import DadosInscricoesEdicao from '../../forms/Relatorio/DadosInscricoesEdicao';

export class InscricoesPorEdicao extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            edicoes: [],
            ultimaChamada: '',
            loading: true,
            filters: {
                adminDep: '',
            }
        }
    }

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;

    // FILTROS
    ApplyFilters = async (forceRefresh = false, ignoreFilter = false) => {
        this.setState({ loading: true });

        const f = [];
        const edicoes = [];
        
        if (!ignoreFilter) {
            const filters = this.state.filters;

            if (filters.adminDep) f.push({ field: 'depAdmin', value: filters.adminDep })
        }

        f.push({ field: "forceRefresh", value: f.length > 0 ? true : forceRefresh })

        await RelatorioService.filtersReportInscricoesPorEdicao(f)
            .then(res => {
                this.setState({
                    ultimaChamada: res.data.ultimaChamada,
                    loading: false,
                });

                edicoes.push(...res.data.cache);
            })
            .catch(error => {                
                const e = errors(error);                
                this.setState({ loading: false });
                
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            });

        for (let i = (getEdicao(this.props.contexts) - 1); i >= 2; i--)
            await RelatorioService.filtersReportInscricoesPorEdicaoOtherEdition(i, f)
                    .then(res => edicoes.push(...res.data.cache))
                    .catch(() => null);

        if (edicoes.length > 0)
            this.setState({ 
                edicoes: [...edicoes.sort((a, b) => b.edicao - a.edicao)], 
            });
    }

    componentDidMount() {
        this.ApplyFilters(false, true);
    }

    render() {
        const pageName = 'Edições';

        return(
            <>
                <Toast parentRef={this.setToast} />
                <Title>
                    {pageName}
                    { this.state.ultimaChamada && 
                        <Cache ultimaAtualizacao={this.state.ultimaChamada} action={() => this.ApplyFilters(true)} /> 
                    }
                </Title>

                { this.state.loading ? 
                    <Loading />
                :
                    <DadosInscricoesEdicao edicoes={this.state.edicoes} contexts={this.props.contexts} />
                }
            </>
        )
    }
}

export default InscricoesPorEdicao;