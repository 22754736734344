import React, { Component } from 'react';

import { isAfterDataProvaFase1, isAfterDataFimInscricaoEDataFimPagamento } from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';

import Table, { DataTableLineTotals as Totals } from '../../components/Table/Table';
import {
    PAGE,
    TOTAL_ELEMENTS,
    TOTAL_PAGES,
} from '../../components/Table/Utils';

import { evasaoLimiterMask, numberFormat } from '../../helper/MaskHelper';

import {
    ButtonNewTabEscolasPorMunicipio,
    ButtonNewTabEscolasPorMunicipioAndStatus,
    ButtonNewTabAlunosInscritosAndMunicipio,
    ButtonNewTabAlunosInscritosAndMunicipioAndNivel,
    ButtonNewTabAlunosParticipantesAndMunicipio,
    ButtonNewTabAlunosParticipantesAndMunicipioAndNivel,
} from '../Buttons/ButtonsNewTab';

export class DadosMunicipios extends Component {
    constructor (props) {
        super(props)
        this.state = {
            page: PAGE,
            size: 50,
            totalElements: TOTAL_ELEMENTS,
            totalPages: TOTAL_PAGES,
        }
    }

    sumMunicipios = () => this.props.municipios.length;

    sumEscolas = () => this.props.municipios.reduce((total, mun) => total + mun.qtdEscolas, 0);
    sumEscolasInscritas = () => this.props.municipios.reduce((total, mun) => total + mun.qtdEscolasInscritas, 0);
    sumEscolasAguardandoPagamento = () => this.props.municipios.reduce((total, mun) => total + mun.qtdEscolasAguardandoPagamento, 0);
    sumAlunosPrevistos = () => this.props.municipios.reduce((total, mun) => total + mun.qtdAlunosPrevistos, 0);
    sumAlunosParticipantes = () => this.props.municipios.reduce((total, mun) => total + mun.qtdAlunosParticipantes, 0);

    calcEvasao = () => 100 - ((this.sumAlunosParticipantes() * 100) / this.sumAlunosPrevistos());

    sumAlunosParticipantesFase2Mirim1 = () => this.props.municipios.reduce((total, mun) => total + mun.qtdAlunosParticipantesFase2Mirim1, 0);
    sumAlunosParticipantesFase2Mirim2 = () => this.props.municipios.reduce((total, mun) => total + mun.qtdAlunosParticipantesFase2Mirim2, 0);
    sumAlunosParticipantesFase2 = () => this.props.municipios.reduce((total, mun) => total + mun.qtdAlunosParticipantesFase2, 0);

    sumAlunosInscritosFase2Mirim1 = () => this.props.municipios.reduce((total, mun) => total + mun.qtdAlunosInscritosFase2Mirim1, 0);
    sumAlunosInscritosFase2Mirim2 = () => this.props.municipios.reduce((total, mun) => total + mun.qtdAlunosInscritosFase2Mirim2, 0);
    sumAlunosInscritosFase2 = () => this.props.municipios.reduce((total, mun) => total + mun.qtdAlunosInscritosFase2, 0);

    // PAGINAÇÃO APENAS NA VIEW
    handlePage = (value) => this.setState({ ...this.state, page: value, });
    handleRowsPerPage = (value) => this.setState({ ...this.state, size: value, page: 0, });
    handleTotalPages = () => Math.ceil(this.props.municipios.length / this.state.size);
    handleTotalElements = () => this.props.municipios.length;

    getUf = municipio => this.props.municipios.slice(this.state.page * this.state.size, (this.state.page * this.state.size) + this.state.size).find(mun => mun.cdMunicipio === municipio).nmUf;

    newTabEscolasPorMunicipio = (qtd, municipio) => <ButtonNewTabEscolasPorMunicipio qtd={numberFormat(qtd)} uf={this.getUf(municipio)} municipio={municipio} />
    newTabEscolasPorMunicipioAndStatus = (qtd, municipio, status) => <ButtonNewTabEscolasPorMunicipioAndStatus qtd={numberFormat(qtd)} uf={this.getUf(municipio)} municipio={municipio} status={status} />
    newTabInscritosAndMunicipio = (qtd, municipio) => <ButtonNewTabAlunosInscritosAndMunicipio qtd={numberFormat(qtd)} uf={this.getUf(municipio)} municipio={municipio} />
    newTabInscritosAndMunicipioAndNivel = (qtd, municipio, nivel) => <ButtonNewTabAlunosInscritosAndMunicipioAndNivel qtd={numberFormat(qtd)} uf={this.getUf(municipio)} municipio={municipio} nivel={nivel} />
    newTabParticipantesAndMunicipio = (qtd, municipio) => <ButtonNewTabAlunosParticipantesAndMunicipio qtd={numberFormat(qtd)} uf={this.getUf(municipio)} municipio={municipio} />
    newTabParticipantesAndMunicipioAndNivel = (qtd, municipio, nivel) => <ButtonNewTabAlunosParticipantesAndMunicipioAndNivel qtd={numberFormat(qtd)} uf={this.getUf(municipio)} municipio={municipio} nivel={nivel} />

    render () {
        const columns = [
            { label: '#', name: 'cdMunicipio', func: null, key: true, hidden: true, },

            { label: 'UF', name: 'nmUf', func: null, key: false, witdh: '5', orderLabel: 'asc', },
            { label: 'Municipio', name: 'nmMunicipio', func: null, key: false, width: '10', },			
            { label: 'Escolas', name: 'qtdEscolas', func: this.newTabEscolasPorMunicipio, key: false, width: '7', },
            
			{ label: 'Escolas Inscritas', name: 'qtdEscolasInscritas', func: (qtd, municipio) =>  this.newTabEscolasPorMunicipioAndStatus(qtd, municipio, "CONCLUIDA"), key: false, width: '7', },
            { label: 'Escolas Aguardando Pagamento', name: 'qtdEscolasAguardandoPagamento', func: (qtd, municipio) => this.newTabEscolasPorMunicipioAndStatus(qtd, municipio, "AGUARDANDO_PAGAMENTO"), key: false, width: '7', hidden: isAfterDataFimInscricaoEDataFimPagamento(this.props.contexts), },
            { label: 'Fase 1 - Alunos Previstos', name: 'qtdAlunosPrevistos', func: numberFormat, key: false, width: '7', },
            { label: 'Fase 1 - Alunos Participantes', name: 'qtdAlunosParticipantes', func: numberFormat, key: false, width: '7', },
            { label: 'Fase 1 - Evasão Total', name: 'evasaoTotal', func: isAfterDataProvaFase1(this.props.contexts) ? evasaoLimiterMask : () => "Prova Não Realizada", key: false, width: '7', },

            { label: 'Fase 2 - Mirim 1 - Alunos Inscritos', name: 'qtdAlunosInscritosFase2Mirim1', func: (qtd, municipio) => this.newTabInscritosAndMunicipioAndNivel(qtd, municipio, "MIRIM_1"), key: false, width: '7', },
            { label: 'Fase 2 - Mirim 2 - Alunos Inscritos', name: 'qtdAlunosInscritosFase2Mirim2', func: (qtd, municipio) => this.newTabInscritosAndMunicipioAndNivel(qtd, municipio, "MIRIM_2"), key: false, width: '7', },
            { label: 'Fase 2 - Alunos Inscritos', name: 'qtdAlunosInscritosFase2', func: (qtd, municipio) => this.newTabInscritosAndMunicipio(qtd, municipio, "MIRIM_1"), key: false, width: '7', },

            { label: 'Fase 2 - Mirim 1 - Alunos Com Nota Lançada', name: 'qtdAlunosParticipantesFase2Mirim1', func: (qtd, municipio) => this.newTabParticipantesAndMunicipioAndNivel(qtd, municipio, "MIRIM_1"), key: false, width: '7', },
            { label: 'Fase 2 - Mirim 2 - Alunos Com Nota Lançada', name: 'qtdAlunosParticipantesFase2Mirim2', func: (qtd, municipio) => this.newTabParticipantesAndMunicipioAndNivel(qtd, municipio, "MIRIM_2"), key: false, width: '7', },
            { label: 'Fase 2 - Alunos Com Nota Lançada', name: 'qtdAlunosParticipantesFase2', func: (qtd, municipio) => this.newTabParticipantesAndMunicipio(qtd, municipio, "MIRIM_1"), key: false, width: '7', },
        ];
        
        const actions = [];
        
        const totals = [
            { column: 0, label: 'Totais', value: "#", width: '5', },
            { column: 1, label: 'Municípios', value: numberFormat(this.sumMunicipios()), width: '10', },
            { column: 2, label: 'Escolas', value: numberFormat(this.sumEscolas()), width: '7', },
            
            { column: 3, label: 'Escolas Inscritas', value: numberFormat(this.sumEscolasInscritas()), width: '7', },
            { column: 4, label: 'Escolas Aguardando Pagamento', value: numberFormat(this.sumEscolasAguardandoPagamento()), width: '7', hidden: isAfterDataFimInscricaoEDataFimPagamento(this.props.contexts), },
            { column: 5, label: 'Fase 1 - Alunos Previstos', value: numberFormat(this.sumAlunosPrevistos()), width: '7', },
            { column: 6, label: 'Fase 1 - Alunos Participantes', value: numberFormat(this.sumAlunosParticipantes()), width: '7', },
            { column: 7, label: 'Fase 1 - Evasão Total', value: isAfterDataProvaFase1(this.props.contexts) ? evasaoLimiterMask(this.calcEvasao()) : () => "Prova Não Realizada", width: '7', },
            
            { column: 8, label: 'Fase 2 - Mirim 1 - Alunos Inscritos', value: numberFormat(this.sumAlunosInscritosFase2Mirim1()), width: '7', },
            { column: 9, label: 'Fase 2 - Mirim 2 - Alunos Inscritos', value: numberFormat(this.sumAlunosInscritosFase2Mirim2()), width: '7', },            
            { column: 10, label: 'Fase 2 - Alunos Inscritos', value: numberFormat(this.sumAlunosInscritosFase2()), width: '7', },
            
            { column: 11, label: 'Fase 2 - Mirim 1 - Alunos Com Nota Lançada', value: numberFormat(this.sumAlunosParticipantesFase2Mirim1()), width: '7', },
            { column: 12, label: 'Fase 2 - Mirim 2 - Alunos Com Nota Lançada', value: numberFormat(this.sumAlunosParticipantesFase2Mirim2()), width: '7', },
            { column: 13, label: 'Fase 2 - Alunos Com Nota Lançada', value: numberFormat(this.sumAlunosParticipantesFase2()), width: '7', },
        ];
        
        return (
            <Grid container spacing={3} style={{ marginTop: this.props.marginTop ?? 8 }}>
                <Grid item xs={12}>
                    <Table              
                        columns={columns}
                        tableData={this.props.municipios.slice(this.state.page * this.state.size, (this.state.page * this.state.size) + this.state.size)}
                        actions={actions}
                        page={this.state.page}
                        handlePage={this.handlePage}
                        handleTotalPages={this.handleTotalPages}
                        handleTotalElements={this.handleTotalElements}
                        rowsPerPage={this.state.size}
                        handleRowsPerPage={this.handleRowsPerPage}
                        sort={null}
                        fixedHeader={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Totals lineData={totals} columns={columns} showHead={true} />
                </Grid>
            </Grid>
        )
    }
}

export default DadosMunicipios;