import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { TabPanel, TabContext, TabList } from '@material-ui/lab';
import { Tab } from '@material-ui/core';
import { green } from "@material-ui/core/colors";
import SettingsBackupRestoreOutlinedIcon from '@material-ui/icons/SettingsBackupRestoreOutlined';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';

import EscolaService from '../../services/Escola';
import InscricaoService from '../../services/Inscricao';
import AlunoService from '../../services/Aluno';
import ArquivoService from '../../services/Arquivo';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Table from '../../components/Table/Table';
import Toast from '../../components/Toast/Toast';
import { ButtonPrimary, ButtonSecondary } from '../../components/Button/Button';

import { locationEscolaParse } from '../../helper/ParseStateHelper';
import { ButtonCancel } from '../../forms/Buttons/ButtonsForm';
import { DialogConfirm as DialogDesfazerPremiacao } from '../../forms/Dialog/DialogConfirm';

import { Layout } from '../../layouts/private/Private';

import { download } from '../../helper/FileHelper';
import { currentDateTime } from '../../helper/DateHelper';
import { 
  isPeriodoInserirNotaFase2,
  getFimPeriodoInserirNotaFase2, 
  isAfterFimPeriodoInserirNotaFase2, 
  isHabilitadoUploadTermoAutorizacao,
  isAbertaDataAlternativaPremiacao,
  getDataAlternativaPremiacao,
} from '../../helper/ContextHelper';

import gold from '../../assets/img/gold.png';
import silver from '../../assets/img/silver.png';
import bronze from '../../assets/img/bronze.png';

import TermoAutorizacao from '../Aluno/TermoAutorizacao';
import Desempate from './Desempate';
import classes from './Style';

export class Premiados extends Component {
  constructor(props) {
    super(props);
    this.state = {
        mec: null,
        escola: null,
        temArquivoCertificado: false,
        mirim1: [],
        mirim2: [],
        loadingMirim1: true,
        loadingMirim2: true,
        tab: '0',
        history: {
          path: null,
          state: null,
        }
    }
  }

  setToast = t => this.Toast = t;
  setDesempate = d => this.Desempate = d;
  setTermoAutorizacao = a => this.TermoAutorizacao = a;
  setDialogDesfazerPremiacao  = r => this.DialogDesfazerPremiacao = r;

  handleTab = (e, newTab) => {
    this.setState({ tab: newTab });
  }

  handleBack = () => {
    if (this.state.history && this.state.history.path) {
      this.props.history.push({
        pathname: `${this.state.history.path}`,
        state: {
          history: {
            state: this.state.history.state
          }
        }
      });
    } else {
      this.props.history.goBack();
    }
  }

  handleDesfazerPremiacao = () => {
    this.DialogDesfazerPremiacao.setState({
      dialog: {
        open: true,
        title: `Deseja Resetar a Premiação da Escola ${this.state.escola.nmEscola} - ${this.state.escola.cdMecEscola} ?`,
        text: `A partir desta ação a Escola estará habilitada a Adicionar / Editar as Notas de Fase 2 dos seus Alunos 
                e a Premiação desta Escola deverá ser executada novamente, exclusivamente na Listagem de Alunos, na ação de Finalizar Lançamento de Notas.`,
        id: this.state.escola.cdMecEscola,
        size: "lg",
        loading: false,
        showConfirm: true,
      }
    });
  }

  // DESFAZER PREMIACAO
  handleDesfazerPremiacaoConfirm = (id) => {
    this.DialogDesfazerPremiacao.loading();

    InscricaoService.resetarPremiacao(id)
      .then(res => {
        this.Toast.setState({
          message: {
            message: "A Premiação foi Resetada com Sucesso. Em instantes você será redirecionado para a Listagem de Alunos.",
            type: 'success',
            open: true
          }
        })
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      })
      .finally(() => {
        this.DialogDesfazerPremiacao.close();
        this.props.history.push({ pathname: `/admin/inscricao/${id}/fase-2`, state: { escola: id } });
      });
  }

  loadData = async () => {
    const f = locationEscolaParse(this.props.location);
    const cdEscola = !isNaN(this.props.match.params.id) ? this.props.match.params.id : f.find(f => f.field === 'id').value;

    this.setState({ mec: cdEscola, loadingMirim1: true, loadingMirim2: true });

    await EscolaService.find(cdEscola)
      .then(res => {
        this.setState({
          ...this.state,
          escola: res.data,
        });
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      })
    
    ArquivoService.list()
      .then(res => {
        if (res.data.content) {
          this.setState({ 
            ...this.state, 
            temArquivoCertificado: res.data.content.find(arquivo => arquivo.tipo === 'CERTIFICADO') != null, 
          });
        }
      })

    InscricaoService.listarPremiados(cdEscola, "MIRIM_1")
      .then(res => {
          this.setState({ 
              ...this.state,
              mirim1: [...res.data],
              tab: res.data.length > 0 ? '0' : '1',
          });
      })
      .catch(error => {
          const e = errors(error);
          this.Toast.setState({
              message: {
                  message: e.message,
                  type: e.type,
                  open: true
              }
          })
      }).finally(() => this.setState({ loadingMirim1: false }))

    InscricaoService.listarPremiados(cdEscola, "MIRIM_2")
      .then(res => {
          this.setState({ 
              ...this.state,
              mirim2: [...res.data],
              tab: res.data.length > 0 ? '1' : '0',
          });
      })
      .catch(error => {
          const e = errors(error);
          this.Toast.setState({
              message: {
                  message: e.message,
                  type: e.type,
                  open: true
              }
          })
        }).finally(() => this.setState({ loadingMirim2: false }))
  }

  // COMPONENT DID MOUNT
  async componentDidMount() { 
    await this.props.loadContext();
    this.loadData(); 
  }

  patternIconPremio = (premio) => premio === 'Ouro' ? <img src={gold} className={classes.medal} alt="Medalha de Ouro" width="30" />
                                    : premio === 'Prata' ? <img src={silver} className={classes.medal} alt="Medalha de Prata" width="30" />
                                      : premio === 'Bronze' ? <img src={bronze} className={classes.medal} alt="Medalha de Bronze" width="30" /> : null;

  patternPremio = (premio) => <>Medalha de <b>{premio}</b></>;

  handleDownloadCertificado = (id) => {
    this.Toast.setState({
      message: {
        message: "Em Instantes o Download Iniciará Automáticamente",
        type: "info",
        open: true
      }
    });

    AlunoService.downloadCertificado(id)
      .then(res => download(res))
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      });
  }

  disabledDownloadCertificado = (id) => !this.state.temArquivoCertificado || !isAfterFimPeriodoInserirNotaFase2(this.props.contexts) || isAbertaDataAlternativaPremiacao(this.state.escola.inscricao.dataAlternativaPremiacao);

  buttonDownloadCertificado = (id) => {
    let aluno = this.state.mirim1.find(s => s.cdAluno === id);

    if (!aluno)
      aluno = this.state.mirim2.find(s => s.cdAluno === id);

    return (
      <ButtonPrimary
        onClick={() => this.handleDownloadCertificado(aluno.cdAluno)}
        name={`Certificado de Premiação`}
        disabled={this.disabledDownloadCertificado(id)}
        style={!this.disabledDownloadCertificado(id) ? { backgroundColor: green[800] } : null}
      />
    )
  }

  render () {
    const pageName = 'Listagem de Premiados';
    const links = [];

    const columns = [
        { label: '#', name: 'cdAluno', func: null, key: true, hidden: true },
        { label: '#', name: 'premio', func: this.patternIconPremio, key: false, width: '10' },
        { label: 'Prêmio', name: 'premio', func: this.patternPremio, key: false, width: '10' },
        { label: 'Nome do Aluno', name: 'nmAluno', func: null, key: false, width: '40' },
        { label: 'Nota', name: 'nota', func: null, key: false, width: '20' },
    ];
    
    const actions = [
      {
        name: () => !isAfterFimPeriodoInserirNotaFase2(this.props.contexts) || isAbertaDataAlternativaPremiacao(this.state.escola.inscricao.dataAlternativaPremiacao) ? "Período de Lançamento de Notas Não Finalizado" : !this.state.temArquivoCertificado ? "Você Não tem permissão para fazer isso" : "Download de Certificado",
        func: () => null,
        icon: this.buttonDownloadCertificado,
        disabled: this.disabledDownloadCertificado,
      },
    ];

    if (isHabilitadoUploadTermoAutorizacao(this.props.contexts) && this.TermoAutorizacao && this.state.mirim1.length + this.state.mirim2.length > 0) {
      actions.push({
        name: this.TermoAutorizacao.labelTermo,
        func: this.TermoAutorizacao.handleTermoDownloadClick,
        icon: this.TermoAutorizacao.buttonTermo,
        disabled: this.TermoAutorizacao.disabledTermo,
      });
    }

    return (
      <Layout>
        <Toast parentRef={this.setToast} />
        <Grid container >
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={pageName} />
          </Grid>
        </Grid>

        <Title>{pageName}</Title>

        {this.state.escola && this.state.escola.inscricao && this.state.escola.inscricao.statusPremiacao != null && (isPeriodoInserirNotaFase2(this.props.contexts) || isAbertaDataAlternativaPremiacao(this.state.escola.inscricao.dataAlternativaPremiacao)) &&
          <Grid item xs={12} spacing={1}>
            <Alert severity="warning" style={{ marginTop: '10px', marginBottom: '25px' }} >
              <Grid container >
                <Grid item sm={12} lg={12} >
                  <Typography variant="body1">
                      <b>ATENÇÃO: </b>
                      Estamos em <b>{currentDateTime()}</b>. Sua escola tem até 
                      <b>{isPeriodoInserirNotaFase2(this.props.contexts) ? getFimPeriodoInserirNotaFase2(this.props.contexts) : getDataAlternativaPremiacao(this.state.escola.inscricao.dataAlternativaPremiacao)}</b> 
                      para concluir a definição da Premiação.
                      Caso seja necessário é possível <b>Reiniciar</b> a Premiação da Escola clicando no botão abaixo.
                  </Typography>
                </Grid>
                
                <Grid item sm={12} lg={4} style={{ marginTop: '10px' }} >
                  <ButtonSecondary
                    startIcon={<SettingsBackupRestoreOutlinedIcon />}
                    onClick={this.handleDesfazerPremiacao}
                    name="Resetar Premiação"
                  />
                </Grid>
              </Grid>          
            </Alert>
          </Grid>
        }

        {this.state.escola && this.state.escola.inscricao && this.state.escola.inscricao.statusPremiacao && 
          <Desempate parentRef={this.setDesempate} handleClose={this.loadData} mec={this.state.mec} statusPremiacao={this.state.escola.inscricao.statusPremiacao} />
        }

        <Grid container style={{ marginTop: '16px' }} >
          <Grid item xs={12}>
            <Paper style={{ padding: '24px' }} elevation={1}>
              <TabContext value={this.state.tab}>
                <TabList onChange={this.handleTab} indicatorColor="primary" textColor="primary" variant="fullWidth">
                  <Tab label="Nível Mirim 1" value="0" />
                  <Tab label="Nível Mirim 2" value="1" />
                </TabList>
                              
                <TabPanel value="0">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Table              
                        columns={columns}
                        tableData={this.state.mirim1}
                        actions={actions}
                        page={null}
                        sort={null}
                        rowsPerPage={null}
                        loading={this.state.loadingMirim1}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value="1">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Table              
                        columns={columns}
                        tableData={this.state.mirim2}
                        actions={actions}
                        page={null}
                        sort={null}
                        rowsPerPage={null}
                        loading={this.state.loadingMirim2}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
              </TabContext>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={3} alignItems='flex-end' style={{ marginTop: 16 }}>
          <Grid item sm={12} lg={12}>
            <ButtonCancel
              onBack={this.handleBack}
              nameOnBack={"Voltar"}
            />
          </Grid>
        </Grid>

        {this.state.mirim1.length + this.state.mirim2.length > 0 &&
          <TermoAutorizacao 
            parentRef={this.setTermoAutorizacao}
            handleClose={this.loadData}
            alunos={[ ...this.state.mirim1, ...this.state.mirim2 ]}
          />
        }

        <DialogDesfazerPremiacao
          parentRef={this.setDialogDesfazerPremiacao}
          handleConfirm={this.handleDesfazerPremiacaoConfirm}
        />
      </Layout>
    )
  }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Premiados));