import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Cache from '../Relatorio/Cache';

import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import Loading from '../../components/Loading/Loading';

import RelatorioService from '../../services/Relatorio';
import { errors } from '../../services/API';

import MapaBrasil from './MapaBrasil';

export class DadosPorUf extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ufs: [],
            ultimaChamada: '',
            loading: true,
        }
    }

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;

    // FILTROS
    refresh = (forceRefresh = false) => {
        this.setState({ loading: true });

        const f = [];        
        f.push({ field: "forceRefresh", value: f.length > 0 ? true : forceRefresh });

        RelatorioService.filtersReportUfs(f)
            .then(res => {
                this.setState({ 
                    ufs: [...res.data.cache],
                    ultimaChamada: res.data.ultimaChamada,
                    loading: false 
                });
            })
            .catch(error => {
                const e = errors(error);
                this.setState({ loading: false });
                
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
    }

    componentDidMount() {
        this.refresh(false);
    }

    render() {
        const pageName = 'Ufs';

        return(
            <>
                <Toast parentRef={this.setToast} />
                <Title>
                    {pageName}
                    { this.state.ultimaChamada && 
                        <Cache ultimaAtualizacao={this.state.ultimaChamada} action={() => this.refresh(true)} /> 
                    }
                </Title>

                { this.state.loading ? 
                    <Loading />
                :
                    <Grid container spacing={3} style={{ marginTop: 8 }}>
                        <Grid item md={12} lg={12} >
                            {this.state.ufs.length > 0 &&
                                <MapaBrasil ufs={this.state.ufs} />
                            }
                        </Grid>
                    </Grid>
                }
            </>
        )
    }
}

export default DadosPorUf;