import React, { Component } from 'react';

import { isAfterDataProvaFase1, isAfterDataFimInscricaoEDataFimPagamento } from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';

import Table, { DataTableLineTotals as Totals } from '../../components/Table/Table';

import { patternAdminDep } from '../../variables/Enums/DependenciaAdministrativa';

import { evasaoLimiterMask, numberFormat } from '../../helper/MaskHelper';

import {
    ButtonNewTabEscolasPorDepAdminAndStatus, 
    ButtonNewTabAlunosInscritosAndDepAdmin, 
    ButtonNewTabAlunosInscritosAndDepAdminAndNivel, 
    ButtonNewTabAlunosParticipantesAndDepAdmin, 
    ButtonNewTabAlunosParticipantesAndDepAdminAndNivel, 
} from '../Buttons/ButtonsNewTab';

export class DadosDependenciasAdministrativas extends Component {
    // SOMA GERAL PARA ESCOLAS INSCRITAS
    sumEscolasInscritas = () => this.props.dependencias.reduce((total, dep) => total + dep.qtdEscolasInscritasTotal, 0);

    sumEscolasAguardandoPagamento = () => this.props.dependencias.reduce((total, dep) => total + dep.qtdEscolasAguardandoPagamentoTotal, 0);
    
    // SOMA GERAL PARA ALUNOS PREVISTOS
    sumAlunosPrevistos = () => this.props.dependencias.reduce((total, dep) => total + dep.qtdAlunosPrevistosTotal, 0);

    // SOMA GERAL PARA ALUNOS PARTICIPANTES
    sumAlunosParticipantes = () => this.props.dependencias.reduce((total, dep) => total + dep.qtdAlunosParticipantesTotal, 0);

    // CLASSIFICAVEIS
    sumAlunosClassificaveisMirim1 = () => this.props.dependencias.reduce((total, dep) => total + dep.qtdMaximaAlunosClassificaveisMirim1, 0);
    sumAlunosClassificaveisMirim2 = () => this.props.dependencias.reduce((total, dep) => total + dep.qtdMaximaAlunosClassificaveisMirim2, 0);
    sumAlunosClassificaveis = () => this.props.dependencias.reduce((total, dep) => total + dep.qtdMaximaAlunosClassificaveis, 0);

    // INSCRITOS
    sumAlunosInscritosFase2Mirim1 = () => this.props.dependencias.reduce((total, dep) => total + dep.qtdAlunosInscritosFase2Mirim1, 0);
    sumAlunosInscritosFase2Mirim2 = () => this.props.dependencias.reduce((total, dep) => total + dep.qtdAlunosInscritosFase2Mirim2, 0);
    sumAlunosInscritosFase2 = () => this.props.dependencias.reduce((total, dep) => total + dep.qtdAlunosInscritosFase2, 0);

    // PARTICIPANTES
    sumAlunosParticipantesFase2Mirim1 = () => this.props.dependencias.reduce((total, dep) => total + dep.qtdAlunosParticipantesFase2Mirim1, 0);
    sumAlunosParticipantesFase2Mirim2 = () => this.props.dependencias.reduce((total, dep) => total + dep.qtdAlunosParticipantesFase2Mirim2, 0);
    sumAlunosParticipantesFase2 = () => this.props.dependencias.reduce((total, dep) => total + dep.qtdAlunosParticipantesFase2, 0);

    // CALCULAR EVASÃO
    calcEvasao = () => this.sumAlunosPrevistos() > 0 ? 100 - ((this.sumAlunosParticipantes() * 100) / this.sumAlunosPrevistos()) : 0;

    newTabEscolasPorDepAdminAndStatus = (qtd, depAdmin, status) => <ButtonNewTabEscolasPorDepAdminAndStatus qtd={numberFormat(qtd)} depAdmin={depAdmin} status={status} />
    newTabInscritosAndDepAdmin = (qtd, depAdmin) => <ButtonNewTabAlunosInscritosAndDepAdmin qtd={numberFormat(qtd)} depAdmin={depAdmin} />
    newTabInscritosAndDepAdminAndNivel = (qtd, depAdmin, nivel) => <ButtonNewTabAlunosInscritosAndDepAdminAndNivel qtd={numberFormat(qtd)} depAdmin={depAdmin} nivel={nivel} />
    newTabParticipantesAndDepAdmin = (qtd, depAdmin) => <ButtonNewTabAlunosParticipantesAndDepAdmin qtd={numberFormat(qtd)} depAdmin={depAdmin} />
    newTabParticipantesAndDepAdminAndNivel = (qtd, depAdmin, nivel) => <ButtonNewTabAlunosParticipantesAndDepAdminAndNivel qtd={numberFormat(qtd)} depAdmin={depAdmin} nivel={nivel} />

    render () {
        const columns = [
            { label: 'Dependência Administrativa', name: 'dependenciaAdministrativa', func: patternAdminDep, key: true, width: '6', orderLabel: 'asc', },
            { label: 'Total de Escolas Aguardando Pagamento', name: 'qtdEscolasAguardandoPagamentoTotal', func: (qtd, depAdmin) => this.newTabEscolasPorDepAdminAndStatus(qtd, depAdmin, "AGUARDANDO_PAGAMENTO"), key: false, width: '6', hidden: isAfterDataFimInscricaoEDataFimPagamento(this.props.contexts), },
            { label: 'Total de Escolas Inscritas', name: 'qtdEscolasInscritasTotal', func: (qtd, depAdmin) => this.newTabEscolasPorDepAdminAndStatus(qtd, depAdmin, "CONCLUIDA"), key: false, width: '6', },
            { label: 'Fase 1 - Total de Alunos Inscritos', name: 'qtdAlunosPrevistosTotal', func: numberFormat, key: false, width: '6', },
            { label: 'Fase 1 - Total de Alunos Participantes', name: 'qtdAlunosParticipantesTotal', func: numberFormat, key: false, width: '6', },            
            { label: 'Fase 1 - Evasão Total', name: 'evasaoTotal', func: isAfterDataProvaFase1(this.props.contexts) ? evasaoLimiterMask : () => "Prova Não Realizada", key: false, width: '6', },
            { label: 'Fase 2 - Mirim 1 - Alunos Classificaveis', name: 'qtdMaximaAlunosClassificaveisMirim1', func: numberFormat, key: false, width: '6', },
            { label: 'Fase 2 - Mirim 2 - Alunos Classificaveis', name: 'qtdMaximaAlunosClassificaveisMirim2', func: numberFormat, key: false, width: '6', },
            { label: 'Fase 2 - Total de Alunos Classificaveis', name: 'qtdMaximaAlunosClassificaveis', func: numberFormat, key: false, width: '6', },
            { label: 'Fase 2 - Mirim 1 - Alunos Inscritos', name: 'qtdAlunosInscritosFase2Mirim1', func: (qtd, depAdmin) => this.newTabInscritosAndDepAdminAndNivel(qtd, depAdmin, "MIRIM_1"), key: false, width: '6', },
            { label: 'Fase 2 - Mirim 2 - Alunos Inscritos', name: 'qtdAlunosInscritosFase2Mirim2', func: (qtd, depAdmin) => this.newTabInscritosAndDepAdminAndNivel(qtd, depAdmin, "MIRIM_2"), key: false, width: '6', },
            { label: 'Fase 2 - Total de Alunos Inscritos', name: 'qtdAlunosInscritosFase2', func: this.newTabInscritosAndDepAdmin, key: false, width: '6', },
            { label: 'Fase 2 - Mirim 1 - Alunos Com Nota Lançada', name: 'qtdAlunosParticipantesFase2Mirim1', func: (qtd, depAdmin) => this.newTabParticipantesAndDepAdminAndNivel(qtd, depAdmin, "MIRIM_1"), key: false, width: '6', },
            { label: 'Fase 2 - Mirim 2 - Alunos Com Nota Lançada', name: 'qtdAlunosParticipantesFase2Mirim2', func: (qtd, depAdmin) => this.newTabParticipantesAndDepAdminAndNivel(qtd, depAdmin, "MIRIM_2"), key: false, width: '6', },
            { label: 'Fase 2 - Total de Alunos Com Nota Lançada', name: 'qtdAlunosParticipantesFase2', func: this.newTabParticipantesAndDepAdmin, key: false, width: '6', },
        ];
        
        const actions = [];

        const totals = [
            { column: 0, value: "Total", width: '6', },
            { column: 1, value: numberFormat(this.sumEscolasAguardandoPagamento()), width: '6', hidden: isAfterDataFimInscricaoEDataFimPagamento(this.props.contexts), },
            { column: 2, value: numberFormat(this.sumEscolasInscritas()), width: '6', },
            { column: 3, value: numberFormat(this.sumAlunosPrevistos()), width: '6', },
            { column: 4, value: numberFormat(this.sumAlunosParticipantes()), width: '6', },
            { column: 5, value: isAfterDataProvaFase1(this.props.contexts) ? evasaoLimiterMask(this.calcEvasao()) : () => "Prova Não Realizada", width: '6', },
            { column: 6, value: numberFormat(this.sumAlunosClassificaveisMirim1()), width: '6', },
            { column: 7, value: numberFormat(this.sumAlunosClassificaveisMirim2()), width: '6', },
            { column: 8, value: numberFormat(this.sumAlunosClassificaveis()), width: '6', },
            { column: 9, value: numberFormat(this.sumAlunosInscritosFase2Mirim1()), width: '6', },
            { column: 10, value: numberFormat(this.sumAlunosInscritosFase2Mirim2()), width: '6', },
            { column: 11, value: numberFormat(this.sumAlunosInscritosFase2()), width: '6', },
            { column: 12, value: numberFormat(this.sumAlunosParticipantesFase2Mirim1()), width: '6', },
            { column: 13, value: numberFormat(this.sumAlunosParticipantesFase2Mirim2()), width: '6', },
            { column: 14, value: numberFormat(this.sumAlunosParticipantesFase2()), width: '6', },
        ];

        return (
            <Grid container spacing={3} style={{ marginTop: this.props.marginTop ?? 8 }}>
                <Grid item xs={12}>
                    <Table              
                        columns={columns}
                        tableData={this.props.dependencias}
                        actions={actions}
                        page={null}
                        rowsPerPage={null}
                        sort={null}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Totals lineData={totals} columns={columns} />
                </Grid>
            </Grid>
        )
    }
}

export default DadosDependenciasAdministrativas;