import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Table, { DataTableLineTotals as Totals } from '../../components/Table/Table';
import {
    PAGE,
    TOTAL_ELEMENTS,
    TOTAL_PAGES,
} from '../../components/Table/Utils';

import { numberFormat } from '../../helper/MaskHelper';

import { ButtonNewTabDataInscricao } from '../Buttons/ButtonsNewTab';

export class DadosInscricoes extends Component {
    constructor (props) {
        super(props)
        this.state = {
            page: PAGE,
            size: 25,
            totalElements: TOTAL_ELEMENTS,
            totalPages: TOTAL_PAGES,
        }
    }

    sumEscolas = () => this.props.inscricoes.reduce((total, i) => total + i.escolas, 0);
    sumAlunosPrevistosMirim1 = () => this.props.inscricoes.reduce((total, i) => total + i.mirim1, 0);
    sumAlunosPrevistosMirim2 = () => this.props.inscricoes.reduce((total, i) => total + i.mirim2, 0);
    sumAlunos = () => this.props.inscricoes.reduce((total, i) => total + i.alunos, 0);

    // PAGINAÇÃO APENAS NA VIEW
    handlePage = (value) => this.setState({ ...this.state, page: value, });
    handleRowsPerPage = (value) => this.setState({ ...this.state, size: value, page: 0, });
    handleTotalPages = () => Math.ceil(this.props.inscricoes.length / this.state.size);
    handleTotalElements = () => this.props.inscricoes.length;

    newTabDataInscricao = (data) => <ButtonNewTabDataInscricao data={data} />

    render () {
        const columns = [
            { label: 'Data de Inscrição', name: 'dataFormatada', func: this.newTabDataInscricao, key: true, width: '20', orderLabel: 'desc', },
            { label: 'Total de Escolas', name: 'escolas', func: numberFormat, key: false, width: '20', },
            { label: 'Mirim 1 - Alunos', name: 'mirim1', func: numberFormat, key: false, width: '20', },
            { label: 'Mirim 2 - Alunos', name: 'mirim2', func: numberFormat, key: false, width: '20', },
            { label: 'Total de Alunos', name: 'alunos', func: numberFormat, key: false, width: '20', },
        ];
        
        const actions = [];

        const totals = [
            { column: 0, value: "Total", width: '20', },
            { column: 1, value: numberFormat(this.sumEscolas()), width: '20', },
            { column: 2, value: numberFormat(this.sumAlunosPrevistosMirim1()), width: '20', },
            { column: 3, value: numberFormat(this.sumAlunosPrevistosMirim2()), width: '20', },
            { column: 4, value: numberFormat(this.sumAlunos()), width: '20', },
        ];

        return (
            <Grid container spacing={3} style={{ marginTop: this.props.marginTop ?? 8 }}>
                <Grid item xs={12}>
                    <Table
                        columns={columns}                        
                        tableData={this.props.inscricoes.slice(this.state.page * this.state.size, (this.state.page * this.state.size) + this.state.size)}
                        actions={actions}
                        page={this.state.page}
                        handlePage={this.handlePage}
                        handleTotalPages={this.handleTotalPages}
                        handleTotalElements={this.handleTotalElements}
                        rowsPerPage={this.state.size}
                        handleRowsPerPage={this.handleRowsPerPage}
                        sort={null}
                        fixedHeader={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Totals lineData={totals} columns={columns} />
                </Grid>
            </Grid>
        )
    }
}

export default DadosInscricoes;