import { makeStyles } from '@material-ui/core/styles';

import { drawerWidth } from '../Drawer/Style';

const useStyles = makeStyles( 
    (theme) => (
        {
            appBar: {
                transition: theme.transitions.create(['margin', 'width'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            },
            appBarShift: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
                transition: theme.transitions.create(['margin', 'width'], {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
            menuButton: {
                marginRight: theme.spacing(2),
                padding: '0px 12px',
            },
            hide: {
                display: 'none',
            },
            logoNav: {
                height: '65px',
                marginRight: theme.spacing(2),
            }
        }
    )
);

export default useStyles;