import React, { useRef } from 'react';
import ReactDOM from 'react-dom';

import { Map, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import { numberFormat } from '../../helper/MaskHelper';

import brStates from '../../assets/maps/brazil-states-geojson.json';

export const MapaBrasil = ({ ufs }) => {
    const mapRef = useRef(null);

    const center = [ -15.2350, -53.9253 ];
    const zoom = 5;

    const focusHighlight = (e) => e.target.setStyle({ weight: 4, fillOpacity: 0.8 });
    const resetHighlight = (e) => e.target.setStyle(getCssNormal(e.target.feature));

    const getCssNormal = (feature) => {
        let color = '#F7B034';

        switch (feature.properties.regiao_id) {
            case '1': color = '#AD9FCB'; break; // SUL
            case '2': color = '#DB9EC0'; break; // SUDESTE
            case '3': color = '#A4CD56'; break; // NORTE
            case '4': color = '#FDD40A'; break; // NORDESTE
            case '5': color = '#F9955A'; break; // CENTRO-OESTE
            default: break;
        } 

        return { color: color, weight: 2, fillOpacity: 0.3, fillColor: color };
    };

    const handleMapClick = () => mapRef.current.leafletElement.setView(center, zoom);

    return (
        <>
            {ufs.length > 0 && 
                <Map 
                    center={center} 
                    zoom={zoom} 
                    style={{ width: '100%', height: '950px' }}
                    scrollWheelZoom={false}
                    doubleClickZoom={false}
                    zoomControl={false}
                    touchZoom={false}
                    keyboard={false}
                    dragging={false}
                    ref={mapRef}
                    onClick={handleMapClick}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />

                    <GeoJSON
                        data={brStates}
                        style={getCssNormal}
                        onEachFeature={(feature, layer) => {
                            const uf = ufs.find(uf => uf.nmUf === feature.properties.sigla);
                            
                            const popup = document.createElement('div');
                            ReactDOM.render(<StatePopup name={feature.properties.name} uf={uf} />, popup);
                            layer.bindPopup(popup);

                            layer.on({ mouseover: focusHighlight, mouseout: resetHighlight });
                        }}
                    />
                </Map>
            }
        </>
    );
};

const StatePopup = ({ uf, name }) => {
    return (
        <div>
            <h3 style={{ textAlign: "center" }}><b>{name}</b></h3>
            <br />

            <div>
                <b>Inscrições</b>
                <br /><br />

                Escolas: <b>{numberFormat(uf.qtdEscolasTotal)}</b><br />
                Escolas Inscritas: <b>{numberFormat(uf.qtdEscolasInscritasTotal)}</b><br />
                Municipios: <b>{numberFormat(uf.qtdMunicipiosTotal)}</b><br />
                Municipios Inscritos: <b>{numberFormat(uf.qtdMunicipiosInscritosTotal)}</b><br />
            </div>
            <br />

            <div>
                <b>Fase 1</b>
                <br /><br />

                Mirim 1 - Alunos Inscritos: <b>{numberFormat(uf.qtdAlunosPrevistosMirim1)}</b><br />
                Mirim 2 - Alunos Inscritos: <b>{numberFormat(uf.qtdAlunosPrevistosMirim2)}</b><br />
                Total de Alunos Inscritos: <b>{numberFormat(uf.qtdAlunosPrevistosTotal)}</b><br />
                Total de Alunos Participantes: <b>{numberFormat(uf.qtdAlunosParticipantesTotal)}</b><br />
            </div>
            <br />
            
            <div>
                <b>Fase 2</b>
                <br /><br />

                Mirim 1 - Alunos Inscritos: <b>{numberFormat(uf.qtdAlunosInscritosFase2Mirim1)}</b><br />
                Mirim 2 - Alunos Inscritos: <b>{numberFormat(uf.qtdAlunosInscritosFase2Mirim2)}</b><br />
                Total de Alunos Inscritos: <b>{numberFormat(uf.qtdAlunosInscritosFase2)}</b><br />
                Mirim 1 - Alunos Com Nota Lançada: <b>{numberFormat(uf.qtdAlunosParticipantesFase2Mirim1)}</b><br />
                Mirim 2 - Alunos Com Nota Lançada: <b>{numberFormat(uf.qtdAlunosParticipantesFase2Mirim2)}</b><br />
                Total de Alunos Com Nota Lançada: <b>{numberFormat(uf.qtdAlunosParticipantesFase2)}</b><br />
            </div>
        </div>
    );
};

export default MapaBrasil;
