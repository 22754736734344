import React, { useEffect } from "react";

import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import { green } from "@material-ui/core/colors";

import { ButtonPrimary } from '../../components/Button/Button';
import { isPeriodoInserirNotaFase2, isPeriodoRegistroParticipacaoCadastroAlunos, isAbertaDataAlternativaPremiacao } from '../../helper/ContextHelper';

import AuthorizedFunction from "../../security/AuthorizedFunction";
import { __CENTRAL } from "../../security/RoleConfiguration";

export const AlertaEscola = ({ escola, contexts, finalizarLancamentoNotas }) => {
    useEffect(() => { }, [escola])

    const permitirFinalizar = (isPeriodoInserirNotaFase2(contexts) || isAbertaDataAlternativaPremiacao(escola.inscricao.dataAlternativaPremiacao) || (isPeriodoRegistroParticipacaoCadastroAlunos(contexts) && AuthorizedFunction([__CENTRAL])))

    return (
        <>
            {escola && escola.inscricao &&
                <Alert severity="info" style={{ marginTop: '10px' }}>
                    <Grid container >
                        <Grid item sm={12} lg={permitirFinalizar && !escola.inscricao.dataFinalizacaoLancamentoNotas && escola.inscricao.todosAlunosComNotaFase2Preenchida && finalizarLancamentoNotas ? 8 : 12}>
                            <Typography variant="body1">
                                <b>{escola.nmEscola} ({escola.cdMecEscola})</b><br />
                            </Typography>
                            <Typography variant="body2">
                                <b>Mirim 1</b>: Total de <b>{escola.inscricao.qtdAlunosInscritosFase2Mirim1}</b> Cadastrados para <b>{escola.inscricao.mirim1.qtdMaximaAlunosClassificaveis}</b> Classificaveis. 
                                {permitirFinalizar && 
                                    <>&nbsp;<b>{escola.inscricao.qtdAlunosComNotaFase2Mirim1}</b> alunos com nota atribuída na Fase 2. <b>{escola.inscricao.qtdAlunosSemNotaFase2Mirim1}</b> alunos sem nota atribuída na Fase 2.</>
                                }
                                <br />
                                <b>Mirim 2</b>: Total de <b>{escola.inscricao.qtdAlunosInscritosFase2Mirim2}</b> Cadastrados para <b>{escola.inscricao.mirim2.qtdMaximaAlunosClassificaveis}</b> Classificaveis. 
                                {permitirFinalizar && 
                                    <>&nbsp;<b>{escola.inscricao.qtdAlunosComNotaFase2Mirim2}</b> alunos com nota atribuída na Fase 2. <b>{escola.inscricao.qtdAlunosSemNotaFase2Mirim2}</b> alunos sem nota atribuída na Fase 2.</>
                                }
                                <br />
                            </Typography>
                        </Grid>
                        {permitirFinalizar && !escola.inscricao.dataFinalizacaoLancamentoNotas && escola.inscricao.todosAlunosComNotaFase2Preenchida && finalizarLancamentoNotas && 
                            <>
                                <Grid item sm={12} lg={4} style={{ marginTop: '8px' }} >
                                    <ButtonPrimary
                                        startIcon={<CheckOutlinedIcon />}
                                        onClick={() => finalizarLancamentoNotas(escola)}
                                        name="Após Informar as Notas de 2ª Fase, CLIQUE AQUI para Finalizar Lançamento de Notas e Realizar Premiação"
                                        style={{ backgroundColor: green[800] }}
                                    />
                                </Grid>
                            </>
                        }
                    </Grid>
                </Alert>
            }
        </>
    )
}

export function escolaComCadastroAlunoDisponivel(escola) {
    if (escola && escola.inscricao && (escola.inscricao.mirim1.qtdMaximaAlunosClassificaveis > escola.inscricao.qtdAlunosInscritosFase2Mirim1 || 
            escola.inscricao.mirim2.qtdMaximaAlunosClassificaveis > escola.inscricao.qtdAlunosInscritosFase2Mirim2)) {
        return true;
    }

    return false;
}

export function escolaPodeCadastrarMaisAlunosNoNivel(escola, nivel) {
    if (escola && escola.inscricao &&
        ((nivel === 1 && escola.inscricao.mirim1.qtdMaximaAlunosClassificaveis > escola.inscricao.qtdAlunosInscritosFase2Mirim1) ||
            (nivel === 2 && escola.inscricao.mirim2.qtdMaximaAlunosClassificaveis > escola.inscricao.qtdAlunosInscritosFase2Mirim2)))
        return true;

    return false;
}

export function escolaNaoInformouAlunosPrevistosParaNivel(escola, nivel) {
    if (escola && escola.inscricao &&
        ((nivel === 1 && escola.inscricao.mirim1.qtdMaximaAlunosClassificaveis === 0) || (nivel === 2 && escola.inscricao.mirim2.qtdMaximaAlunosClassificaveis === 0)))
        return true;

    return false;
}

export function escolaJaCadastrouTodosOsAlunosDoNivel(escola, nivel) {
    if (escola && escola.inscricao &&
        ((nivel === 1 && escola.inscricao.mirim1.qtdMaximaAlunosClassificaveis === escola.inscricao.qtdAlunosInscritosFase2Mirim1) ||
            (nivel === 2 && escola.inscricao.mirim2.qtdMaximaAlunosClassificaveis === escola.inscricao.qtdAlunosInscritosFase2Mirim2)))
        return true;

    return false;
}

export default AlertaEscola;