import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";

import { errors } from '../../services/API'

import Dialog from '../../components/Dialog/Dialog'
import Toast from '../../components/Toast/Toast'
import SwitchOption from "../../components/Inputs/Switch/Switch";
import { DatePicker } from '../../components/Inputs/DatePicker/DatePicker';

import { ButtonSave, ButtonCancel } from '../Buttons/ButtonsForm'

import EscolaService from '../../services/Escola';

import {
    isBlank,
    isBlankHelperText,
    isDateTimeHelperText,
    isDateTimeInvalid
} from '../../helper/ValidationHelper';
import { formatInDate, currentDatePlusDays } from '../../helper/DateHelper';

export class DialogDefinirDataAlternativaRealizarPremiacao extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            open: false,
            school: null,
            habilitarDataAlternativa: false,
            dataAlternativa: null,
            errors: {
                dataAlternativa: false,
            },
            helpers: {
                dataAlternativa: null,
            },
            loading: false,
        }
    }

    // DECLARAÇÕES DE REFERENCIA DOS COMPONENTES
    setToast = t => this.Toast = t;

    handleChangeSwitch = e => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.checked,
        });
    };

    // INPUTS DATE PICKER
    handleChangeDate = (e, name) => {
        this.setState({
            [name]: e,
            errors: { ...this.state.errors, [name]: false },
            helpers: { ...this.state.helpers, [name]: null }
        });
    }

    // AÇÕES MODAL
    buttons = (id) =>
        <>
            <ButtonSave 
                onClick={this.handleAtualizarDataAlternativa} 
                loading={this.state.loading}
            />
            <ButtonCancel onBack={this.handleClose} />
        </>

    // FECHAR MODAL
    handleClose = () => {
        if (this.props.handleClose)
            this.props.handleClose()

        this.setState({
            open: false,
            school: null,
            habilitarDataAlternativa: false,
            dataAlternativa: null,
            errors: {
                dataAlternativa: false,
            },
            helpers: {
                dataAlternativa: null,
            },
            loading: false,
        });
    }

    isValid = () => {
        if (this.state.habilitarDataAlternativa && (isBlank(this.state.dataAlternativa) || isDateTimeInvalid(this.state.dataAlternativa))) {
            this.setState({
                errors: {                    
                    dataAlternativa: isBlank(this.state.dataAlternativa) || isDateTimeInvalid(this.state.dataAlternativa) ? true : false,
                },
                helpers: {
                    dataAlternativa: isBlank(this.state.dataAlternativa) ? isBlankHelperText() : isDateTimeInvalid(this.state.dataAlternativa) ? isDateTimeHelperText() : null,
                },
            });
            return false;
        }
        return true;
    }

    // SUBMIT
    handleAtualizarDataAlternativa = () => {
        this.setState({ loading: true });

        const formIsValid = this.isValid();

        if (!formIsValid) {
            this.setState({ loading: false });
            return;
        }

        const data = {
            dataAlternativaPremiacao: this.state.habilitarDataAlternativa ? formatInDate(this.state.dataAlternativa) : null,
        }

        EscolaService.atualizarDataAlternativaPremiacao(this.state.school.cdMecEscola, data)
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: `Data Alternativa para Premiação de Escola ${ this.state.dataAlternativa ? `Definida` : `Removida` } Com Sucesso.`,
                        type: "success",
                        open: true
                    }
                })
            })
            .catch(err => {
                const e = errors(err);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
            .finally(() => this.handleClose());
    }

    render() {
        return (
            <>  
                <Toast parentRef={this.setToast} />
                <Dialog
                    maxWidth={"md"}
                    fullWidth={true}
                    closeDialog={this.handleClose}
                    openDialog={this.state.open}
                    title={`Definir uma Data Alternativa Máxima de Premiação para a Escola ${this.state.school && `${this.state.school.nmEscola} (${this.state.school.cdMecEscola})`}`}
                    actions={this.buttons()}
                >
                    <Grid container spacing={3}>
                        <Grid item sm={12} lg={12}>
                            <Grid container item spacing={3} >
                                <Grid item sm={12} >
                                    <SwitchOption
                                        name="habilitarDataAlternativa"
                                        label="Permitir que Escola utilize uma Data Alternativa Máxima Exclusiva, para Lançar Notas e Realizar sua Premiação"
                                        onChange={this.handleChangeSwitch}
                                        checked={this.state.habilitarDataAlternativa}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        { this.state.school && this.state.habilitarDataAlternativa &&
                            <>
                                <Grid item sm={12} lg={12}>
                                    <Alert severity="warning">
                                        <Typography variant="body2">
                                            { this.state.school.dataAlternativaPremiacao ?
                                                <>
                                                    A Escola está habilitada a <b>Adicionar / Editar as Notas de Fase 2 dos seus Alunos e a Realizar sua Premiação</b>, exclusivamente até a
                                                    Data Alternativa Máxima de <b>{this.state.school.dataAlternativaPremiacao}</b> definida anteriormente. 
                                                    Deseja definir outra Data Alternativa Máxima de Premiação?
                                                </>
                                            :   
                                                <>
                                                    A partir desta ação a Escola estará habilitada a <b>Adicionar / Editar as Notas de Fase 2 dos seus Alunos e a Realizar sua Premiação</b>, 
                                                    exclusivamente até a da Data Alternativa Máxima de Premiação definida abaixo.
                                                </>
                                            }
                                        </Typography>
                                    </Alert>
                                </Grid>

                                <Grid item sm={12} lg={6}>
                                    <DatePicker
                                        required
                                        disablePast
                                        label="Data Alternativa Máxima para Premiação"
                                        name='dataAlternativa'
                                        value={this.state.dataAlternativa}
                                        onChange={(e) => this.handleChangeDate(e, 'dataAlternativa')}
                                        error={this.state.errors.dataAlternativa}
                                        helperText={this.state.school.dataAlternativaPremiacao ? this.state.helpers.dataAlternativa : `Data Sugerida: ${currentDatePlusDays(3)}. ${this.state.helpers.dataAlternativa ?? ""}`}
                                    />
                                </Grid>
                            </>
                        }
                    </Grid>
                </Dialog>
            </>
        )
    }
}

export default DialogDefinirDataAlternativaRealizarPremiacao;