import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import { Card, CardContent } from "@material-ui/core";

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';

import { Layout } from '../../layouts/private/Private';

import InscricoesPorData from './InscricoesPorData';
import DadosPorUf from './DadosPorUfs';

export class Graficos extends Component {
  render () {
    const pageName = 'Gráficos';
    const links = [
      {
        path: null,
        name: 'Relatórios'
      },
    ];

    return (
      <Layout>
        <Grid container >
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={pageName} />
          </Grid>
        </Grid>
        <Title>{pageName}</Title>

        <Card style={{ marginTop: 20, marginBottom: 20 }}>
          <CardContent style={{ padding: 20, fontSize: 14, borderBottom: "1px solid #ccc", backgroundColor: "#f5f5f5" }}>
            <InscricoesPorData />
          </CardContent>
        </Card>

        <Card style={{ marginTop: 20, marginBottom: 20 }}>
          <CardContent style={{ padding: 20, fontSize: 14, borderBottom: "1px solid #ccc", backgroundColor: "#f5f5f5" }}>
            <DadosPorUf />
          </CardContent>
        </Card>
      </Layout>
    )
  }
}

export default withRouter(Graficos);