import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
    (theme) => (
        {
            container: {
                marginTop: theme.spacing(12),
                marginBottom: theme.spacing(8),
            },
            wrapper: {
                marginBottom: theme.spacing(2), 
                paddingLeft: theme.spacing(6),
                paddingRight: theme.spacing(6),
                ['@media (max-width:780px)']: { // eslint-disable-line no-useless-computed-key
                    marginBottom: theme.spacing(1),
                    paddingLeft: theme.spacing(3),
                    paddingRight: theme.spacing(3),
                },
            },
            bolder: {
                fontWeight: 'bold',
            },
            table: {
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
                textIndent: theme.spacing(0),
            },
            box: {
                textAlign: "center",
                backgroundColor: '#3C50CF',
                color: "#FFF",
                border: "2px #FFF solid",
            },
        }
    )
);

export default useStyles;