import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import AuthorizedFunction from '../../security/AuthorizedFunction';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { makeStyles } from '@material-ui/core/styles';

import {
    __DASHBOARD_VISUALIZAR,
    __ESCOLA_DASHBOARD,
} from '../../security/RoleConfiguration';

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
    iconMenu: {
        minWidth: "32px",
    },
}));

export const SingleItem = ({ item }) => {
    const classes = useStyles();

    return (
        <ListItem button
            to={item.path}
            href={item.path}
            component={item.component === "a" ? item.component : Link}
            target={item.component === "a" ? "__blank" : null}
        >
            <ListItemIcon className={classes.iconMenu}>{<item.icon />}</ListItemIcon>
            <ListItemText primary={item.name} />
        </ListItem>
    )
}

export const CollapseItem = ({ item }) => {
    const classes = useStyles();

    const [ open, setOpen ] = useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItem button
                onClick={handleClick}
            >
                <ListItemIcon className={classes.iconMenu} >{<item.icon />}</ListItemIcon>
                <ListItemText primary={item.name} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {item.submenus.map((item, key) =>
                        AuthorizedFunction(item.roles) &&
                            <ListItem button
                                className={classes.nested}
                                to={item.path}
                                href={item.path}
                                component={item.component === "a" ? item.component : Link}
                                target={item.component === "a" ? "__blank" : null}
                                key={key}
                            >
                                <ListItemIcon className={classes.iconMenu} >{<item.icon />}</ListItemIcon>
                                <ListItemText primary={item.name} />
                            </ListItem>
                    )}
                </List>
            </Collapse>
        </>
    )
}

export const ListDrawer = (props) => {
    const hideMenuInicio = (i) => ((i.roles.some(r => r !== __DASHBOARD_VISUALIZAR) && AuthorizedFunction([__ESCOLA_DASHBOARD])) || !AuthorizedFunction([__ESCOLA_DASHBOARD]));

    return (
        <>
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <>
                        {(props.items.some((item) => hideMenuInicio(item) && AuthorizedFunction(item.roles))) &&
                            <ListSubheader component="div" id="nested-list-subheader">
                                { props.category }
                            </ListSubheader>
                        }
                    </>
                }
            >
                { props.items.map((item, key) =>
                    hideMenuInicio(item) && AuthorizedFunction(item.roles) ? item.collapsed ? <CollapseItem key={key} item={item} /> : <SingleItem key={key} item={item} /> : null
                )}
            </List>

            { (props.items.some((item) => hideMenuInicio(item) && AuthorizedFunction(item.roles))) && <Divider /> }
        </>
    );
}

export default ListDrawer;