import { useKeycloak } from '@react-keycloak/web';
import Inicio from '../../views/Inicio/Inicio';
import Calendario from '../../views/Calendario/Calendario';
import Contato from '../../views/Contato/Contato';
import Regulamento from '../../views/Regulamento/Regulamento';
import Privacidade from '../../views/Privacidade/Privacidade';
import Apresentacao from '../../views/Apresentacao/Apresentacao';
import ProvasSolucoes from '../../views/ProvasSolucoes/ProvasSolucoes';
import RelatorioEscolasPublico from '../../views/Relatorio/EscolasPublico';
import EmNumeros from '../../views/EmNumeros/EmNumeros';

export const path = '';

export const mainPage = '/';

export const listRoutes = [
    {
        path: path + '/',
        component: Inicio,
        roles: [],
    },
];

export const listRoutesParty = [
    {
        path: path + '/regulamento',
        component: Regulamento,
        roles: null,
    },
    {
        path: path + '/apresentacao',
        component: Apresentacao,
        roles: null,
    },
    {
        path: path + '/contato',
        component: Contato,
        roles: null,
    },
    {
        path: path + '/privacidade',
        component: Privacidade,
        roles: null,
    },
    {
        path: path + '/provas-solucoes',
        component: ProvasSolucoes,
        roles: null,
    },
    {
        path: path + '/em-numeros',
        component: EmNumeros,
        roles: null,
    },
    {
        path: path + '/calendario',
        component: Calendario,
        roles: null,
    },
    {
        path: path + '/escolas',
        component: RelatorioEscolasPublico,
        roles: null,
    },
]

export const GoToLogin = () => {
    const [keycloak, initialized] = useKeycloak();
    
    return initialized && !keycloak.authenticated && keycloak.login({ redirectUri: process.env.REACT_APP_PUBLIC_URL })
}

export default listRoutes;