import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import EmailService from '../../services/Email';
import { errors } from '../../services/API';

import { verifyPersistence, removePagingAndSorting } from '../../helper/PaginationHelper';

import EmailFilter from '../../forms/Email/EmailFilter';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Table from '../../components/Table/Table';
import Toast from '../../components/Toast/Toast';
import {
  verifyPagingAndSorting,
  PAGE,
  SIZE,
  ASC,
  TOTAL_ELEMENTS,
  TOTAL_PAGES,
} from '../../components/Table/Utils';

import { patternMails } from '../../variables/Enums/Email';

import { __EMAIL_VISUALIZAR } from '../../security/RoleConfiguration';
import AuthorizedFunction from '../../security/AuthorizedFunction';

import { Layout } from '../../layouts/private/Private';

export class Email extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: PAGE,
      size: SIZE,
      totalElements: TOTAL_ELEMENTS,
      totalPages: TOTAL_PAGES,
      sort: [{ orderBy: 'nmTipo', order: ASC }],
      defaultOrderBy: 'nmTipo',
      filtered: false,
      filters: [],
      mails: [],
      loading: true,
      history: {
        path: null,
        state: null
      }
    }

    this.state = verifyPersistence(this.state, this.props.location);
  }

  // FECHAR TOAST
  setToast = t => this.Toast = t;
  setEmailFilter = e => this.EmailFilter = e;

  // LABELS
  labelVerEmail = id => !this.disabledVerEmail(id) ? "Visualizar Email" : "Você não tem permissão para fazer isso";

  // DISABLES
  disabledVerEmail = id => !AuthorizedFunction([__EMAIL_VISUALIZAR]);

  // EDIÇÃO
  handleVerEmail = id => {
    this.props.history.push({
      pathname: `/admin/emails/${id}`,
      state: {
        history: {
          path: `/admin/emails`,
          state: this.state
        }
      }
    })
  }

  loadData = () => {
    let f = verifyPagingAndSorting(this.state);

    this.setState({ 
      filters: [...f], 
      filtered: true, 
      loading: true 
    });

    EmailService.filters(f)
      .then(res => {
        if (res.data.content) {
          this.setState({ 
            mails: [...patternMails(res.data.content)],
            page: res.data.number,
            size: res.data.size,
            totalElements: res.data.totalElements,
            totalPages: res.data.totalPages,
            loading: false, 
          });
        } else {
          this.setState({ 
            mails: [],
            page: res.data.number,
            size: res.data.size,
            totalElements: res.data.totalElements,
            totalPages: res.data.totalPages,
            loading: false,
          });
        }
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      })
      .finally(() => this.setState({ loading: false }))
  }

   // FILTROS
   filterData = (filters, isActive = true) => this.setState({ filtered: isActive, filters: [...removePagingAndSorting(filters)] }, () => this.loadData());

   // PÁGINA
   handlePage = page => this.setState({ page: page }, () => this.filterData(this.state.filters));
 
   // LINHAS POR PÁGINA
   handleRowsPerPage = (size, page) => this.setState({ size: size, page: page }, () => this.filterData(this.state.filters));
 
   // ORDENAÇÃO
   handleSort = (orderBy, order) => {
    if (this.state.sort.find(s => s.orderBy === orderBy)) {
        let newSort = this.state.sort.filter(s => s.orderBy !== orderBy);
        newSort.splice(this.state.sort.indexOf(this.state.sort.find(s => s.orderBy === orderBy)), 0, { orderBy: orderBy, order: order })
        this.setState({...this.state, sort: [...newSort], filtered: true });
    } else {
        this.setState({ ...this.state, sort: [...this.state.sort.filter(s => s.orderBy !== orderBy), { orderBy: orderBy, order: order }], filtered: true });
    }
  }
  handleClearSort = (orderBy) => {
      this.setState({ ...this.state, filtered: true, sort: orderBy ? [...this.state.sort.filter(s => s.orderBy !== orderBy)] : [{ orderBy: this.state.defaultOrderBy, order: ASC }] });
  };
 
   // TOTAL DE PAGINAS
   handleTotalPages = () => this.state.totalPages;
 
   // TOTAL DE ELEMENTOS
   handleTotalElements = () => this.state.totalElements;
 
   // VERIFICAR PERSISTENCIA NO FORMULARIO DE FILTROS
   handleFilterPersistence = () => this.EmailFilter ? this.EmailFilter.state ? this.EmailFilter.handleFilterPersistence(this.state.filters) : null : null;

  // COMPONENT DID MOUNT
  componentDidMount() { this.loadData(); }

  render() {
    const pageName = 'Emails';
    const links = [
      {
        path: null,
        name: 'Comunicação'
      }
    ];

    const columns = [
      { label: '#', name: 'cdEmail', func: null, key: true, hidden: true },
      { label: 'Tipo de Email', name: 'nmTipo', func: null, key: false },
      { label: 'Email que Recebeu', name: 'nmEmail', func: null, key: false, ordering: false },
      { label: 'INEP', name: 'cdEscola', func: null, key: false, ordering: false },
      { label: 'Escola', name: 'nmEscola', func: null, key: false, ordering: false },
    ];

    const actions = [
      {
        name: this.labelVerEmail,
        func: this.handleVerEmail,
        icon: <MailOutlineIcon />,
        disabled: this.disabledVerEmail,
      },
    ];

    this.handleFilterPersistence();

    return (
      <Layout>
        <Toast parentRef={this.setToast} />
        <Grid container >
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={pageName} />
          </Grid>
        </Grid>
        <Title>{pageName}</Title>

        <Grid container >
          <Grid item xs={12}>
            <EmailFilter 
              parentRef={this.setEmailFilter}
              handleFilterChange={this.filterData}
            />

            <Table
              columns={columns}
              tableData={this.state.mails}
              actions={actions}
              page={this.state.page}
              handlePage={this.handlePage}
              handleTotalPages={this.handleTotalPages}
              handleTotalElements={this.handleTotalElements}
              rowsPerPage={this.state.size}
              handleRowsPerPage={this.handleRowsPerPage}
              sort={this.state.sort}
              handleSort={this.handleSort}
              handleClearSort={this.handleClearSort}
              loading={this.state.loading}
            />
          </Grid>
        </Grid>
      </Layout>
    )
  }
}

export default withRouter(Email);