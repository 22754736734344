export const cpfMask = value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
}

export const zipCodeMask = value => {
    if(value !== undefined && value !== ""){
    return value
      .replace(/\D/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1')
    }else{
      return value;
    }
}

export const cnpjMask = value => {
  let x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);

  if(x[0].length > 8){
    return !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '')
  }else{
    return !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3];
  }
}

export const phoneNumberMask = value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{4,5})(\d{4})/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1')
}

export const phoneDDDMask = value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})\d+?$/, '$1')
}

export const mecCodeMask = value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{8})\d+?$/, '$1')
}

export const evasaoLimiterMask = value => {
    return (Math.round(value * 100) / 100).toFixed(2) + "%";
}

export const numberMask = value => {
    return value
      .replace(/\D+/g, '')
}

export const nameMask = value => {
  return value
    .replace(/[^a-zA-ZÀ-ú'\s]+$/g, '')
}

export const percentMask = value => {
  return (Math.round(value * 100) / 100).toFixed(2) + "%";
}

export const moneyMask = value => {
  return parseInt(value, 10).toLocaleString("pt-br", { style: "currency", currency: "BRL" });
}

export const numberFormat = value => {
  return parseInt(value, 10).toLocaleString("pt-br", { minimumFractionDigits: 0, maximumFractionDigits: 2 });
}