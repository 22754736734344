import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

export const ButtonNewTab = ({ texto, url, legenda }) => {
    return (
      <>
        {texto}
        <Tooltip title={legenda} TransitionComponent={Zoom}>
            <IconButton 
                size="small"
                color="primary" 
                component="a"
                href={url}
                target="_blank"
                aria-label={legenda}
            >
                <OpenInNewIcon fontSize="small" />
            </IconButton>
        </Tooltip>
      </>
    )
}

export const ButtonNewTabInep = ({ inep }) => 
  <ButtonNewTab 
    texto={inep}
    legenda="Ver Detalhes da Escola"
    url={`${process.env.REACT_APP_PUBLIC_URL}/admin/escola?inep=${inep}`}
  />

export const ButtonNewTabModalidade = ({ label, value }) =>
  <ButtonNewTab 
    texto={label}
    legenda="Ver Escolas Nesta Modalidade"
    url={`${process.env.REACT_APP_PUBLIC_URL}/admin/escola?modalidade=${value}`}
  />

export const ButtonNewTabNivelAndTipoProva = ({ qtd, inep, nivel, tipo }) => 
  <>
    {qtd > 0 ?
      <ButtonNewTab 
        texto={qtd}
        legenda="Ver Alunos"
        url={`${process.env.REACT_APP_PUBLIC_URL}/admin/aluno?inep=${inep}&nivel=${nivel}&tipoProva=${tipo}`}
      />
    : qtd }
  </>

export const ButtonNewTabAlunosInscritosAndNivel = ({ qtd, inep, nivel }) => 
  <>
    {qtd > 0 ?
      <ButtonNewTab 
        texto={qtd}
        legenda="Ver Alunos"
        url={`${process.env.REACT_APP_PUBLIC_URL}/admin/aluno?inep=${inep}&nivel=${nivel}`}
      />
    : qtd }
  </>

export const ButtonNewTabAlunosInscritos = ({ qtd, inep }) => 
  <>
    {qtd > 0 ?
      <ButtonNewTab 
        texto={qtd}
        legenda="Ver Alunos"
        url={`${process.env.REACT_APP_PUBLIC_URL}/admin/aluno?inep=${inep}`}
      />
    : qtd }
  </>

export const ButtonNewTabAlunosParticipantesAndNivel = ({ qtd, inep, nivel }) =>
  <>
    {qtd > 0 ?
      <ButtonNewTab 
        texto={qtd}
        legenda="Ver Alunos"
        url={`${process.env.REACT_APP_PUBLIC_URL}/admin/aluno?inep=${inep}&nivel=${nivel}&comNotaFase2=true`}
      />
    : qtd }
  </>

export const ButtonNewTabAlunosParticipantes = ({ qtd, inep }) =>
  <>
    {qtd > 0 ?
      <ButtonNewTab 
        texto={qtd}
        legenda="Ver Alunos"
        url={`${process.env.REACT_APP_PUBLIC_URL}/admin/aluno?inep=${inep}&comNotaFase2=true`}
      />
    : qtd }
  </>

export const ButtonNewTabEscolasPorDepAdminAndStatus = ({ qtd, depAdmin, status }) =>
  <>
    {qtd > 0 ?
      <ButtonNewTab 
        texto={qtd}
        legenda="Ver Escolas"
        url={`${process.env.REACT_APP_PUBLIC_URL}/admin/escola?depAdmin=${depAdmin}&inscrita=${status}`}
      />
    : qtd }
  </>

export const ButtonNewTabAlunosInscritosAndDepAdmin = ({ qtd, depAdmin }) => 
  <>
    {qtd > 0 ?
      <ButtonNewTab 
        texto={qtd}
        legenda="Ver Alunos"
        url={`${process.env.REACT_APP_PUBLIC_URL}/admin/aluno?depAdmin=${depAdmin}`}
      />
    : qtd }
  </>

export const ButtonNewTabAlunosInscritosAndDepAdminAndNivel = ({ qtd, depAdmin, nivel }) => 
  <>
    {qtd > 0 ?
      <ButtonNewTab 
        texto={qtd}
        legenda="Ver Alunos"
        url={`${process.env.REACT_APP_PUBLIC_URL}/admin/aluno?depAdmin=${depAdmin}&nivel=${nivel}`}
      />
    : qtd }
  </>

export const ButtonNewTabAlunosParticipantesAndDepAdmin = ({ qtd, depAdmin }) =>
  <>
    {qtd > 0 ?
      <ButtonNewTab 
        texto={qtd}
        legenda="Ver Alunos"
        url={`${process.env.REACT_APP_PUBLIC_URL}/admin/aluno?depAdmin=${depAdmin}&comNotaFase2=true`}
      />
    : qtd }
  </>

export const ButtonNewTabAlunosParticipantesAndDepAdminAndNivel = ({ qtd, depAdmin, nivel }) =>
  <>
    {qtd > 0 ?
      <ButtonNewTab 
        texto={qtd}
        legenda="Ver Alunos"
        url={`${process.env.REACT_APP_PUBLIC_URL}/admin/aluno?depAdmin=${depAdmin}&nivel=${nivel}&comNotaFase2=true`}
      />
    : qtd }
  </>

export const ButtonNewTabEscolasPorUf = ({ qtd, uf }) => 
  <>
    {qtd > 0 ?
      <ButtonNewTab 
        texto={qtd}
        legenda="Ver Escolas"
        url={`${process.env.REACT_APP_PUBLIC_URL}/admin/escola?uf=${uf}`}
      />
    : qtd }
  </>

export const ButtonNewTabEscolasPorUfAndStatus = ({ qtd, uf, status }) =>
  <>
    {qtd > 0 ?
      <ButtonNewTab 
        texto={qtd}
        legenda="Ver Escolas"
        url={`${process.env.REACT_APP_PUBLIC_URL}/admin/escola?uf=${uf}&inscrita=${status}`}
      />
    : qtd }
  </>

export const ButtonNewTabAlunosInscritosAndUf = ({ qtd, uf }) => 
  <>
    {qtd > 0 ?
      <ButtonNewTab 
        texto={qtd}
        legenda="Ver Alunos"
        url={`${process.env.REACT_APP_PUBLIC_URL}/admin/aluno?uf=${uf}`}
      />
    : qtd }
  </>

export const ButtonNewTabAlunosInscritosAndUfAndNivel = ({ qtd, uf, nivel }) => 
  <>
    {qtd > 0 ?
      <ButtonNewTab 
        texto={qtd}
        legenda="Ver Alunos"
        url={`${process.env.REACT_APP_PUBLIC_URL}/admin/aluno?uf=${uf}&nivel=${nivel}`}
      />
    : qtd }
  </>

export const ButtonNewTabAlunosParticipantesAndUf = ({ qtd, uf }) =>
  <>
    {qtd > 0 ?
      <ButtonNewTab 
        texto={qtd}
        legenda="Ver Alunos"
        url={`${process.env.REACT_APP_PUBLIC_URL}/admin/aluno?uf=${uf}&comNotaFase2=true`}
      />
    : qtd }
  </>

export const ButtonNewTabAlunosParticipantesAndUfAndNivel = ({ qtd, uf, nivel }) =>
  <>
    {qtd > 0 ?
      <ButtonNewTab 
        texto={qtd}
        legenda="Ver Alunos"
        url={`${process.env.REACT_APP_PUBLIC_URL}/admin/aluno?uf=${uf}&nivel=${nivel}&comNotaFase2=true`}
      />
    : qtd }
  </>

export const ButtonNewTabEscolasPorMunicipio = ({ qtd, municipio, uf }) => 
  <>
    {qtd > 0 ?
      <ButtonNewTab 
        texto={qtd}
        legenda="Ver Escolas"
        url={`${process.env.REACT_APP_PUBLIC_URL}/admin/escola?uf=${uf}&municipio=${municipio}`}
      />
    : qtd }
  </>

export const ButtonNewTabEscolasPorMunicipioAndStatus = ({ qtd, municipio, uf, status }) =>
  <>
    {qtd > 0 ?
      <ButtonNewTab 
        texto={qtd}
        legenda="Ver Escolas"
        url={`${process.env.REACT_APP_PUBLIC_URL}/admin/escola?uf=${uf}&municipio=${municipio}&inscrita=${status}`}
      />
    : qtd }
  </>

export const ButtonNewTabAlunosInscritosAndMunicipio = ({ qtd, municipio, uf }) => 
  <>
    {qtd > 0 ?
      <ButtonNewTab 
        texto={qtd}
        legenda="Ver Alunos"
        url={`${process.env.REACT_APP_PUBLIC_URL}/admin/aluno?uf=${uf}&municipio=${municipio}`}
      />
    : qtd }
  </>

export const ButtonNewTabAlunosInscritosAndMunicipioAndNivel = ({ qtd, municipio, uf, nivel }) => 
  <>
    {qtd > 0 ?
      <ButtonNewTab 
        texto={qtd}
        legenda="Ver Alunos"
        url={`${process.env.REACT_APP_PUBLIC_URL}/admin/aluno?uf=${uf}&municipio=${municipio}&nivel=${nivel}`}
      />
    : qtd }
  </>

export const ButtonNewTabAlunosParticipantesAndMunicipio = ({ qtd, municipio, uf }) =>
  <>
    {qtd > 0 ?
      <ButtonNewTab 
        texto={qtd}
        legenda="Ver Alunos"
        url={`${process.env.REACT_APP_PUBLIC_URL}/admin/aluno?uf=${uf}&municipio=${municipio}&comNotaFase2=true`}
      />
    : qtd }
  </>

export const ButtonNewTabAlunosParticipantesAndMunicipioAndNivel = ({ qtd, municipio, uf, nivel }) =>
  <>
    {qtd > 0 ?
      <ButtonNewTab 
        texto={qtd}
        legenda="Ver Alunos"
        url={`${process.env.REACT_APP_PUBLIC_URL}/admin/aluno?uf=${uf}&municipio=${municipio}&nivel=${nivel}&comNotaFase2=true`}
      />
    : qtd }
  </>

export const ButtonNewTabDataInscricao = ({ data }) =>
  <ButtonNewTab 
    texto={data}
    legenda="Ver Escolas"
    url={`${process.env.REACT_APP_PUBLIC_URL}/admin/escola?dataInscricao=${data}`}
  />

export const ButtonNewTabEscolasPorUfAndDepAdminAndStatus = ({ qtd, uf, depAdmin, status }) =>
  <>
    {qtd > 0 ?
      <ButtonNewTab 
        texto={qtd}
        legenda="Ver Escolas"
        url={`${process.env.REACT_APP_PUBLIC_URL}/admin/escola?uf=${uf}&depAdmin=${depAdmin}&inscrita=${status}`}
      />
    : qtd }
  </>

export default ButtonNewTab;