import API, { APIWithoutURLBase, APIWithoutURLBaseAndAuth } from './API';

const path = `relatorios`;

const RelatorioService = {

    // RELATÓRIO DE ESCOLAS
    reportAllSchools: async () => await API.list(`${path}/escolas`),
    filtersReportAllSchools: async filters => await API.filters(`${path}/escolas`, filters),
    exportReportAllSchools: async filters => await API.export(`${path}/escolas`, filters),

    // RELATÓRIO DE DEPENDENCIAS ADMINISTRATIVAS
    reportDependenciasAdministrativas: async () => await API.list(`${path}/dependencias-administrativas`),
    filtersReportDependenciasAdministrativas: async filters => await API.filters(`${path}/dependencias-administrativas`, filters),
    exportReportDependenciasAdministrativas: async filters => await API.export(`${path}/dependencias-administrativas`, filters),

    // RELATÓRIO DE MUNICÍPIOS
    reportMunicipios: async () => await API.list(`${path}/municipios`),
    filtersReportMunicipios: async filters => await API.filters(`${path}/municipios`, filters),
    exportReportMunicipios: async filters => await API.export(`${path}/municipios`, filters),

    // RELATÓRIO DE UF'S
    reportUfs: async () => await API.list(`${path}/ufs`),
    filtersReportUfs: async filters => await API.filters(`${path}/ufs`, filters),
    exportReportUfs: async filters => await API.export(`${path}/ufs`, filters),

    // RELATÓRIO DE MEDALHAS
    reportMedalhas: async () => await API.list(`${path}/medalhas`),
    filtersReportMedalhas: async filters => await API.filters(`${path}/medalhas`, filters),
    exportReportMedalhas: async filters => await API.export(`${path}/medalhas`, filters),

    // RELATÓRIO DE PAGAMENTOS
    reportPagamentos: async () => await API.list(`${path}/pagamentos`),
    filtersReportPagamentos: async filters => await API.filters(`${path}/pagamentos`, filters),
    exportReportPagamentos: async filters => await API.export(`${path}/pagamentos`, filters),

    // RELATÓRIO DE INSCRIÇÕES POR DATA
    reportInscricoesPorData: async () => await API.list(`${path}/inscricoes/por-data`),
    filtersReportInscricoesPorData: async filters => await API.filters(`${path}/inscricoes/por-data`, filters),
    exportReportInscricoesPorData: async filters => await API.export(`${path}/inscricoes/por-data`, filters),

    // RELATÓRIO PÚBLICO DE ESCOLAS
    reportAllSchoolsPublic: async () => await API.list(`${path}/publico/escolas`),
    filtersReportAllSchoolsPublic: async filters => await API.filters(`${path}/publico/escolas`, filters),
    exportReportAllSchoolsPublic: async filters => await API.export(`${path}/publico/escolas`, filters),

    // RELATÓRIO PÚBLICO DE QTD ALUNOS POR ESCOLA
    reportQtdAlunosSchoolPublic: async (cdMecEscola) => await API.list(`${path}/publico/escolas/${cdMecEscola}/alunos`),
    reportQtdAlunosSchoolPublicLastEdition: async (edition, cdMecEscola) => await APIWithoutURLBaseAndAuth.list(`https://cloud-gateway.impa.br/olimpiada-mirim-${edition}-services/${path}/publico/escolas/${cdMecEscola}/alunos`),

    // RELATÓRIO DE PROVAS ESPECIAIS
    reportProvasEspeciais: async () => await API.list(`${path}/provas-especiais`),
    filtersReportProvasEspeciais: async filters => await API.filters(`${path}/provas-especiais`, filters),
    exportReportProvasEspeciais: async filters => await API.export(`${path}/provas-especiais`, filters),

    // RELATÓRIO DE INSCRIÇÕES POR UF
    reportInscricoesPorUF: async () => await API.list(`${path}/inscricoes/por-uf`),
    filtersReportInscricoesPorUF: async filters => await API.filters(`${path}/inscricoes/por-uf`, filters),
    exportReportInscricoesPorUF: async filters => await API.export(`${path}/inscricoes/por-uf`, filters),

    // RELATÓRIO DE INSCRIÇÕES POR EDICAO
    reportInscricoesPorEdicao: async () => await API.list(`${path}/inscricoes/por-edicao`),
    filtersReportInscricoesPorEdicao: async filters => await API.filters(`${path}/inscricoes/por-edicao`, filters),
    filtersReportInscricoesPorEdicaoOtherEdition: async (edition, filters) => await APIWithoutURLBase.filters(`https://cloud-gateway.impa.br/olimpiada-mirim-${edition}-services/${path}/inscricoes/por-edicao`, filters),

    // RELATÓRIO DE INSCRIÇÕES POR MUNICIPIO
    reportInscricoesPorMunicipio: async () => await API.list(`${path}/inscricoes/por-municipio`),
    filtersReportInscricoesPorMunicipio: async filters => await API.filters(`${path}/inscricoes/por-municipio`, filters),
    filtersReportInscricoesPorMunicipioOtherEdition: async (edition, filters) => await APIWithoutURLBase.filters(`https://cloud-gateway.impa.br/olimpiada-mirim-${edition}-services/${path}/inscricoes/por-municipio`, filters),

    // RELATÓRIO DE GESTAO POR EDICAO
    reportGestaoPorEdicao: async () => await API.list(`${path}/gestao/por-edicao`),
    filtersReportGestaoPorEdicao: async filters => await API.filters(`${path}/gestao/por-edicao`, filters),
    filtersReportGestaoPorEdicaoOtherEdition: async (edition, filters) => await APIWithoutURLBase.filters(`https://cloud-gateway.impa.br/olimpiada-mirim-${edition}-services/${path}/gestao/por-edicao`, filters),

    // RELATÓRIO DE GESTAO POR ALUNOS
    reportGestaoPorAlunos: async () => await API.list(`${path}/gestao/por-alunos`),
    filtersReportGestaoPorAlunos: async filters => await API.filters(`${path}/gestao/por-alunos`, filters),
    exportReportGestaoPorAlunos: async filters => await API.export(`${path}/gestao/por-alunos`, filters),

    // RELATÓRIO DE GESTAO POR ESCOLAS
    reportGestaoPorEscolas: async () => await API.list(`${path}/gestao/por-escolas`),
    filtersReportGestaoPorEscolas: async filters => await API.filters(`${path}/gestao/por-escolas`, filters),
    exportReportGestaoPorEscolas: async filters => await API.export(`${path}/gestao/por-escolas`, filters),

    // RELATÓRIO PÚBLICO DE NÚMEROS GERAIS
    reportNumbersGeneralsPublic: async () => await API.list(`${path}/publico/numeros-gerais`),
    reportNumbersGeneralsPublicOtherEdition: async (edition) => await APIWithoutURLBaseAndAuth.list(`https://cloud-gateway.impa.br/olimpiada-mirim-${edition}-services/${path}/publico/numeros-gerais`),
}

export default RelatorioService;